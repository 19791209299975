import React from 'react';
import {BREAKPOINTS, COLOR_PICKER_GAP, COLOR_PICKER_WIDTH} from "../../constants";
import ColorPicker from "./ColorPicker";
import Slider from "./Slider";
import {useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {selectAllColors} from "../../store/appSlice";
import useColorSelector from "../../hooks/useColorSelector";

const SliderLayout = () => {
  const baseColors = useSelector(selectAllColors);
  const isLaptop = useMediaQuery(`(max-width: ${BREAKPOINTS.md})`);
  const isSpecial = useMediaQuery(`(max-width: ${BREAKPOINTS.special})`);

  const { currentId, onColorSelect } = useColorSelector();

  return (
    <Slider
      maxItems={isLaptop ? (isSpecial ? 17 : 24) : 31}
      totalItems={baseColors.length}
      itemWidth={COLOR_PICKER_WIDTH}
      itemGap={COLOR_PICKER_GAP}
    >
      {
        baseColors.map(item => <ColorPicker
          key={item.id}
          rgb={item.rgb}
          title={item.title}
          selected={currentId === item.id}
          handleClick={() => onColorSelect(item)}
        />)
      }
    </Slider>
  );
};

export default SliderLayout;
