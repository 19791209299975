import React from 'react';
import {Box, styled} from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";
import StartOver from "../Modals/StartOver";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setModal} from "../../store/appSlice";
import ContactUs from "../Modals/ContactUs";
import WarningColorsCount from "../Modals/WarningColorsCount";
import {BREAKPOINTS} from "../../constants";

const DialogWrapper = styled(Box)`
  position: fixed;
  height: 80vh;
  width: 60vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  
  .preventEvents {
    height: 100%;
    width: 100%;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    height: 100%;
    width: 100%;

    .preventEvents {
      width: max-content;
      height: max-content;
    }
  }
`;

const Modal = () => {
  const modal = useAppSelector(state => state.app.modal);
  const dispatch = useAppDispatch();

  if (!modal) {
    return null;
  }

  const handleClose = () => {
    dispatch(setModal(null));
  }

  const renderContent = () => {
    switch(modal) {
      case 'startOver': return <StartOver />;
      case 'contactUs': return <ContactUs />;
      case 'warningColorsCount': return <WarningColorsCount />;
      default: return null;
    }
  }

  return (
    <DialogWrapper
      onClick={handleClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='preventEvents'
      >
        {
          renderContent()
        }
      </div>
    </DialogWrapper>
  );
};

export default Modal;
