import React from 'react';
import {Box, styled, css} from "@mui/material";
import {BREAKPOINTS} from "../../constants";
import chevronSvg from '../../assets/img/UI/click-chevron-black.svg';

interface ICardProps {
  selected: boolean;
  disabled: boolean;
}

const disabledCss = css`
  cursor: default;
  
  .imageBox {
    img {
      opacity: .5;
    }
  }
  
  .titleBox {
    p {
      opacity: .5;

      &::after {
        opacity: 0;
      }
    }
  }
`

const Card = styled(Box)<ICardProps>`
  width: 128px;
  height: 144px;
  cursor: pointer;
  user-select: none;
  
  .imageBox {
    width: 100%;
    height: 102px;
    display: flex;
    justify-content: center;
    pointer-events: none;
    
    img {
      width: 100%;
      height: 100%;
      opacity: ${props => props.selected ? 1 : 0.5};
      transition: .3s;
    }
  }
  
  .titleBox {
    margin-top: 10px;
    
    p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 120%;
      color: #000000;
      text-align: center;
      margin: 0 auto;
      padding-left: 25px;

      &::after {
        content: url(${chevronSvg});
        margin-left: 6px;
        opacity: ${props => props.selected ? 1 : 0};
        transition: .3s;
      }
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.md}) {
    width: 110px;
    height: 125px;

    .imageBox {
      height: 95px;
    }

    .titleBox {
      margin-top: 5px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    width: 163px;
    height: 163px;

    .imageBox {
      height: 125px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: calc(50% - 8px);
    height: 26vw;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .imageBox {
      height: 50%;
    }
  }
  
  ${props => props.disabled && disabledCss}
`

interface IProps {
  svgSrc: string;
  title: string;
  selected: boolean;
  disabled?: boolean;
  handleClick: () => void;
}

const GarmentCard = ({ svgSrc, title, selected, handleClick, disabled }: IProps) => {
  const onClick = () => {
    if (!disabled) {
      handleClick();
    }
  }

  return (
    <Card
      onClick={onClick}
      selected={selected}
      disabled={disabled}
    >
      <div className='imageBox'>
        <img
          src={svgSrc}
          alt='garment'
        />
      </div>
      <div className='titleBox'>
        <p>{ title }</p>
      </div>
    </Card>
  );
};

export default GarmentCard;
