import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingManager } from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import triggerScene from "../../utils/triggerScene";
import { isGltfResource, Loader } from "./ResourceTypes";

const loadingManager = new LoadingManager(
  // Loaded
  () => {
    console.log("loaded");
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    const progressRatio = itemsLoaded / itemsTotal;
    const value = +(progressRatio * 100).toFixed(0);
    triggerScene("preloaderProgress", { value });
  }
);

export class ThreeResourceLoader implements Loader {
  public loadResource({ url, resource }): Promise<void> {
    if (resource.loaded) return Promise.resolve();
    const loader = isGltfResource(resource) ? new GLTFLoader(loadingManager) : new RGBELoader(loadingManager);
    return new Promise((resolve, reject) =>
      loader.load(
        url,
        (content) => {
          resource.loaded = true;
          resource.content = content;
          resource.error = undefined;
          resolve();
        },
        () => undefined,
        () => {
          resource.loaded = true;
          resource.content = undefined;
          resource.error = new Error(`Unable to load ${resource.type} resource. By address: ${url}`);
          reject(resource.error);
        }
      )
    );
  }
}
