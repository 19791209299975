import { App } from "../app3d/App";
import { useRef } from "react";

const app = new App();
export default function useApp3dScreenshots(): App {
  const app3dref = useRef<App | null>(null);
  if (!app3dref.current) {
    app3dref.current = app;
  }
  return app3dref.current;
}
