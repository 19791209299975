import React from 'react';
import {styled} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../constants";

interface IWrapperProps {
  disabled: boolean;
}

const Wrapper = styled('button')<IWrapperProps>`
  width: 197px;
  height: 86px;
  color: ${props => props.disabled ? COLORS.greyBg : COLORS.textDarkGrey};
  font-weight: 600;
  font-size: 18px;
  border: ${props => props.disabled ? `1px solid ${COLORS.greyBg}` : '1px solid #000000'};
  background: none;
  transition: .3s;

  .hidden {
    display: none;
  }

  &:hover {
    background: ${props => props.disabled ? '' : COLORS.boxBg};
    color: ${props => props.disabled ? '' : '#FFFFFF'};
    cursor: ${props => props.disabled ? 'default' : ''};

    .visible {
      display: none;
    }

    .hidden {
      display: block;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    border: none;
    background: white;
    font-weight: 400;
    width: 48%;
    height: 20vw;
    flex-grow: 1;
  }
`

interface IProps {
  children: React.ReactNode;
  handleClick: () => void;
  disabled: boolean;
}

const ButtonSport = ({ children, handleClick, disabled }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
      disabled={disabled}
    >
      <div className='visible'>{ children }</div>
      <div className='hidden'>{disabled ? 'Coming soon' : children}</div>
    </Wrapper>
  );
};

export default ButtonSport;
