import React from 'react';
import {styled} from "@mui/material";
import nextSvg from '../../../assets/img/UI/next-bold.svg';
import {COLORS} from "../../../constants";
import {AnimationSideBarButtonCss} from "./styles";

interface IWrapperProps {
  delay: string;
}

const CustomButton = styled('button')<IWrapperProps>`
  ${AnimationSideBarButtonCss};
  animation-delay: ${props => props.delay};
  
  padding-left: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #FFFFFF;
  width: 199px;
  height: 56px;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: ${COLORS.textDarkGrey};
`

interface IProps {
  children: React.ReactNode;
  delay?: string;
  handleClick: () => void;
  style?: React.CSSProperties;
}

const ButtonSideBar = ({ children, delay = '0s', handleClick, style }: IProps) => {
  return (
    <CustomButton
      delay={delay}
      onClick={handleClick}
      style={style}
    >
      <img src={nextSvg} alt='>'/>
      { children }
    </CustomButton>
  );
};

export default ButtonSideBar;
