// Функция для вычисления множества индесов граней меша, которые находятся на краю
// меша, или имеют краевых соседей через рёбра по мешу, где N - глубина соседства
export function createNeighbourFacesSet(mesh, N: number) : Set<number> {
  const geometry = mesh.geometry;
  const edgeFaceIndices = [];

  const faceIndices = geometry.index.array;
  const edgeMap = {};

// Итерируемся по каждой тройке индексов вершин в массиве
  for (let i = 0; i < faceIndices.length; i += 3) {
    const vertexIndex1 = faceIndices[i];
    const vertexIndex2 = faceIndices[i + 1];
    const vertexIndex3 = faceIndices[i + 2];

    const edges = [
      [vertexIndex1, vertexIndex2].sort(),
      [vertexIndex2, vertexIndex3].sort(),
      [vertexIndex3, vertexIndex1].sort(),
    ];

    edges.forEach(([v1, v2]) => {
      const key = `${v1}-${v2}`;
      if (edgeMap[key]) {
        edgeMap[key]++;
      } else {
        edgeMap[key] = 1;
      }
    });
  }

// Итерируемся по каждой грани в геометрии
  for (let i = 0; i < faceIndices.length; i += 3) {
    const vertexIndex1 = faceIndices[i];
    const vertexIndex2 = faceIndices[i + 1];
    const vertexIndex3 = faceIndices[i + 2];

    const edges = [
      [vertexIndex1, vertexIndex2].sort(),
      [vertexIndex2, vertexIndex3].sort(),
      [vertexIndex3, vertexIndex1].sort(),
    ];

    let isEdgeFace = false;

    // Проверяем, является ли грань краевой
    edges.forEach(([v1, v2]) => {
      const key = `${v1}-${v2}`;
      if (edgeMap[key] === 1) {
        isEdgeFace = true;
      }
    });

    if (isEdgeFace) {
      edgeFaceIndices.push(i / 3);
    }
  }

  const neighboringFaceIndices = new Set<number>();

// Итерируемся по каждому индексу краевой грани
  for (const edgeIndex of edgeFaceIndices) {
    const neighboringIndices = getNeighboringFaceIndices(edgeIndex, faceIndices, N);
    neighboringIndices.forEach((value) => {
      neighboringFaceIndices.add(value);
    });
  }
  return neighboringFaceIndices;
}

// Функция для получения индексов фейсов рядом с краевой гранью
function getNeighboringFaceIndices(edgeIndex, faceIndices, N: number) {
  const neighboringIndices = [];

  for (let i = 0; i < N; i++) {
    const index = edgeIndex + i;
    if (index >= 0 && index < faceIndices.length / 3) {
      neighboringIndices.push(index);
    }
  }

  return neighboringIndices;
}
