import {useEffect, useState} from "react";
import {COLOR_MENU_DISPLAY} from "../constants";
import useAppSelector from "./useAppSelector";
import {addColorId, setPatternColorId} from "../store/configSlice";
import useAppDispatch from "./useAppDispatch";
import {IBaseColor} from "../store/types";

interface IReturnColorSelector {
  currentId: string;
  onColorSelect: (color: IBaseColor) => void;
}

const useColorSelector = (): IReturnColorSelector => {
  const [currentId, setCurrentId] = useState<string>('');

  const baseColorIds = useAppSelector(state => state.config.baseColorIds);
  const selectedColorIndex = useAppSelector((state) => state.app.selectedColorIndex);
  const patternColorScheme = useAppSelector((state) => state.config.patternColorScheme);
  const selectedColorName = useAppSelector((state) => state.app.selectedColorName);
  const selectedPattern = useAppSelector((state) => state.app.selectedPatternColorScheme);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedColorName === 'Side panel') {
      const id = patternColorScheme.find((k) => k.code === selectedPattern)?.colorId;
      setCurrentId(id);
    } else {
      const colorOrderIds = COLOR_MENU_DISPLAY.map((item) => baseColorIds[item.value]?.id);
      setCurrentId(colorOrderIds[selectedColorIndex]);
    }
  }, [selectedPattern, selectedColorName, selectedColorIndex, baseColorIds, patternColorScheme]);

  const onColorSelect = (color: IBaseColor) => {
    if (selectedColorName !== 'Side panel') {
      dispatch(addColorId({ name: selectedColorName, color }));
    }

    switch (selectedColorName) {
      case 'Side panel': {
        dispatch(setPatternColorId({
          colorId: color.id,
          layerCode: selectedPattern,
        }));
        break;
      }
      case 'Accent1': {
        dispatch(addColorId({ name: 'Text', color }));
        dispatch(addColorId({ name: 'Number', color }));
        break;
      }
      case 'Text': {
        dispatch(addColorId({ name: 'Number', color }));
        break;
      }
      case 'Text outline': {
        dispatch(addColorId({ name: 'Number outline', color }));
        break;
      }
    }
  }

  return {
    currentId,
    onColorSelect,
  }
}

export default useColorSelector;
