import {Box, styled, Typography} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

export const DropdownListWrapper = styled(Box)`
  display: flex;
  gap: 4px;
  width: 320px;

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 60%;
  }
`;

export const DropdownListContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100vh - 785px);
  overflow-y: auto;
  padding-right: 16px;

  @media all and (max-width: ${BREAKPOINTS.special}) {
    max-height: unset;
    overflow-y: visible;
    padding-right: 0;
  }
`;

export const DropdownListButton = styled('button')`
  background: none;
  width: 24px;
  height: 24px;
`;

export const ListRowWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 4px;
  align-items: center;
`;

export const ListRowText = styled(Typography)`
  color: #333333;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  font-weight: 600;
  word-break: break-word;
`;

export const ListRowTextDescription = styled(Typography)`
  color: #BCBCBC;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
`;
