import React, {useEffect} from 'react';
import ColorCircle from "../../../../pages/BaseColors/ColorCircle";
import {Box, styled} from "@mui/material";
import {BREAKPOINTS} from "../../../../constants";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {addSelectedColorId, setSelectedColorIds} from "../../../../store/configSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import {useSelector} from "react-redux";
import {selectAllColors, setModal} from "../../../../store/appSlice";
import {assignColorsFromBaseToSelected} from "../../../../utils/assignColors";
import useColorSelector from "../../../../hooks/useColorSelector";

interface ILayoutProps {
  isbottommenu: string;
}

const LayoutSelector = styled(Box)<ILayoutProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 372px;
  margin-bottom: 16px;
  
  .colorBox {
    width: 62px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    
    > button {
      width: 42px;
      height: 42px;
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.lg}) {
    width: 363px;
    
    .colorBox {
      width: 52px;
      height: 45px;
      
      > button {
        width: 32px;
        height: 32px;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    width: 293px;
    
    .colorBox {
      width: 42px;
      height: 35px;

      > button {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    width: 312px;
    
    .colorBox {
      width: 52px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 384px;
    
    .colorBox {
      width: 55px;
      height: 45px;

      > button {
        width: 32px;
        height: 32px;
      }
    }
  }
`

interface IProps {
  isBottomMenu?: boolean;
}

const ColorSelector = ({ isBottomMenu }: IProps) => {
  const dispatch = useAppDispatch();

  const baseColors = useSelector(selectAllColors);
  const baseColorIds = useAppSelector(state => state.config.baseColorIds);
  const selectedColorIds = useAppSelector((state) => state.config.selectedColorIds);
  const pageId = useAppSelector((state) => state.app.pageId);

  const { currentId, onColorSelect } = useColorSelector();

  useEffect(() => {
    const selectedIds = assignColorsFromBaseToSelected(baseColorIds);
    dispatch(setSelectedColorIds(selectedIds));
  }, [baseColorIds]);

  const handleColorPick = (id: string) => {
    if (isBottomMenu) {
      const color = baseColors.find((k) => k.id === id);
      onColorSelect(color);
      return;
    }

    if (selectedColorIds.length < 3 && selectedColorIds.includes(id)) {
      dispatch(setModal('warningColorsCount'));
    } else {
      dispatch(addSelectedColorId(id));
    }

    if (pageId > 1) {
      const color = baseColors.find((k) => k.id === id);
      onColorSelect(color);
    }
  };

  return (
    <LayoutSelector isbottommenu={String(isBottomMenu)}>
      {baseColors.map((color) => (
        <div
          className="colorBox"
          key={color.id}
        >
          <ColorCircle
            data={color}
            handleClick={() => handleColorPick(color.id)}
            isBottomMenu={isBottomMenu}
            selected={
              isBottomMenu
                ? currentId === color.id // single color selection
                : selectedColorIds.includes(color.id) // all colors selection
            }
            secondPalette
          />
        </div>
      ))}
    </LayoutSelector>
  );
};

export default ColorSelector;
