import React, { useEffect } from "react";
import { GarmentSelectSingle } from "./GarmentSelectButtons";
import { setSelectedGarmentIds } from "../../store/canvasSlice";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { Box, styled } from "@mui/material";
import {BREAKPOINTS, PAGES_ORDER_ARR} from "../../constants";
import { useSelector } from "react-redux";
import { selectAllGarmentTypesBySport } from "../../store/appSlice";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 16px;

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    margin-top: 0;
  }
`;

const GarmentSelectContent = () => {
  const dispatch = useAppDispatch();

  const pageId = useAppSelector((state) => state.app.pageId);
  const pageName = PAGES_ORDER_ARR[pageId];
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const configuredGarmentIds = useAppSelector(
    (state) => state.config.garmentIds
  );

  const selectedGarmentIds = useAppSelector(
    (state) => state.canvas.selectedGarmentIds
  );

  useEffect(() => {
    dispatch(setSelectedGarmentIds(configuredGarmentIds));
  }, [configuredGarmentIds]);

  if (pageName !== 'pattern') {
    return null;
  }

  const handleGarmentClick = (id: string) => {
    dispatch(setSelectedGarmentIds([id]));
  };

  return (
    <Wrapper>
      {garmentList
        .filter((item) => configuredGarmentIds.includes(item.id))
        .map((item) => (
          <GarmentSelectSingle
            key={item.id}
            onClick={() => handleGarmentClick(item.id)}
            isActive={selectedGarmentIds.includes(item.id)}
          >
            <img src={item.icon} alt={item.title} />
          </GarmentSelectSingle>
        ))}
    </Wrapper>
  );
};

export default GarmentSelectContent;
