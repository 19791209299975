import React from 'react';
import {Button, styled, SxProps} from "@mui/material";
import {COLORS} from "../../../constants";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {setModal} from "../../../store/appSlice";

const Wrapper = styled(Button)`
  width: 70%;
  height: 56px;
  background: ${COLORS.textDarkGrey};
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-transform: none;
  border-radius: 0;
  margin: 16px 0;
  
  &:hover {
    background: ${COLORS.textDarkGrey};
  }
  
  &:active {
    color: #009FF1;
  }
  
  &.Mui-disabled {
    color: white;
    background: #BCBCBC;
  }
`;

interface IProps {
  sx?: SxProps;
}

const ShareButton = ({ sx }: IProps) => {
  const dispatch = useAppDispatch();

  const handleShare = () => {
    dispatch(setModal('contactUs'));
  }

  return (
    <Wrapper
      type='button'
      sx={sx}
      onClick={handleShare}
    >
      Share
    </Wrapper>
  );
};

export default ShareButton;
