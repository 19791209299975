import {IPatternColorScheme} from "../store/types";
import {IGarment} from "../store/types/designTypes";

const generatePatternColorScheme = (count: number, colorId: string, design: IGarment): IPatternColorScheme[] => {
  if (!design) {
    return [];
  }

  const result: IPatternColorScheme[] = [];

  for (let i = 1; i <=count; i++) {
    result.push({
      code: `side_panel_${i}`,
      display: design.config.default.layers[i - 1]?.name || `Side panel ${i}`,
      colorId,
    });
  }

  return result;
}

export default generatePatternColorScheme;
