import React from "react";
import CenteredPageContainer from "../../components/Containers/CenteredPageContainer";
import { styled, Box, useMediaQuery } from "@mui/material";
import TextHeading2 from "../../components/Typography/TextHeading2";
import {BREAKPOINTS, COLORS} from "../../constants";
import GarmentCard from "./GarmentCard";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setGarmentIds } from "../../store/configSlice";
import { setSelectedGarmentIds } from "../../store/canvasSlice";
import SideBarMobile from "../../components/SideBar/Desktop/SideBarMobile";
import { useSelector } from "react-redux";
import { selectAllGarmentTypesBySport } from "../../store/appSlice";

const Layout = styled(Box)`
  width: 600px;
  max-height: 75vh;
  overflow-y: auto;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 32px auto 0;
  }

  .title {
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: ${COLORS.textDarkGrey};
    text-align: center;
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    .wrap {
      margin-left: 50px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    width: 550px;

    .wrap {
      margin-left: auto;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 90%;
    max-height: calc(100vh - 150px);
    
    .title {
      font-size: 16px;
    }
  }
`;

const PageGarment = () => {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const isLaptop = useMediaQuery(`(max-width: ${BREAKPOINTS.special})`);

  const detailedSideBar = useAppSelector((state) => state.app.detailedSideBar);
  const currentGarmentIds = useAppSelector((state) => state.config.garmentIds);

  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const handleClickCard = (id: string) => {
    dispatch(setGarmentIds(id));
    dispatch(setSelectedGarmentIds([]));
  };

  const renderLayout = () => <Layout>
    <TextHeading2
      sx={{
        marginBottom: '32px',
      }}
    >
      Select up to 5 garments
    </TextHeading2>
    <div className="title">{sports[selectedSportId].title}</div>
    <div className="wrap">
      {garmentList?.map((item) => (
        <GarmentCard
          key={item.id}
          svgSrc={item.icon}
          title={item.title}
          selected={currentGarmentIds.includes(item.id)}
          disabled={item.disabled}
          handleClick={() => handleClickCard(item.id)}
        />
      ))}
    </div>
  </Layout>;

  const showSideBarMobile = isMobile && detailedSideBar && detailedSideBar !== 'color';
  const hideLayout = (isMobile && detailedSideBar === 'sport') || (isLaptop && !isMobile && detailedSideBar === 'color');

  return (
    <CenteredPageContainer>
      {
        showSideBarMobile && <SideBarMobile />
      }
      {
        !hideLayout && renderLayout()
      }
    </CenteredPageContainer>
  );
};

export default PageGarment;
