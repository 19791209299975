import React from 'react';
import {Box, styled} from "@mui/material";
import useAppSelector from "../../../hooks/useAppSelector";
import {useSelector} from "react-redux";
import {selectAllColors} from "../../../store/appSlice";
import {basicColor} from "../../../constants";

const Wrapper = styled(Box)`
  display: flex;
  width: 90%;
  justify-content: space-around;
`

interface IColorSampleProps {
  rgb: string;
}

const ColorSample = styled('div')<IColorSampleProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.rgb};
`

const ButtonColors = () => {
  const baseColors = useSelector(selectAllColors);
  const colorIds = useAppSelector(state => state.config.baseColorIds);
  // we need to sort ids in right order

  const colorOrderIds = basicColor.map((item) => colorIds[item]?.id);
  const colorData = colorOrderIds.map((item) => baseColors.find((data) => data.id === item));

  return (
    <Wrapper>
      {
        colorData.map((item, index) => <ColorSample
            key={index}
            rgb={item.rgb}
          />)
      }
    </Wrapper>
  );
};

export default ButtonColors;
