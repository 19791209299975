import { AppStateType, IGarmentType } from './types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { convertRgbToHex } from '../utils/colorConverters';
import { WebfontLoaderPlugin } from 'pixi-webfont-loader';
import * as PIXI from 'pixi.js';
import { IGarment } from './types/designTypes';

const debug = process.env.REACT_APP_DEBUG;
PIXI.extensions.add({
  type: PIXI.ExtensionType.Loader,
  ref: WebfontLoaderPlugin,
});

const initialState: AppStateType = {
  loading: false,
  firstLoading: true,
  pageId: 0,
  isMenuOpen: false,
  isGarmentSelectOpen: false,
  modal: null,
  detailedSideBar: null,
  showCanvas: false,
  canvasIsPreview: false,
  selectedColorIndex: 0,
  selectedColorName: 'Main',
  selectedPatternColorScheme: null,
  colors: [],
  patterns: {},
  garmentTypes: {},
  designs: [],
  sports: [],
  sceneConfig: { lightFilePath: '', sceneFilePath: '' },
  assetsLoading: true,
  paramsIsLoading: true,
  fontsLoading: true,
};

export const loadConfig = createAsyncThunk('config/load', async () => {
  const result = await axios({
    method: 'get',
    url: process.env.REACT_APP_CONFIG_FILE_PATH,
  });
  return result.data;
});

export const loadFonts = createAsyncThunk('fonts/load', async () => {
  const result = await axios({
    method: 'get',
    url: process.env.REACT_APP_FONTS_FILE_PATH,
  });

  if (debug) console.log('data from json with fronts: ', result.data);
  result.data.forEach((fontName) => {
    try {
      PIXI.Loader.shared.add({
        name: fontName,
        url: `/fonts/${fontName}.ttf`,
      });
    } catch (e) {
      if (debug) console.log(e);
    }
  });
  if (debug)
    PIXI.Loader.shared.onProgress.add(() => {
      // console.log('loaded resource: ', res);
    });
  if (debug)
    PIXI.Loader.shared.onComplete.once(() => {
      // console.log('fonts loaded', res);
    });
  if (debug)
    PIXI.Loader.shared.onError.add((_, res) => {
      console.log('error');
      console.log({ loader: _, res });
    });
  PIXI.Loader.shared.load();
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setFirstLoading: (state, { payload }) => {
      state.firstLoading = payload;
    },
    setPageId: (state, { payload }) => {
      state.showCanvas = payload === 4;
      state.canvasIsPreview = payload === 5;
      state.pageId = payload;
    },
    setPageNext: (state) => {
      const newPage = state.pageId + 1;
      state.showCanvas = newPage === 4;
      state.canvasIsPreview = newPage === 5;
      state.pageId = newPage;
    },
    setPagePrev: (state) => {
      const newPage = state.pageId - 1;
      state.showCanvas = newPage === 4;
      state.canvasIsPreview = newPage === 5;
      state.pageId = newPage;
    },
    setIsMenuOpen: (state, { payload }) => {
      state.isMenuOpen = payload;
    },
    setModal: (state, { payload }) => {
      state.modal = payload;
    },
    setDetailedSideBar: (state, { payload }) => {
      state.detailedSideBar = payload;
    },
    toggleGarmentSelectOpen: (state) => {
      state.isGarmentSelectOpen = !state.isGarmentSelectOpen;
    },
    setSelectedColorIndex: (state, { payload }) => {
      state.selectedColorIndex = payload;
    },
    setSelectedColorName: (state, { payload }) => {
      state.selectedColorName = payload;
    },
    setShowCanvas: (state, { payload }) => {
      state.showCanvas = payload;
      state.canvasIsPreview = !payload;
    },
    setAssetsLoading: (state, { payload }) => {
      state.assetsLoading = payload;
    },
    setParamsIsLoading: (state, { payload }) => {
      state.paramsIsLoading = payload;
    },
    resetAppSlice: (state) => {
      state.pageId = 1;
      state.detailedSideBar = null;
    },
    setSelectedPatternColorScheme: (state, { payload }) => {
      state.selectedPatternColorScheme = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadConfig.fulfilled, (state, action) => {
        state.colors = action.payload.colors.map((color) => {
          return {
            ...color,
            rgb: `rgb(${color.rgbComponents.join(',')})`,
            hex: convertRgbToHex(
              color.rgbComponents[0],
              color.rgbComponents[1],
              color.rgbComponents[2],
            ),
          };
        });
        state.sports = action.payload.sports;
        state.patterns = action.payload.patterns;
        state.garmentTypes = action.payload.garmentTypes;
        state.designs = action.payload.designs;
        state.sceneConfig = action.payload.scene;
      })
      .addCase(loadFonts.fulfilled, (state) => {
        state.fontsLoading = false;
      });
  },
});

export const selectAllColors = (state) => state.app.colors;
export const selectAllSports = (state) => state.app.sports;
export const selectSportById = (state, id) =>
  state.app.sports.find((s) => s.id === id);
export const selectAllGarmentTypesBySport = (state, sport): IGarmentType[] =>
  state.app.garmentTypes[sport?.code] ?? [];
export const selectAllGarmentTypes = (state) => state.app.garmentTypes;
export const selectAllPatternsBySport = (state, sport) =>
  state.app.patterns[sport?.code] ?? [];
export const selectAllDesigns = (state) => state.app.designs;
export const selectDesignsBySportAndGarmentId = (
  state,
  sportId: string,
  garmentTypeIds: string[],
): IGarment[] =>
  state.app.designs.filter((item) => {
    const sport = selectSportById(state, sportId);
    const garmentCodes: string[] = selectAllGarmentTypesBySport(state, sport)
      .filter((k) => garmentTypeIds.includes(k.id))
      .map((g) => g.code);
    return (
      item.sport === sport?.code && garmentCodes.includes(item.garmentType)
    );
  });

export const {
  setLoading,
  setPageId,
  setFirstLoading,
  setIsMenuOpen,
  setModal,
  setPageNext,
  setPagePrev,
  setDetailedSideBar,
  toggleGarmentSelectOpen,
  setSelectedColorIndex,
  setSelectedColorName,
  setShowCanvas,
  setAssetsLoading,
  setParamsIsLoading,
  resetAppSlice,
  setSelectedPatternColorScheme,
} = appSlice.actions;

export default appSlice.reducer;
