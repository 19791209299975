import {createSlice} from "@reduxjs/toolkit";
import {ContactsStateType} from "./types";

const initialState: ContactsStateType = {
  name: '',
  organization: '',
  city: '',
  countryState: '',
  email: '',
  phone: '',
  message: '',
}

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContactField: (state, { payload: { field, value } }) => {
      state[field as keyof ContactsStateType] = value as string;
    },
  }
});

export const {
  setContactField,
} = contactsSlice.actions;

export default contactsSlice.reducer;
