import React from 'react';
import MenuDrawer from "./MenuDrawer";
import MenuHeader from "./MenuHeader";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setIsMenuOpen, setModal} from "../../store/appSlice";
import useAppSelector from "../../hooks/useAppSelector";
import {Box, styled} from "@mui/material";
import MenuButton from "./MenuButton";
import {ChevronButtonRight} from "../../assets/img/svgComponents";
import startOverSvg from '../../assets/img/UI/start-over.svg';
import {SITE_LINK} from "../../constants";

const ButtonsWrap = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
`

const MainMenu = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(state => state.app.isMenuOpen);

  const handleClose = () => {
    dispatch(setIsMenuOpen(false));
  }

  const handleStartOver = () => {
    dispatch(setModal('startOver'));
  }

  const handleContact = () => {
    dispatch(setModal('contactUs'));
    handleClose();
  }

  const handleLeave = () => {
    window.open(SITE_LINK);
    handleClose();
  }

  return (
    <MenuDrawer
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <MenuHeader
        handleClose={handleClose}
      />

      <ButtonsWrap>

        <MenuButton
          variant='black'
          handleClick={handleStartOver}
        >
          <p>START OVER</p>
          <img src={startOverSvg} alt='O'/>
        </MenuButton>

        <MenuButton
          handleClick={handleContact}
        >
          <ChevronButtonRight/>
          <p>CONTACT</p>
        </MenuButton>

        <MenuButton
          handleClick={handleLeave}
        >
          <ChevronButtonRight/>
          <p>323sports</p>
        </MenuButton>

      </ButtonsWrap>
    </MenuDrawer>
  );
};

export default MainMenu;
