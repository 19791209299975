import React, {useEffect, useState} from 'react';
import {Box, styled} from "@mui/material";
import TextHeading4 from "../../../components/Typography/TextHeading4";
import useAppSelector from "../../../hooks/useAppSelector";
import {setSelectedPreviewGarmentId} from "../../../store/canvasSlice";
import useAppDispatch from "../../../hooks/useAppDispatch";
import PreviewSlider from "./PreviewSlider";
import {BREAKPOINTS} from "../../../constants";
import DecorationsNew from "./DecorationsNew";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 100%;
  }
`

const ModelColumnLayout = () => {
  const garmentIds = useAppSelector(state => state.config.garmentIds);
  const currentId = useAppSelector(state => state.canvas.selectedPreviewGarmentId);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const result = garmentIds.findIndex(item => item === currentId);

    if (result > -1) {
      setSelectedIndex(result + 1);
      return;
    }

    // if id not found, then set this id as the first id of the selected garments list
    if (garmentIds.length) {
      dispatch(setSelectedPreviewGarmentId(garmentIds[0]));
      // component will re-render
    }
  }, [garmentIds, currentId]);

  return (
    <Wrapper>
      <TextHeading4>{selectedIndex} of {garmentIds.length}</TextHeading4>
      <PreviewSlider
        garmentIds={garmentIds}
        currentId={currentId}
      />
      <DecorationsNew />
    </Wrapper>
  );
};

export default ModelColumnLayout;
