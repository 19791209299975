import React, {useCallback} from 'react';
import PaginatorArrow from "./PaginatorArrow";
import {Box, styled, useMediaQuery} from "@mui/material";
import {BREAKPOINTS, DEFAULT_LOGO_CODE, PAGES_ORDER_ARR} from "../../constants";
import useAppDispatch from "../../hooks/useAppDispatch";
import {selectAllColors, setPageNext, setPagePrev} from "../../store/appSlice";
import useAppSelector from "../../hooks/useAppSelector";
import useSelector from "../../hooks/useAppSelector";
import MobilePanel from "./MobilePanel/MobilePanel";
import {assignColorsFromSelectedToBase} from "../../utils/assignColors";
import {setBaseColorIds, setLogo, setLogosAreSet} from "../../store/configSlice";
import useDecorationForm from "../../hooks/useDecorationForm";
import useSpreadSidePanelColors from "../../hooks/useSpreadSidePanelColors";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 50px;
  padding: 0 50px;
  z-index: 10;
  pointer-events: none;

  @media all and (max-width: ${BREAKPOINTS.md}) {
    bottom: 20px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    bottom: 0;
    padding: 0;
  }
`

interface IProps {
  pageId: number;
}

const Paginator = ({ pageId }: IProps) => {
  const pageName = PAGES_ORDER_ARR[pageId];

  const dispatch = useAppDispatch();

  const baseColors = useSelector(selectAllColors);
  const selectedColorIds = useAppSelector((state) => state.config.selectedColorIds);
  const garmentIds = useAppSelector(state => state.config.garmentIds);
  const isSideBarDetailed = Boolean(useSelector(state => state.app.detailedSideBar));
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const logos = useAppSelector((state) => state.config.logos);
  const logosAreSet = useAppSelector((state) => state.config.logosAreSet);
  const patternColorScheme = useAppSelector((state) => state.config.patternColorScheme);
  const baseColorIds = useAppSelector((state) => state.config.baseColorIds);

  const { formData: { allGarmentLogos }} = useDecorationForm();
  const { handleSpread } = useSpreadSidePanelColors();

  const handleNext = () => {
    if (pageName === 'color') {
      // в момент переключения со страницы colors на следующую, мы должны назначить выбранные цвета
      const colors = assignColorsFromSelectedToBase(selectedColorIds, baseColors);
      dispatch(setBaseColorIds(colors));
    }

    if (pageName === 'garment' && !logosAreSet && logos[DEFAULT_LOGO_CODE]) {
      // в момент переключения со страницы decoration на следующую,
      // мы должны поместить загруженный логотип во все доступные слоты, но только 1 раз
      allGarmentLogos.forEach((code) => {
        dispatch(setLogo({
          code,
          img: logos[DEFAULT_LOGO_CODE],
        }))
      });
      dispatch(setLogosAreSet(true));
    }

    if (pageName === 'garment') {
      // в момент переключения со страницы decoration на следующую,
      // мы должны назначить цвет Side panels на все возможные слои всех паттернов,
      // но только если цвет Side panel не меняли на предыдущих шагах!
      const currentPatternColorId = patternColorScheme.find((k) => k.code === 'side_panel_1')?.colorId;
      const currentColorId = baseColorIds['Side panel']?.id;
      if (currentPatternColorId !== currentColorId) {
        // здесь цвета новые, либо их меняли на предыдущих шагах
        handleSpread();
      }
    }

    dispatch(setPageNext());
  };

  const handlePrev = useCallback(() => {
    dispatch(setPagePrev());
  }, []);

  if (isSideBarDetailed || pageId === 0) {
    return null;
  }

  const hideAtLastPage = pageId >= PAGES_ORDER_ARR.length - 1;
  const disableLackColors = pageName === 'color' && selectedColorIds.length < 2;
  const disableLackGarments = pageName === 'garment' && garmentIds.length === 0;

  return (
    <Wrapper>
      <PaginatorArrow
        variant='prev'
        handleClick={handlePrev}
      />
      {
        isMobile && pageName !== 'sport' &&
        <MobilePanel/>
      }
      <PaginatorArrow
        variant='next'
        handleClick={handleNext}
        disabled={disableLackColors || disableLackGarments}
        hide={hideAtLastPage}
      />
    </Wrapper>
  );
};

export default Paginator;
