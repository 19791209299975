import { IGarment } from "../../store/types/designTypes";
import cleanArray from "../../utils/cleanArray";

export interface ReturnFontStrings {
  numberFonts: string[];
  teamNameFonts: string[];
}

export const getFontsFromDesigns = (designs: IGarment[], sportId: string, patternId: string): ReturnFontStrings => {
  if (!designs || !designs.length || !sportId || !patternId) {
    return {
      numberFonts: [],
      teamNameFonts: [],
    };
  }

  const filtered = designs.filter((k) => {
    return k.sport === sportId && k.pattern === patternId;
  });

  const fontsNumber: string[] = [];
  const fontsText: string[] = [];

  filtered.forEach((k) => {
    k.config.default.playerNumbers?.forEach((number) => {
      fontsNumber.push(number.fontFamily);
    });

    k.config.default.texts?.forEach((item) => {
      fontsText.push(item.fontFamily);
    });
  });

  return {
    numberFonts: cleanArray(fontsNumber),
    teamNameFonts: cleanArray(fontsText),
  };
};
