import React from 'react';
import {styled, Box} from "@mui/material";
import {BREAKPOINTS} from "../../constants";

const Wrapper = styled(Box)`
  width: 33px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 16px;
  
  > div {
    width: 100%;
    height: 3px;
    background: black;
    margin-bottom: 5px;
  }
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 20px;
    margin-bottom: 24px;
    
    > div {
      height: 2px;
      margin-bottom: 3px;
    }
  }
`

interface IProps {
  handleClick: () => void;
}

const Burger = ({ handleClick }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
    >
      <div/>
      <div/>
      <div/>
    </Wrapper>
  );
};

export default Burger;
