import {Box, styled} from "@mui/material";

export const InputBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 260px;
`;

export const LogosWrap = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  gap: 16px;
`;

export const ModelButtonsBox = styled(Box)`
  display: flex;
  gap: 8px;
  margin: 6px 9px 17px 9px;
`;
