import React, {useState} from 'react';
import {styled} from "@mui/material";
import nextSvg from '../../assets/img/UI/next.svg';
import useSelector from "../../hooks/useAppSelector";

interface IWrapperProps {
  itemGap: number;
  contentWidth: number;
  pos: number;
}

const Wrapper = styled('div')<IWrapperProps>`
  width: max-content;
  height: 120px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  
  > button {
    height: 100%;
    width: 30px;
    background: none;
    
    img {
      width: 8px;
      margin-top: 40px;
    }
    
    &.left {
      img {
        transform: rotate(180deg);
      }
    }
  }
  
  .sliderBox {
    width: ${props => props.contentWidth + 'px'};
    overflow: hidden;
    position: relative;
    margin: 0 12px;
    
    .movingTrack {
      position: absolute;
      bottom: 0;
      left: ${props => props.pos + 'px'};
      display: flex;
      align-items: flex-end;
      gap: ${props => props.itemGap + 'px'};
      transition: .3s;
      padding-left: 3px;
    }
  }
`

interface IProps {
  maxItems: number;
  totalItems: number;
  itemWidth: number;
  itemGap: number;
  children: React.ReactNode;
}

const Slider = ({ maxItems, totalItems, itemWidth, itemGap, children }: IProps) => {
  const detailed = useSelector(state => state.app.detailedSideBar);
  const [pos, setPos] = useState(0);

  if (detailed !== 'color') {
    return null;
  }

  const contentWidth = (itemWidth + itemGap) * maxItems - itemGap;
  const totalWidth = (itemWidth + itemGap) * totalItems - itemGap;

  const handleClickLeft = () => {
    setPos(prev => {
      if (prev >= 0) {
        return 0;
      }

      return prev + itemWidth + itemGap;
    });
  }

  const handleClickRight = () => {
    setPos(prev => {
      if (prev <= contentWidth - totalWidth) {
        return prev;
      }
      return prev - (itemWidth + itemGap);
    });
  }

  return (
    <Wrapper
      itemGap={itemGap}
      contentWidth={contentWidth}
      pos={pos}
    >
      <button
        className='left'
        onClick={handleClickLeft}
      >
        <img src={nextSvg} alt='<'/>
      </button>
      <div className='sliderBox'>
        <div className='movingTrack'>
          { children }
        </div>
      </div>
      <button
        className='right'
        onClick={handleClickRight}
      >
        <img src={nextSvg} alt='>'/>
      </button>
    </Wrapper>
  );
};

export default Slider;
