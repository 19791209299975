import React, {useEffect} from 'react';
import CenteredPageContainer from "../../components/Containers/CenteredPageContainer";
import {styled, Box, useMediaQuery} from "@mui/material";
import TextHeading2 from "../../components/Typography/TextHeading2";
import ColorSelectButton from "./ColorSelectButton";
import {BREAKPOINTS} from "../../constants";
import useAppDispatch from "../../hooks/useAppDispatch";
import {addSelectedColorId, setSelectedColorIds} from "../../store/configSlice";
import useAppSelector from "../../hooks/useAppSelector";
import SideBarMobile from "../../components/SideBar/Desktop/SideBarMobile";
import {useSelector} from "react-redux";
import {selectAllColors} from "../../store/appSlice";
import {assignColorsFromBaseToSelected} from "../../utils/assignColors";

const Layout = styled(Box)`
  max-height: 75vh;
  
  .wrap {
    width: 494px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    .wrap {
      width: 437px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .wrap {
      width: 360px;
      column-gap: 0;
      row-gap: 0;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.xs}) {
    .wrap {
      width: 97%;
    }
  }
`;

const PageBaseColors = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const detailedSideBar = useAppSelector(state => state.app.detailedSideBar);
  const baseColorIds = useAppSelector(state => state.config.baseColorIds);
  const baseColors = useSelector(selectAllColors);
  const selectedColorIds = useAppSelector((state) => state.config.selectedColorIds);

  useEffect(() => {
    const selectedIds = assignColorsFromBaseToSelected(baseColorIds);
    dispatch(setSelectedColorIds(selectedIds));
  }, [baseColorIds]);

  const handleColorPick = (id: string) => {
    dispatch(addSelectedColorId(id));
  };

  const renderLayout = () => <Layout>
    <TextHeading2
      sx={{
        marginBottom: '32px',
      }}
    >
      Select up to 3 colors
    </TextHeading2>
    <div className='wrap'>
      {
        baseColors.map(color => <ColorSelectButton
          key={color.id}
          data={color}
          handleClick={() => handleColorPick(color.id)}
          selected={selectedColorIds.includes(color.id)}
        />)
      }
    </div>
  </Layout>;

  return (
    <CenteredPageContainer>
      {
        isMobile && detailedSideBar
          ? <SideBarMobile />
          : renderLayout()
      }
    </CenteredPageContainer>
  );
};

export default PageBaseColors;
