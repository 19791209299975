import React from 'react';
import CenteredPageContainer from "../../components/Containers/CenteredPageContainer";
import {styled, Box} from "@mui/material";
import {BREAKPOINTS} from "../../constants";
import useAppSelector from "../../hooks/useAppSelector";
import FormColumnLayout from "./FormColumn/FormColumnLayout";
import ModelColumnLayout from "./ModelColumn/ModelColumnLayout";

const Layout = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10vw;

  @media all and (max-width: ${BREAKPOINTS.special}) {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 100px;
    width: 100%;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    gap: 16px;
    margin-bottom: 0;
  }
`

const PagePreview = () => {
  const showCanvas = useAppSelector(state => state.app.showCanvas);

  if (showCanvas) {
    return null;
  }

  return (
    <CenteredPageContainer>
      <Layout>
        <ModelColumnLayout />
        <FormColumnLayout />
      </Layout>
    </CenteredPageContainer>
  );
};

export default PagePreview;
