import {Box, styled} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../constants";

interface IWrapperProps {
  open?: boolean;
}

interface IGarmentSelectSingleProps {
  isActive?: boolean;
}

export const GarmentSelectButton = styled(Box)<IWrapperProps>`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  user-select: none;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  
  svg {
    height: 60%;
    transform: rotate(${props => props.open ? '90deg' : '-90deg'});
    
    path {
      transition: .3s;
    }
  }

  &:hover {
    background: #000;
    color: white;
    
    svg {
      path {
        fill: ${COLORS.textGrey};
      }
    }
  }
`

export const GarmentSelectSingle = styled('button')<IGarmentSelectSingleProps>`
  height: 50px;
  background: none;
  display: flex;
  justify-content: center;
  
  img {
    height: 100%;
    max-width: 53px;
    pointer-events: none;
    transition: .3s;
    opacity: ${props => props.isActive ? 1 : .5};
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    height: 40px;

    img {
      max-width: 40px;
    }
  }
`
