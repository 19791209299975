import React from 'react';
import {Box, styled} from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";

const Wrapper = styled(Box)`
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
  
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  user-select: none;
  
  .spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    
    div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #333333;
      }

      &:nth-of-type(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }

      &:nth-of-type(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }

      &:nth-of-type(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }

      &:nth-of-type(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }

      &:nth-of-type(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }

      &:nth-of-type(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }

      &:nth-of-type(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }

      &:nth-of-type(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }

      &:nth-of-type(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }

      &:nth-of-type(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }

      &:nth-of-type(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }

      &:nth-of-type(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
    }
    
  }
`

const Spinner = () => {
  const loading = useAppSelector(state => state.app.loading);

  if (!loading) {
    return null;
  }

  return (
    <Wrapper>
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Wrapper>
  );
};

export default Spinner;
