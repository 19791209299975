import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  ConfigStateType, IPatternColorScheme,
  IPayloadPatternColor,
} from "./types";
import putValueToArray from "../utils/putValueToArray";
import { cloneDeep } from "lodash";
import { RootState } from "./index";
import {basicColor, DEFAULT_TEXT_CODE} from "../constants";
import {IGarment} from "./types/designTypes";

const initialState: ConfigStateType = {
  sportId: null,
  baseColorIds: {},
  selectedColorIds: [],
  number: "",
  garmentIds: [],
  patternId: "1",
  backupValues: null,
  texts: {
    [DEFAULT_TEXT_CODE]: '',
  },
  logos: {},
  logosAreSet: false,
  patternColorScheme: [],
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setSportId: (state, { payload }) => {
      state.sportId = payload;
    },
    addColorId: (state, { payload: { name, color } }) => {
      state.baseColorIds[name] = color;
    },
    setBaseColorIds: (state, { payload }) => {
      state.baseColorIds = payload;
    },
    addSelectedColorId: (state, { payload }) => {
      const newArray = putValueToArray(state.selectedColorIds, payload);

      if (newArray.length >= 4) {
        newArray.splice(0, 1);
      }

      state.selectedColorIds = newArray;
    },
    setSelectedColorIds: (state, { payload }) => {
      state.selectedColorIds = payload;
    },
    setNumber: (state, { payload }) => {
      state.number = payload;
    },
    setText: (state, { payload: { code, text } }) => {
      state.texts[code] = text;
    },
    removeText: (state, { payload }) => {
      delete state.texts[payload];
    },
    setLogo: (state, { payload: { code, img } }) => {
      state.logos[code] = img;
    },
    setLogosAreSet: (state, { payload }) => {
      state.logosAreSet = payload;
    },
    removeLogo: (state, { payload }) => {
      delete state.logos[payload];
    },
    setGarmentIds: (state, { payload }) => {
      state.garmentIds = putValueToArray(state.garmentIds, payload);
    },
    setPatternId: (state, { payload }) => {
      state.patternId = payload;
    },
    updatePatternColorScheme: (state, { payload }: { payload: IGarment }) => {
      if (!payload) {
        return;
      }
      state.patternColorScheme = state.patternColorScheme.map((item, index) => ({
        ...item,
        display: payload.config.default.layers[index]?.name || `Side panel ${index}`,
      }));
    },
    backupValues: (state) => {
      state.backupValues = {
        baseColorIds: cloneDeep(state.baseColorIds),
        selectedColorIds: state.selectedColorIds,
        number: state.number,
        patternId: state.patternId,
        texts: cloneDeep(state.texts),
        logos: cloneDeep(state.logos),
      };
    },
    restoreValues: (state) => {
      if (state.backupValues !== null) {
        for (const key in state.backupValues) {
          state[key] = state.backupValues[key];
        }
      }
    },
    reset: () => initialState,
    setPatternsColorSchemes: (state, { payload }: { payload: IPatternColorScheme[] }) => {
      state.patternColorScheme = payload;
    },
    setPatternColorId: (state, { payload: { colorId, layerCode } }: IPayloadPatternColor) => {
      const index = state.patternColorScheme.findIndex((k) => k.code === layerCode) ?? -1;
      state.patternColorScheme[index].colorId = colorId;
    },
  },
});

export const baseColorIdsSelector = createSelector(
  (state: RootState) => state.config.baseColorIds,
  (baseColorIds) => {
    return Object.entries(baseColorIds)
      .map(([key, value]) => {
        if (basicColor.includes(key)) {
          return value.id;
        }
      })
      .filter((k) => k);
  }
);

export const {
  setSportId,
  addColorId,
  setNumber,
  setText,
  removeText,
  setLogo,
  setLogosAreSet,
  setGarmentIds,
  setPatternId,
  backupValues,
  restoreValues,
  reset,
  addSelectedColorId,
  setBaseColorIds,
  setSelectedColorIds,
  removeLogo,
  setPatternsColorSchemes,
  setPatternColorId,
  updatePatternColorScheme,
} = configSlice.actions;

export default configSlice.reducer;
