import React from 'react';
import {styled, css} from "@mui/material";
import { COLORS, SHOW_COLOR_IDS } from '../../../../constants';

interface IWrapperProps {
  rgb: string;
  selected: boolean;
  dropdown: boolean;
}

const selectedCss = css`
  font-weight: 600;
  font-size: 18px;
`

const dropdownCss = css`
  width: 100%;
  padding-right: 0;
  
  .title {
    font-size: 14px;
    font-style: normal;
  }
`

const Wrapper = styled('button')<IWrapperProps>`
  background: none;
  width: max-content;
  padding-right: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: .3s;
  height: 24px;

  .colorCircle {
    background: ${props => props.rgb};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    p {
      color: #dadada;
      font-size: 11px;
    }
  }

  .title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 120%;
    color: ${COLORS.textDarkGrey};
    text-align: left;

    ${props => props.selected && selectedCss}
  }

  ${props => props.dropdown && dropdownCss}
`

interface IProps {
  title: string;
  id: string;
  rgb: string;
  selected: boolean;
  handleClick: () => void;
  dropdown?: boolean;
}

const ButtonColorSwitch = ({ title, rgb, id, selected, handleClick, dropdown }: IProps) => {
  return (
    <Wrapper
      rgb={rgb}
      selected={selected}
      onClick={handleClick}
      dropdown={dropdown}
    >
      <div className='colorCircle'>
        {
          SHOW_COLOR_IDS && <p>{id}</p>
        }
      </div>
      <p className='title'>{ title }</p>
    </Wrapper>
  );
};

export default ButtonColorSwitch;
