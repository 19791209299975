import {css, styled} from '@mui/material';

export const AnimationSideBarButtonCss = css`
  @keyframes leftButtons {
    80% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  transform: translateX(-200px);
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-name: leftButtons;
`;

export const RemoveIcon = styled('div')`
  z-index: 2;
  width: 24px;
  height: 24px;
  background: #F6F7F9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  img {
    max-height: 13px;
  }
`;
