import getMaxNumberOfLayers from "../utils/getMaxNumberOfLayers";
import generatePatternColorScheme from "../utils/generatePatternColorScheme";
import {setPatternsColorSchemes} from "../store/configSlice";
import useAppSelector from "./useAppSelector";
import {selectDesignsBySportAndGarmentId} from "../store/appSlice";
import useAppDispatch from "./useAppDispatch";
import {useCallback} from "react";

interface IReturnSpread {
  handleSpread: () => void;
}

/**
 * Хук используется для распределения выбранных юзером цветов на начальном экране среди паттернов
 * Активируется, если:
 * а) первый раз дошли до страницы garments
 * б) решили вернуться назад, изменили один из цветов на начальном экране, а потом снова перешли на garments
 */

const useSpreadSidePanelColors = (): IReturnSpread => {
  const sportId = useAppSelector((state) => state.config.sportId);
  const garmentIds = useAppSelector(state => state.config.garmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, sportId, garmentIds));
  const selectedColorIds = useAppSelector((state) => state.config.selectedColorIds);
  const patternId = useAppSelector((state) => state.config.patternId);

  const dispatch = useAppDispatch();

  const handleSpread = useCallback(() => {
    const maxLayers = getMaxNumberOfLayers(filteredDesigns);
    const design = filteredDesigns.filter((k) => k.pattern === patternId && k.garmentType === 'jersey')[0];
    // здесь selectedColorIds[1] - это Side panel
    const result = generatePatternColorScheme(maxLayers, selectedColorIds[1], design);
    dispatch(setPatternsColorSchemes(result));
  }, [filteredDesigns, selectedColorIds, patternId]);

  return {
    handleSpread,
  }
}

export default useSpreadSidePanelColors;
