/**
 * Returns the next value in the array, knowing the current one
 * If the value is out of range of the array, then returns the value from the beginning
 * @param array
 * @param current
 */

export function switchArrayToNextItem<T>(array: T[], current: T): T {
  const currentIndex = array.findIndex(item => item === current);

  if (currentIndex === -1) {
    return current;
  }

  const newIndex = currentIndex + 1;

  if (newIndex >= array.length) {
    return array[0];
  }

  return array[newIndex];
}

/**
 * Returns the previous value in the array, knowing the current one
 * If the value is out of range of the array, then returns the value from the end
 * @param array
 * @param current
 */

export function switchArrayToPreviousItem<T>(array: T[], current: T): T {
  const currentIndex = array.findIndex(item => item === current);

  if (currentIndex === -1) {
    return current;
  }

  const newIndex = currentIndex - 1;

  if (newIndex < 0) {
    return array.at(-1);
  }

  return array[newIndex];
}
