import {IBaseColor} from "../store/types";
import {uniq} from "lodash";

/**
 * Назначает цвета selectedColorIds из начального экрана (выбранные юзером) на baseColorIds
 *
 * Main: ids[0] - обязательный
 * Side panel: ids[1] - обязательный
 * Accent1: ids[2] - не обязательный
 *
 * Если Accent1 отсутствует, взять из Side panel
 *
 * Text - берет из Accent1
 * Text outline - берет из Side panel
 * Number - берет из Accent1
 * Number outline - берет из Side panel
 * @param ids
 * @param allColors
 */

export const assignColorsFromSelectedToBase = (ids: string[], allColors: IBaseColor[]): Record<string, IBaseColor> => {
  const result: Record<string, IBaseColor> = {};

  const main = allColors.find((k) => k.id === ids[0]);
  const sidePanel = allColors.find((k) => k.id === ids[1]);
  const accent1 = ids[2] ? allColors.find((k) => k.id === ids[2]) : sidePanel;

  result.Main = main;
  result['Side panel'] = sidePanel;
  result.Accent1 = accent1;
  result.Text = accent1;
  result['Text outline'] = sidePanel;
  result.Number = accent1;
  result['Number outline'] = sidePanel;

  return result;
}

/**
 * Забирает id цветов из baseColorIds и назначает их на selectedColorIds из начального экрана
 *
 * Main id становится 0 индексом массива
 * Side panel id становится 1 индексом массива
 * Accent1 становится 2 индексом массива
 *
 * Если значение falsy или повторяется, то в массив включено не будет
 * @param baseColorIds
 */

export const assignColorsFromBaseToSelected = (baseColorIds: Record<string, IBaseColor>): string[] => {
  const result = [baseColorIds.Main?.id, baseColorIds['Side panel']?.id, baseColorIds.Accent1?.id]
    .filter((k) => k);

  return uniq(result);
}
