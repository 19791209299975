import React from 'react';
import {styled, SxProps, Typography} from "@mui/material";
import {BREAKPOINTS, COLORS} from '../../constants';

const Wrapper = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 125%;
  color: ${COLORS.textDarkGrey};
  text-align: center;
  
  @media all and (max-width: ${BREAKPOINTS.md}) {
    font-size: 30px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
  }
`

interface IProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const TextHeading2 = ({ children, sx }: IProps) => {
  return (
    <Wrapper
      variant='h2'
      sx={sx}
    >
      { children }
    </Wrapper>
  );
};

export default TextHeading2;
