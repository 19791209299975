import React from 'react';
import {Box, styled} from "@mui/material";

const Wrapper = styled(Box)`
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
  width: 70px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > button {
    clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
    width: 68px;
    border: 1px solid #000000;
    background: #FFFFFF;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    padding: 5px 0;
    transition: 0.3s;
  }
  
  &:hover {
    > button {
      background: black;
      color: white;
    }
  }
`

interface IProps {
  children: React.ReactNode;
  handleClick: () => void;
}

const ButtonModal = ({ children, handleClick }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
    >
      <button>
        { children }
      </button>
    </Wrapper>
  );
};

export default ButtonModal;
