import React, {
  useEffect,
  useState,
  CSSProperties,
  useLayoutEffect,
} from 'react';
import useSelector from '../../hooks/useAppSelector';
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  addScreenshot, setLoadingScreenshots,
  setSelectedPreviewGarmentId,
} from '../../store/canvasSlice';
import {
  selectAllDesigns,
  selectAllGarmentTypesBySport,
} from '../../store/appSlice';
import { App } from '../../app3d/App';
import { makeGarmentParamsForPreview } from '../../utils/makeGarmentParams';
import type { IGarment } from '../../store/types/designTypes';

type canvasStyle = {
  width: string;
  height: string;
  top: 0;
  position: string;
  display: string;
};

interface IProps {
  CanvasElement: JSX.Element;
  app: App;
  initialPage: number;
}

const Canvas3DShots = ({ CanvasElement, app, initialPage }: IProps) => {
  const pageId = useAppSelector((state) => state.app.pageId);
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const showCanvas = useSelector((state) => state.app.showCanvas);
  const canvasIsPreview = useSelector((state) => state.app.canvasIsPreview);
  const selectedPreviewGarmentId = useAppSelector(
    (state) => state.canvas.selectedPreviewGarmentId,
  );
  let selectedGarmentIds = useAppSelector(
    (state) => state.canvas.selectedGarmentIds,
  );
  selectedGarmentIds = canvasIsPreview
    ? [selectedPreviewGarmentId ?? '0']
    : selectedGarmentIds;

  const selectedNumber = useAppSelector((state) => state.config.number);
  const garmentIds = useAppSelector((state) => state.config.garmentIds);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId),
    ),
  );

  const dispatch = useAppDispatch();

  const designs = useSelector(selectAllDesigns);

  const selectedGamentIds = useAppSelector((state) => state.config.garmentIds);
  const firstLoading = useAppSelector((state) => state.app.firstLoading);
  const [screenshotsIsReady, setScreenshotsIsReady] = useState(false);
  useLayoutEffect(() => {
    if (screenshotsIsReady) {
      app.destroy();
    }
  }, [screenshotsIsReady]);
  // dispatch(setGamentPatternsForPreview(garmentPatterns));

  // const { gamentPatternsForPreview } = useAppSelector((state) => state.canvas);
  useEffect(() => {
    // we need to specify sport type for garments
    if (!sports.length) return;
    app.setCurrentSport(sports.find((s) => s.id === selectedSportId)?.code);
  }, [selectedSportId]);

  useEffect(() => {
    if (garmentList.length) {
      const garments = garmentList.filter((g) => garmentIds.includes(g.id));
      garments.forEach((garment) => app.addGarment(garment.code));
    }
  }, [garmentIds]);

  useEffect(() => {
    // we need to select at least one garment in the slider scene
    if (canvasIsPreview && garmentIds.length && !selectedPreviewGarmentId) {
      dispatch(setSelectedPreviewGarmentId([garmentIds[0]]));
    }
  }, [selectedGarmentIds, garmentIds, canvasIsPreview]);

  useEffect(() => {
    app.setupDesigns(designs);
  }, []);

  useEffect(() => {
    if (!showCanvas || firstLoading) return;
    async function makeScreenshots() {
      setScreenshotsIsReady(false);
      dispatch(setLoadingScreenshots(true));
      function getGamentPatternsForPreviewForGarment(garmentId) {
        const garment = garmentList.find((g) => g.id === garmentId);
        const currentSport = sports.find((s) => s.id === selectedSportId)?.code;
        const gamentPatternsForPreview = designs.filter(
          (g) => g?.garmentType === garment?.code && g?.sport === currentSport,
        );
        return gamentPatternsForPreview;
      }
      const gamentPatternsForPreviewPerGarment = {};
      selectedGamentIds.forEach((id) => {
        gamentPatternsForPreviewPerGarment[id] =
          getGamentPatternsForPreviewForGarment(id);
      });
      const labels = [];
      const promises: Promise<void>[] = [];
      const garmentsWithPatterns = Object.values(
        gamentPatternsForPreviewPerGarment,
      )[0] as IGarment[];
      for (const garmentOfFirstSelectedElemInScene of garmentsWithPatterns) {
        const patternId = garmentOfFirstSelectedElemInScene.pattern;
        let labelPart = '';
        for (const garmentTypeId of selectedGamentIds) {
          const garment: IGarment = gamentPatternsForPreviewPerGarment[
            garmentTypeId
          ].find((p) => p.pattern === patternId);
          if (!garment) {
            continue;
          }
          await app.setupParams(
            garment.garmentType,
            makeGarmentParamsForPreview(
              garment,
              selectedNumber,
              garment.pattern,
              [],
              '',
              'preview',
            ),
          );
          labelPart += `${garment.garmentType}_`;
        }
        if (selectedGamentIds.length === 1) {
          app.showSingleGarment(garmentOfFirstSelectedElemInScene.garmentType, true);
        } else {
          app.showAllGarments();
        }
        const label = `${labelPart}${garmentOfFirstSelectedElemInScene.pattern}`;
        app.setShotMode();
        app.makeScreenshot(label);
        labels.push(label);
        promises.push(
          app.getScreenshots([label]).then((screenshots) => {
            dispatch(
              addScreenshot({
                key: garmentOfFirstSelectedElemInScene.pattern,
                value: screenshots[0],
              }),
            );
          }),
        );
      }
      // for (const garment of gamentPatternsForPreview) {
      //   await app.setupParams(garment.garmentType, makeGarmentParamsForPreview(garment, selectedNumber, garment.pattern, logos, ""));
      //   app.showSingleGarment(garment.garmentType);
      //   app.makeScreenshot(`${firstSelectedGarmentId}_${garment.pattern}`);
      //   labels.push(`${firstSelectedGarmentId}_${garment.pattern}`);
      // }
      // const screenshots = await app.getScreenshots(labels);
      // dispatch(setScreenshots(screenshots));
      Promise.all(promises).then(() => {
        dispatch(setLoadingScreenshots(false));
        setScreenshotsIsReady(true);
      });
    }
    async function _() {
      await app.prepareSelectedGarment();
      makeScreenshots();
    }
    _();
  }, [designs, showCanvas, selectedGamentIds, firstLoading]);

  const [style, setStyle] = useState<canvasStyle>();
  useEffect(() => {
    // We need to show the main scene on page 4 or when the preview is enabled, and the slider scene on page 5
    if (initialPage === 5) {
      canvasIsPreview && !showCanvas && app.setContainerTo(1);
    }
    if (initialPage === 4) {
      showCanvas && app.setContainerTo(0);
    }
  }, [pageId, canvasIsPreview, showCanvas]);

  useEffect(() => {
    setStyle({
      width: '0%',
      height: '0%',
      top: 0,
      position: 'fixed',
      display: 'block',
    });
  }, [pageId, canvasIsPreview, showCanvas]);

  return <div style={style as CSSProperties}>{CanvasElement}</div>;
};

export default Canvas3DShots;
