import {TextConfig} from "../store/types/designTypes";

export default function getFirstAvailableTextKey(allTexts: TextConfig[], selectedCodes: string[]): string {
  for (let i = 0; i < allTexts.length; i++) {
    if (!selectedCodes.includes(allTexts[i].code)) {
      return allTexts[i].code;
    }
  }

  return '';
}
