import {Box, styled, Typography} from "@mui/material";

export const DecorationsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 30px 0 32px 0;
`;

export const DecorationsRowBox = styled(Box)`
  display: flex;
`;

export const DecorationLeftText = styled(Typography)`
  color: #5E5E5E;
  text-align: right;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 24px;
  width: 100px;
  margin-right: 16px;
`;
