import React from 'react';
import {styled, Box} from "@mui/material";
import {BREAKPOINTS} from "../../constants";
import ColorCircle from "./ColorCircle";
import {IBaseColor} from "../../store/types/types";

const Wrapper = styled(Box)`
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: .3s;
  position: relative;
  
  > button {
    width: 44px;
    height: 44px;
  }
  
  @media all and (max-width: ${BREAKPOINTS.lg}) {
    width: 64px;
    height: 64px;
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    width: 54px;
    height: 54px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 60px;
    height: 60px;
  }

  @media all and (max-width: ${BREAKPOINTS.xs}) {
    width: 45px;
    height: 45px;

    > button {
      width: 37px;
      height: 37px;
    }
  }
`

interface IProps {
  data: IBaseColor;
  handleClick: () => void;
  selected: boolean;
}

const ColorSelectButton = ({ data, handleClick, selected }: IProps) => {
  return (
    <Wrapper>
      <ColorCircle
        handleClick={handleClick}
        data={data}
        selected={selected}
      />
    </Wrapper>
  );
};

export default ColorSelectButton;
