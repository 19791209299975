import React, {useRef} from 'react';
import {styled} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../constants";
import {UploadSvg} from "../../assets/img/svgComponents";
import clearFileInput from "../../utils/clearFileInput";

const Wrapper = styled('label')`
  width: 170px;
  height: 52px;
  background: #000;
  clip-path: polygon(18px 0, 100% 0, calc(100% - 18px) 100%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  .imageWrap {
    clip-path: polygon(18px 0, 100% 0, calc(100% - 17.5px) 100%, .5px 100%);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: ${COLORS.greyBg};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    
    svg {
      width: 30%;
      path {
        transition: .3s;
      }
    }
  }
  
  #inputFile {
    display: none;
  }
  
  &:hover {
    .imageWrap {
      background: white;
      
      svg {
        path {
          fill: black;
        }
      }
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .imageWrap {
      background: white;
      
      svg {
        width: 30px;
        path {
          fill: black;
        }
      }
    }
  }
`

interface IProps {
  style?: React.CSSProperties;
  handleChange: (files: FileList | null) => void;
}

const AddLogoButton = ({ style, handleChange }: IProps) => {
  const InputRef = useRef(null);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.files);
  }

  const clearInput = () => {
    clearFileInput(InputRef.current);
  }

  return (
    <Wrapper
      style={style}
      htmlFor='inputFile'
    >
      <div className='imageWrap'>
        <UploadSvg />
      </div>

      <input
        id='inputFile'
        ref={InputRef}
        type='file'
        accept='image/*'
        onChange={onChangeInput}
        onClick={clearInput}
      />
    </Wrapper>
  );
};

export default AddLogoButton;
