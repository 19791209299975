import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import {removeLogo, removeText, setLogo, setNumber, setText} from "../store/configSlice";
import {ChangeEvent, useEffect, useState} from "react";
import useSelector from "./useAppSelector";
import { selectAllGarmentTypesBySport, selectAllPatternsBySport } from "../store/appSlice";
import getFirstAvailableTextKey from "../utils/getFirstAvailableTextKey";
import {LogoConfig, TextConfig} from "../store/types/designTypes";
import {uniq} from "lodash";

interface IReturnDecorations {
  formData: {
    selectedGarmentLogos: LogoConfig[];
    selectedGarmentTexts: TextConfig[];
    allGarmentLogos: string[];
    allTitleTexts: string[];
  },
  actions: {
    onChangeNumber: (e: ChangeEvent<HTMLInputElement>) => void;
    onRemovePreview: (code: string) => void;
    onTextChange: (code: string, text: string) => void;
    onLogoChange: (code: string, files: FileList | null) => void;
    onAddTextClick: () => void;
    onRemoveTextClick: (code: string) => void;
  },
}

const useDecorationForm = (): IReturnDecorations => {
  const dispatch = useAppDispatch();

  const [selectedGarmentLogos, setSelectedGarmentLogos] = useState<LogoConfig[]>([]);
  const [selectedGarmentTexts, setSelectedGarmentTexts] = useState<TextConfig[]>([]);
  const [allGarmentLogos, setAllGarmentLogos] = useState<string[]>([]);
  const [allTitleTexts, setAllTitleTexts] = useState<string[]>([]);

  const texts = useAppSelector((state) => state.config.texts);
  const garments = useAppSelector((state) => state.app.designs);
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patterns = useSelector((state) =>
    selectAllPatternsBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );
  const selectedPatternId = useAppSelector((state) => state.config.patternId);
  const garmentTypes = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const selectedGarmentTypesIds = useSelector((state) => state.canvas.selectedGarmentIds);

  useEffect(() => {
    if (selectedGarmentTypesIds.length !== 1) return;

    const selectedSport = sports.find((s) => s.id === selectedSportId);
    const selectedPattern = patterns.find((p) => p.id === selectedPatternId);
    const garmentType = garmentTypes?.find((g) => g.id === selectedGarmentTypesIds[0]);

    const garment =
      selectedSport && selectedPattern && garmentType
        ? garments.find((g) => {
            return (
              g.sport === selectedSport.code && g.pattern === selectedPattern.code && garmentType.code === g.garmentType
            );
          })
        : undefined;

    const selectedTexts = garment?.config?.default?.texts || [];
    const selectedLogos = garment?.config?.default?.logos || [];
    setSelectedGarmentTexts(selectedTexts);
    setSelectedGarmentLogos(selectedLogos);

    let allLogos: string[] = [];
    const logos = garments
      .filter((g) => g.sport === selectedSport.code)
      .map((g) => g?.config?.default?.logos);
    if (logos) {
      allLogos = logos.flat().map((l) => l?.code).filter((k) => k);
    }

    const allTitleTexts = garments
      .filter((g) => g.sport === selectedSport.code && g.pattern == selectedPattern.code)
      .map((g) => g?.config?.default?.texts?.[0]?.code);

    setAllGarmentLogos(uniq(allLogos));
    setAllTitleTexts(allTitleTexts);
  }, [garments, sports, patterns, selectedPatternId, selectedSportId, selectedGarmentTypesIds, garmentTypes]);

  const onChangeNumber = (e) => {
    const val = e.target.value;
    if (val.length < 3 && val >= 0) {
      dispatch(setNumber(val));
    }
  };

  const onRemovePreview = (code) => {
    dispatch(removeLogo(code));
  };

  const onTextChange = (code, text) => {
    dispatch(setText({ code, text }));
  };

  const onLogoChange = (code, files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.onload = () => {
      dispatch(setLogo({ code, img: reader.result }));
    };

    reader.readAsDataURL(file);
  };

  const onAddTextClick = () => {
    const usedKeys = Object.keys(texts);
    const code = getFirstAvailableTextKey(selectedGarmentTexts, usedKeys);
    dispatch(setText({ code, text: '' }));
  }

  const onRemoveTextClick = (code: string) => {
    dispatch(removeText(code));
  }

  return {
    formData: {
      selectedGarmentLogos,
      selectedGarmentTexts,
      allGarmentLogos,
      allTitleTexts,
    },
    actions: {
      onChangeNumber,
      onRemovePreview,
      onTextChange,
      onLogoChange,
      onAddTextClick,
      onRemoveTextClick,
    },
  };
};

export default useDecorationForm;
