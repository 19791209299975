import {styled, TextField} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../../constants";

const FormInput = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #5E5E5E;
    background: #FFFFFF;
    border: 1px solid ${COLORS.border};
    border-radius: 0;

    .MuiInputBase-input {
      height: 54px;
      box-sizing: border-box;
      resize: vertical;
    }
  }

  fieldset {
    border: none;
  }

  @media all and (max-width: ${BREAKPOINTS.lg}) {
    .MuiInputBase-root {
      font-size: 16px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: 44px;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: 38px;
      }
    }
  }
`

export default FormInput;
