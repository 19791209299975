import React from 'react';
import TextHeading3 from "../Typography/TextHeading3";
import ButtonsContainer from "../Containers/ButtonsContainer";
import ButtonModal from "./ButtonModal";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setDetailedSideBar, setIsMenuOpen, setModal, setPageId} from "../../store/appSlice";
import {setSelectedGarmentIds, setSelectedPreviewGarmentId} from "../../store/canvasSlice";
import {StyledModal} from "./style";

const StartOver = () => {
  const dispatch = useAppDispatch();

  const handleYes = () => {
    dispatch(setModal(null));
    dispatch(setIsMenuOpen(false));
    dispatch(setDetailedSideBar(null));
    dispatch(setSelectedGarmentIds([]));
    dispatch(setSelectedPreviewGarmentId('0'));
    dispatch(setPageId(0));
    location.reload();
  }

  const handleNo = () => {
    dispatch(setModal(null));
  }

  return (
    <StyledModal>
      <div className='innerBox'>
        <TextHeading3>Are You Sure You Want To Start Over?</TextHeading3>
        <ButtonsContainer
          style={{ width: '75%' }}
        >
          <ButtonModal
            handleClick={handleYes}
          >
            Yes
          </ButtonModal>
          <ButtonModal
            handleClick={handleNo}
          >
            No
          </ButtonModal>
        </ButtonsContainer>
      </div>
    </StyledModal>
  );
};

export default StartOver;
