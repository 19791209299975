import useAppDispatch from "./useAppDispatch";
import {setShowCanvas} from "../store/appSlice";
import {setSelectedGarmentIds} from "../store/canvasSlice";
import useAppSelector from "./useAppSelector";

const usePreviewButton = () => {
  const pressed = useAppSelector(state => state.app.showCanvas);
  const configuresGarmentIds = useAppSelector(state => state.config.garmentIds);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    const newValue = !pressed;
    dispatch(setShowCanvas(newValue));

    if (newValue) {
      // must be selected all configured garments
      dispatch(setSelectedGarmentIds(configuresGarmentIds));
    }
  }

  return {
    pressed,
    onPress: handleClick,
  }
}

export default usePreviewButton;
