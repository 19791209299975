import {Box, styled} from "@mui/material";
import {BREAKPOINTS} from "../../constants";

export const StyledModal = styled(Box)`
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
  width: 449px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .innerBox {
    width: 447px;
    background: white;
    border: 1px solid black;
    clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
    padding: 52px 80px 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 92%;
    
    .innerBox {
      width: 99%;
      gap: 20px;
    }
  }
`;

export const ContactUsWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background: white;
  
  > button {
    background: none;
    position: absolute;
    top: 20px;
    right: 5%;
    z-index: 10;
    
    img {
      width: 20px;
    }
  }
  
  .formWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    height: 100%;
    width: 100%;
    padding: 0 5%;
    overflow-y: auto;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    position: fixed;
    top: 0;
    left: 0;

    .formWrap {
      height: 100vh;
    }
  }
`;
