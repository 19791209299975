import { CanvasStateType } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CanvasStateType = {
  selectedGarmentIds: [], // garments that we are selecting in the header dropdown menu
  selectedPreviewGarmentId: null, // garment that selected in 'preview' page
  screenshots: {},
  loading: false,
};

export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setSelectedGarmentIds: (state, { payload }) => {
      if (payload.length === 1) {
        if (state.selectedGarmentIds.includes(payload[0]) && state.selectedGarmentIds.length > 1) {
          state.selectedGarmentIds = state.selectedGarmentIds.filter((item) => item !== payload[0]);
        } else if (!state.selectedGarmentIds.includes(payload[0])) {
          state.selectedGarmentIds.push(payload[0]);
        }
      } else {
        state.selectedGarmentIds = payload;
      }
    },
    setSelectedGarmentAllIds: (state, { payload }) => {
      state.selectedGarmentIds = payload;
    },
    setSelectedPreviewGarmentId: (state, { payload }) => {
      state.selectedPreviewGarmentId = payload;
    },
    addScreenshot(state, { payload: { key, value } }) {
      state.screenshots[key] = value;
    },
    setLoadingScreenshots(state, { payload }: { payload: boolean }) {
      state.loading = payload;
    },
    clearScreenshots(state) {
      state.screenshots = {};
    },
    reset: () => initialState,
  },
});

export const {
  addScreenshot,
  setSelectedGarmentIds,
  setSelectedPreviewGarmentId,
  reset,
  setSelectedGarmentAllIds,
  setLoadingScreenshots,
} = canvasSlice.actions;

export default canvasSlice.reducer;
