import React from 'react';
import {styled, Typography} from "@mui/material";
import {BREAKPOINTS, COLORS} from '../../constants';

const Wrapper = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  text-align: center;
  color: ${COLORS.textDarkGrey};
  word-spacing: .3em;
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    font-size: 18px;
    line-height: 1;
    font-style: italic;
  }
`

interface IProps {
  children: React.ReactNode;
}

const TextHeading4 = ({ children }: IProps) => {
  return (
    <Wrapper
      variant='h4'
    >
      { children }
    </Wrapper>
  );
};

export default TextHeading4;
