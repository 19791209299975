import React from 'react';
import {Button, css, styled} from "@mui/material";

interface WrapperProps {
  isselected: string;
}

const Wrapper = styled(Button)<WrapperProps>`
  width: 55px;
  height: 56px;
  background: ${props => props.isselected === 'true' ? 'black' : 'white'};
  border: 1px solid ${props => props.disabled ? '#999999' : '#333333'};
  transform: skew(-18deg);
  cursor: pointer;
  border-radius: 0;
  min-width: unset;
  
  ${props => props.isselected === 'true' && css`
    &:hover {
      background: black;
    }
  `};
  
  img {
    max-height: 24px;
    max-width: 24px;
    transform: skew(18deg);
  }
  
  ${props => props.isselected === 'true' && css`
    img {
      filter: invert(100%);
    }
  `}
`;

interface IProps {
  isSelected: boolean;
  disabled?: boolean;
  onClick: () => void;
  imgSrc: string;
}

const ButtonDecorationModel = ({ isSelected, disabled, imgSrc, onClick }: IProps) => {
  return (
    <Wrapper
      disableRipple
      disabled={disabled}
      isselected={String(isSelected)}
      onClick={onClick}
    >
      <img src={imgSrc} alt=''/>
    </Wrapper>
  );
};

export default ButtonDecorationModel;
