import { App } from "../app3d/App";
// import { SceneManager } from "../scene/SceneManager";
import { useCallback } from "react";

type appProps = {
  sceneConfig;
  app: App;
  garmentList;
  render?: boolean;
};
export default function useApp3dRefCallback(appProps: appProps): (HTMLElement) => void {
  const { sceneConfig, app, garmentList, render = true } = appProps;
  return useCallback(async (container: HTMLElement) => {
    await app.init(sceneConfig, garmentList);
    app.setContainer(container, render);
  }, []);
}
