import React from 'react';
import {Box, styled} from "@mui/material";
import ButtonDecorationModelMobile from "../../UI/ButtonDecorationModelMobile";
import useSelector from "../../../../hooks/useAppSelector";
import {selectAllGarmentTypesBySport} from "../../../../store/appSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import {useDecorationControl} from "../../../../hooks/useDecorationControl";

const Wrapper = styled(Box)`
  display: flex;
  overflow-x: auto;
`;

const DecorationSwitcher = () => {
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const garmentIds = useSelector((state) => state.config.garmentIds);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const { currentGarmentId, onGarmentClick } = useDecorationControl();

  return (
    <Wrapper>
      {garmentList
        .filter((item) => garmentIds.includes(item.id))
        .map((k) => <ButtonDecorationModelMobile
          key={k.id}
          isSelected={k.id === currentGarmentId}
          onClick={() => onGarmentClick(k.id)}
          imgSrc={k.icon}
        />)
      }
    </Wrapper>
  );
};

export default DecorationSwitcher;
