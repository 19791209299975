import React from "react";
import { styled } from "@mui/material";
import { COLORS } from "../../../constants";
import shuffle from "../../../assets/button/shuffleMobile.svg";
import useShufflePatterns from "../../../hooks/useShufflePatterns";

const Wrapper = styled("button")`
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 127px;
  height: 56px;
  position: fixed;
  bottom: 13vh;
  right: 0;
  z-index: 5;
  clip-path: polygon(22% 0%, 100% 0%, 100% 100%, 0% 100%);
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.textDarkGrey};

  .text {
    margin-left: 10px;
  }
`;

const ButtonShufflePatterns = () => {
  const { onShufflePatterns } = useShufflePatterns();

  return (
    <Wrapper onClick={onShufflePatterns}>
      <img src={shuffle} alt=''/>
      <div className="text">Pattern</div>
    </Wrapper>
  );
};

export default ButtonShufflePatterns;
