import React from 'react';
import {CircularProgress, styled} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

const Wrapper = styled('div')`
  width: 110px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 100px;
    height: 132px;
  }
`;

const ButtonPatternLoader = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};

export default ButtonPatternLoader;
