import React from "react";
import LayoutContainer from "./LayoutContainer";
import { Box, styled } from "@mui/material";
import ButtonPatternDesktop from "../../UI/ButtonPatternDesktop";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {setPatternId, updatePatternColorScheme} from "../../../../store/configSlice";
import { useSelector } from "react-redux";
import {selectAllPatternsBySport, selectDesignsBySportAndGarmentId} from "../../../../store/appSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import ButtonPatternLoader from "../../UI/ButtonPatternLoader";

const Layout = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 230px;
  margin: 16px 0;
  row-gap: 16px;
`;

const LayoutPattern = () => {
  const screenshots = useAppSelector((state) => state.canvas.screenshots);
  const loading = useAppSelector((state) => state.canvas.loading);
  const dispatch = useAppDispatch();
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patternList = useSelector((state) =>
    selectAllPatternsBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );
  const currentId = useAppSelector(state => state.config.patternId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, selectedSportId, garmentIds));

  const handleClickPattern = (id: string) => {
    dispatch(setPatternId(id));
    const design = filteredDesigns.filter((k) => k.pattern === id && k.garmentType === 'jersey')[0];
    dispatch(updatePatternColorScheme(design));
  };

  return (
    <LayoutContainer title="Pattern">
      <Layout>
        {
          patternList.map((item, index) => {
            const src = screenshots[item.id]?.src;
            if (src && !loading) {
              return <ButtonPatternDesktop
                key={item.id + index}
                selected={item.id === currentId}
                src={src}
                onClick={() => handleClickPattern(item.id)}
              />;
            } else {
              return <ButtonPatternLoader
                key={index}
              />;
            }
          })
        }
      </Layout>
    </LayoutContainer>
  );
};

export default LayoutPattern;
