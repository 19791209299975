/**
 * ['text1', 'text2', 'text3'] => text1, text2, text3
 */

const arrayToString = (arr: string[]): string => {
  let result = '';
  for (let i = 0; i < arr.length; i++) {
    result += arr[i];
    if (i < arr.length - 1) {
      result += ', ';
    }
  }
  return result;
};

export default arrayToString;
