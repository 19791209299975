import React from 'react';
import TextHeading3 from "../Typography/TextHeading3";
import {StyledModal} from "./style";
import ButtonModal from "./ButtonModal";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setModal} from "../../store/appSlice";

const WarningColorsCount = () => {
  const dispatch = useAppDispatch();

  const handleOK = () => {
    dispatch(setModal(null));
  };

  return (
    <StyledModal>
      <div className='innerBox'>
        <TextHeading3>You can&apos;t choose less than 2 colors.</TextHeading3>
        <ButtonModal
          handleClick={handleOK}
        >
          OK
        </ButtonModal>
      </div>
    </StyledModal>
  );
};

export default WarningColorsCount;
