import React, {useEffect, useState} from 'react';
import {Box, styled} from "@mui/material";
import imgLogo from '../../assets/img/logos/logo.svg';

interface ILayoutProps {
  progresswidth: string;
  opacity: number;
  display: string;
}

const Layout = styled(Box)<ILayoutProps>`
  width: 100%;
  height: 100vh;
  background: #F6F7F9;
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  opacity: ${props => props.opacity};
  transition: .5s;
  
  .loaderBox {
    width: 30%;
    pointer-events: none;
    user-select: none;
    
    .logo {
      width: 100%;
      cursor: default;
    }

    .preloader {
      margin-top: 50px;
      width: 100%;
      border: 2px solid #000;
      padding: 2px;
      border-radius: 10px;
      height: 30px;
    }

    .line {
      width: ${props => props.progresswidth};
      height: 100%;
      background: #000;
      border-radius: 10px;
    }

    .progress {
      margin-top: 30px;
      font-weight: 600;
      font-size: 30px;
      line-height: 22px;
      color: #000;
      text-align: center;
    }
  }
`

const Preloader = () => {
  const [progress, setProgress] = useState<number>(0);
  const [display, setDisplay] = useState('flex');

  const preloaderProgress = (e) => {
    setProgress(e.detail.value);
  }

  useEffect(() => {
    document.addEventListener('preloaderProgress', preloaderProgress);

    return () => {
      document.removeEventListener('preloaderProgress', preloaderProgress);
    }
  }, [])

  useEffect(() => {
    // let timeoutIdx;

    if (progress === 100) {
      // timeoutIdx = 
      setTimeout(() => {
        setDisplay('none');
      }, 500);
    }

    return () => {
      // if (timeoutIdx) timeoutIdx?.clearTimeout();
    }
  }, [progress])

  return (
    <Layout
      progresswidth={progress + '%'}
      opacity={progress === 100 ? 0 : 1}
      display={display}
    >
      <div className='loaderBox'>
        <img
          src={imgLogo}
          alt='GoodGame'
          className='logo'
        />
        <div className='preloader'>
          <div className='line'/>
        </div>
        <p className="progress"><span>{progress}</span> %</p>
      </div>
    </Layout>
  );
};

export default Preloader;
