import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/assets/fonts/montserrat/Montserrat-MediumItalic.woff';
import {Provider} from "react-redux";
import store from "./store";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);

