import React from 'react';
import {Box, styled, useMediaQuery} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";
import FormContacts from "./FormContacts";
import TextHeading5 from "../../../components/Typography/TextHeading5";

const Wrapper = styled(Box)`
  width: 30vw;

  @media all and (max-width: ${BREAKPOINTS.special}) {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: row;
  }
`

const FormColumnLayout = () => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  if (isMobile) {
    return null;
  }

  return (
    <Wrapper>
      <TextHeading5
          sx={{
            marginBottom: '16px'
          }}
      >
          Send us your design!
      </TextHeading5>
      <FormContacts />
    </Wrapper>
  );
};

export default FormColumnLayout;
