import React from 'react';
import {Box, styled} from "@mui/material";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ButtonsContainer = ({ children, style }: IProps) => {
  return (
    <Wrapper
      sx={style}
    >
      { children }
    </Wrapper>
  );
};

export default ButtonsContainer;