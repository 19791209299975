import React, {useRef, useState} from 'react';
import exploded from '../../../assets/img/UI/dropdown-exploded.svg';
import collapsed from '../../../assets/img/UI/dropdown-collapsed.svg';
import {DropdownListButton, DropdownListContent, DropdownListWrapper} from "./style";
import ListRow from "./ListRow";
import {useMediaQuery} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

export interface IDropdownListRow {
  value1: string;
  value2: string;
}

interface IProps {
  rows: IDropdownListRow[];
  isColor?: boolean;
  maxItems: number;
}

const DropdownList = ({ rows, isColor, maxItems }: IProps) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  const [open, setOpen] = useState<boolean>(false);

  const listRef = useRef(null);

  const handleClick = () => {
    setOpen((prev) => {
      const newValue = !prev;
      if (newValue === false) {
        (listRef.current as HTMLDivElement).scrollTop = 0;
      }
      return newValue;
    });
  }

  if (!rows?.length) {
    return null;
  }

  return (
    <DropdownListWrapper
      sx={{
        marginBottom: isColor && isMobile ? '90px' : '0',
      }}
    >
      {
        rows.length > maxItems && <DropdownListButton
          onClick={handleClick}
        >
          <img src={open ? exploded : collapsed} alt='>'/>
        </DropdownListButton>
      }
      <DropdownListContent
        sx={{
          minHeight: open ? '70px' : '27px',
        }}
        ref={listRef}
      >
        {
          open ? rows.map((row, index) => <ListRow
              key={index}
              rows={[row]}
              isColor={isColor}
            />) :
            <ListRow
              rows={rows.length > maxItems ? [rows[0]] : rows}
              isColor={isColor}
            />
        }
      </DropdownListContent>
    </DropdownListWrapper>
  );
};

export default DropdownList;
