import React from 'react';
import {Box, styled} from "@mui/material";
import {COLORS} from "../../../constants";
import adornmentSvg from '../../../assets/img/UI/down-arrow.svg';

interface IWrapperProps {
  open: boolean;
}

const Wrapper = styled(Box)<IWrapperProps>`
  position: absolute;
  top: 7px;
  left: 16px;
  border: 1px solid ${COLORS.border};
  background: white;
  z-index: 20;
  padding: 5px 16px;
  transition: .3s;
  width: 65%;
  max-height: 90%;
  overflow-y: auto;
  
  .adornment {
    position: absolute;
    top: 13px;
    right: 13px;
    user-select: none;
    transform: ${props => props.open ? 'none' : 'rotateZ(180deg)'};
  }
`

interface IProps {
  open: boolean;
  ListComponent: JSX.Element;
}

const Dropdown = ({ open, ListComponent }: IProps) => {
  return (
    <Wrapper
      open={open}
    >
      {
        ListComponent
      }
      <img
        src={adornmentSvg}
        alt=''
        className='adornment'
      />
    </Wrapper>
  );
};

export default Dropdown;
