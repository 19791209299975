import * as Three from 'three';
import * as Pixi from 'pixi.js';
import _ from 'lodash';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import CanvasManager from '../app3d/Scene/CanvasManger';
import SceneWrapper from './Scene/SceneWrapper';
import { ResourceType } from './ResourceManager/ResourceTypes';
import { ResourceManager } from './ResourceManager/ResourceManager';
import updateObject from '../utils/updateObject';
import type {
  GarmentCodeInfo,
  GarmentConfig,
  GarmentList,
  GarmentObjectInfo,
  RgbColorRepresentation,
  StandardMaterialMesh,
} from '../types/app3dTypes';
import type {
  Config,
  GarmentConfigDefault,
  IGarment,
} from '../store/types/designTypes';
import type { SceneState } from '../store/types';
import type { CanvasType, GarmentParameters } from '../types/canvasTypes';
import { createNeighbourFacesSet } from '../utils/faceMath/createNeighbourFacesSet';

export class App {
  private readonly resourceManager: ResourceManager;
  private readonly container: Three.Group;
  private readonly prevParams = {};
  private readonly garmentDataList = new Map<string, GarmentObjectInfo>();
  private readonly allGarmentConfigs: Record<
    string,
    Map<string, GarmentConfig>
  > = {};
  private currentGarmentConfigs = new Map<string, GarmentConfig>();
  private readonly canvasManagersMap = new Map<string, CanvasManager>();
  private sceneConfig: SceneState;
  private isInit = false;
  private sceneWrapper: SceneWrapper;
  private povPosition: Three.Object3D;
  private previewPosition: Three.Object3D;
  private resultControlsPosition: Three.Object3D;
  private designs: Array<IGarment>;
  private currentGarmentConfig: GarmentConfigDefault;
  private isMobile: boolean;
  private isMousePressed: boolean;
  constructor() {
    this.resourceManager = new ResourceManager();
    this.container = new Three.Group();
    this.sceneWrapper = new SceneWrapper();
    this.sceneWrapper.appendChild(this.container);
  }

  public async init(
    sceneConfig: SceneState,
    garmentListBySport: Record<string, GarmentList>,
  ): Promise<void> {
    if (this.isInit) return Promise.resolve();
    this.sceneConfig = sceneConfig;
    this.resourceManager.appendResource(
      sceneConfig.lightFilePath,
      ResourceType.HDR_TEXTURE,
    );
    this.resourceManager.appendResource(
      sceneConfig.sceneFilePath,
      ResourceType.GLTF,
    );
    await this.resourceManager.loadResources();

    const gltf = this.resourceManager.getGltfByUrl(
      this.sceneConfig.sceneFilePath,
    );

    this.container.add(gltf.scene);
    const garmentCodeInfoBySport: Record<string, GarmentCodeInfo[]> = {};
    Object.entries(garmentListBySport).forEach(([sport, garmentList]) => {
      garmentCodeInfoBySport[sport] = garmentList.map((g) => ({
        source: g.source,
        code: g.code,
        sceneName: g.sceneObjectName || g.code,
        resultPositionCode: g.resultPositionCode,
        mainPositionCode: g.mainPositionCode,
        insideObjectName: g.insideObjectName,
      }));
    });
    this.configureAllGarmentTypes(gltf, garmentCodeInfoBySport);

    this.sceneWrapper.setEnvironment(
      this.resourceManager.getHdrTextureUrl(this.sceneConfig.lightFilePath),
    );
    this.isInit = true;
    return Promise.resolve();
  }

  private onMouseMove(event: MouseEvent): void {
    if (this.isMousePressed) {
      // Get the normalized device coordinates
      const mouse = new Three.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
      );

      // Create a raycaster from the mouse coordinates
      const raycaster = new Three.Raycaster();
      raycaster.setFromCamera(mouse, this.sceneWrapper.camera); // Replace `camera` with your actual camera object

      // Perform raycasting to find intersected faces
      const intersects = raycaster.intersectObjects(this.sceneWrapper.children);

      if (intersects.length > 0) {
        if (!(intersects[0].object instanceof Three.Mesh)) {
          return;
        }
        // Get the index of the first intersected face
        const mesh = intersects[0].object as Three.Mesh;
        const faceIndex = intersects[0].faceIndex;
        const face = intersects[0].face;

        const { a, b, c } = face;

        // Output the face index to the console
        console.log('Face index:', faceIndex);
        if (mesh.geometry.attributes.color === undefined) {
          return;
        }
        // Закрашиваем фейс черным цветом
        mesh.geometry.attributes.color.setXYZ(a, 255, 0, 0);
        mesh.geometry.attributes.color.setXYZ(b, 255, 0, 0);
        mesh.geometry.attributes.color.setXYZ(c, 255, 0, 0);
        mesh.geometry.attributes.color.needsUpdate = true;
      }
    }
  }

  public setCurrentSport(sport: string | undefined): void {
    if (sport) this.currentGarmentConfigs = this.allGarmentConfigs[sport];
  }

  public addGarment(garmentCode: string): void {
    const garmentConfig = this.currentGarmentConfigs.get(garmentCode);
    this.resourceManager.appendResource(
      garmentConfig.source,
      ResourceType.GLTF,
    );
  }

  public update(): void {
    Array.from(this.garmentDataList.values()).forEach((garmentInfo) => {
      if (garmentInfo?.canvasManager?.needsRedraw) {
        garmentInfo?.canvasManager?.redraw();
        garmentInfo.object.material.needsUpdate = true;
        if (garmentInfo.object.material.map) {
          garmentInfo.object.material.map.needsUpdate = true;
        }
      }
    });
    this.sceneWrapper.render();
  }

  private convertHexToRgb(hex: string): RgbColorRepresentation | undefined {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined;
  }

  public setSecondColor(parameters: GarmentParameters): void {
    const color = this.convertHexToRgb(parameters.colors.Main.hex);
    Array.from(this.garmentDataList.values()).forEach((garmentData) => {
      garmentData.insideObject.material.color = new Three.Color(
        color.r / 1000,
        color.g / 1000,
        color.b / 1000,
      );
    });
  }

  public configureAllGarmentTypes(
    gltf: GLTF,
    allGarmentTypesCodesBySport: Record<string, GarmentCodeInfo[]>,
  ): void {
    for (const [sport, allGarmentTypesCodes] of Object.entries(
      allGarmentTypesCodesBySport,
    )) {
      this.allGarmentConfigs[sport] = new Map<string, GarmentConfig>();
      allGarmentTypesCodes.forEach((g) => {
        this.allGarmentConfigs[sport].set(g.code, {
          sceneName: g.sceneName,
          source: g.source,
          insideObjectName: g.insideObjectName,
          mainPositionCode: g.mainPositionCode,
          resultPositionCode: g.resultPositionCode,
        });
      });
    }
    this.povPosition = gltf.scene.getObjectByName('pov');
    this.previewPosition = gltf.scene.getObjectByName('preview');
    this.resultControlsPosition = gltf.scene.getObjectByName('result_target');
  }

  public async prepareSelectedGarment(): Promise<void> {
    await this.resourceManager.loadResources();
    if (!this.currentGarmentConfigs)
      return Promise.reject(`Unable to load resources.`);
    this.currentGarmentConfigs.forEach((config, garmentCode) => {
      const url = config.source;
      const gltf = this.resourceManager.getGltfByUrl(url);
      if (!gltf) return;
      this.container.add(gltf.scene);
      const garment = gltf.scene.getObjectByName(
        config.sceneName,
      ) as StandardMaterialMesh;
      if (!garment) {
        return;
      }
      garment.material.color = new Three.Color('#ffffff');
      // garment.material.vertexColors = true;
      const neighbours = createNeighbourFacesSet(garment, 6);

      garment.visible = false;
      this.garmentDataList.set(garmentCode, {
        url: config.source,
        object: garment,
        edgeFaces: neighbours,
        lastReducedFontSize: undefined,
        previewPosition: gltf.scene.getObjectByName(
          config.resultPositionCode || `${garmentCode}_result_position`,
        ),
        standardPosition: gltf.scene.getObjectByName(
          config.mainPositionCode || `${garmentCode}_main_position`,
        ),
        insideObject: config.insideObjectName
          ? (gltf.scene.getObjectByName(
              config.insideObjectName,
            ) as StandardMaterialMesh)
          : undefined,
      });
    });
    return Promise.resolve();
  }

  public setPositionGament(
    garment: GarmentObjectInfo,
    isPreview = false,
  ): void {
    garment.object.position.copy(garment.standardPosition.position);
    this.sceneWrapper.setCameraState({
      position: isPreview
        ? this.previewPosition.position
        : this.povPosition.position,
    });
    this.setupPositionCameraForAABB(garment);
    this.sceneWrapper.setControlsState({
      position: garment.object.position,
      minDistance: 12,
      maxDistance: 35,
    });
  }

  /**
   * Calculates the optimal position for the camera to ensure the given object's
   * Axis-Aligned Bounding Box (AABB) fits perfectly within the camera's field of view.
   * The camera is positioned along the line between points A and В, with the target
   * point A set to the center of the object's AABB, and B is the current camera's position
   * This ensures a visually pleasing composition where the object is framed perfectly
   * within the camera's view.
   *
   * @param {THREE.Camera} garment - The object in the scene.
   */
  setupPositionCameraForAABB(garment: GarmentObjectInfo) {
    const objectAABB = new Three.Box3().setFromObject(garment.object);
    const diagonalLength = objectAABB.getSize(new Three.Vector3()).length();
    const cameraFov = this.sceneWrapper.camera.fov;
    // 1.1 is needed to add some small empty space between object and edge of canvas, just to add some beauty
    const distanceToObject =
      (diagonalLength /
        (2 * Math.tan(Three.MathUtils.degToRad(cameraFov) / 2))) *
      1.1;
    const A = garment.object.position;
    const B = this.sceneWrapper.camera.position;
    const direction = new Three.Vector3().subVectors(B, A).normalize();
    const cameraPosition = new Three.Vector3()
      .copy(A)
      .addScaledVector(direction, distanceToObject);
    this.sceneWrapper.setCameraState({
      position: cameraPosition,
    });
  }

  setupDesigns(designs: Array<IGarment>): void {
    this.designs = _.cloneDeep(designs);
  }

  private async initDesign(
    code: string,
    design: Config,
    type: CanvasType,
  ): Promise<void> {
    this.isMobile = type === 'mobile' || type === 'preview';
    const designConfig = _.cloneDeep(design.default) as GarmentConfigDefault;
    if (type === 'mobile' || type === 'preview')
      updateObject(designConfig, design.mobile);
    this.currentGarmentConfig = designConfig;

    this.collectAssetsFromGarmentDesign(designConfig);
    await this.resourceManager.loadAssets();
    this.setDesignToGarment(
      code,
      designConfig,
      type,
      this.resourceManager.getAssets(),
    );
    return Promise.resolve();
  }

  public async setupParams(
    code: string,
    parameters: GarmentParameters,
  ): Promise<void> {
    const design = this.designs.find(
      (design) =>
        design.pattern === parameters.selectedPatternId &&
        design.garmentType === code,
    );
    await this.initDesign(code, design.config, parameters.canvasType);
    if (_.isEqual(parameters, this.prevParams[code])) return;
    await this.loadLogos(parameters.logos);
    await this.updateTextureData(code, parameters);
    this.prevParams[code] = _.cloneDeep(parameters);
    this.update();
    return Promise.resolve();
  }

  private loadLogos(urls: string[]) {
    urls.forEach((url, idx) => {
      this.resourceManager.appendAssetFromData(`logo_${idx}`, url);
    });
  }

  public showSingleGarment(garmentCode: string, isPreview = false): void {
    const garmentData = this.garmentDataList.get(garmentCode);
    if (!garmentData) return;
    this.setPositionGament(garmentData, isPreview);
    this.garmentDataList.forEach(
      (garmentInfo, code) => (garmentInfo.object.visible = code == garmentCode),
    );
    this.sceneWrapper.render();
  }
  public async loadResources() {
    return this.resourceManager.loadResources();
  }
  public setShotMode() {
    this.sceneWrapper.setShotMode();
  }
  public setMainMode() {
    this.sceneWrapper.setMainMode();
  }
  public makeScreenshot(label: string): void {
    this.sceneWrapper.render();
    const shot = this.sceneWrapper.getShot();
    this.resourceManager.appendResource(shot, ResourceType.IMAGE, label);
  }
  public async getScreenshots(labels: Array<string>) {
    await this.resourceManager.loadResources();
    return labels
      .map((label) => {
        return {
          src: this.resourceManager.getImageByLabel(label),
          label,
          name: label,
        };
      })
      .filter((e) => e.src !== undefined);
  }
  public showAllGarments(): void {
    this.garmentDataList.forEach((garmentInfo) => {
      garmentInfo.object.visible = true;
      garmentInfo.object.position.copy(garmentInfo.previewPosition.position);
    });

    const cameraPosition = new Three.Vector3().copy(this.povPosition.position);
    const targetPosition = new Three.Vector3().copy(
      this.resultControlsPosition.position,
    );

    if (this.isMobile) {
      const shift = new Three.Vector3(0, 3, 0);
      cameraPosition.sub(shift);
      targetPosition.sub(shift);
    }

    this.sceneWrapper.setCameraState({
      position: cameraPosition,
      isFar: this.isMobile,
    });
    this.sceneWrapper.setControlsState({
      position: targetPosition,
      maxDistance: 200,
    });
    this.sceneWrapper.render();
  }

  private async updateTextureData(
    garmentCode: string,
    parameters: GarmentParameters,
  ): Promise<void> {
    const garment = this.garmentDataList.get(garmentCode);
    await this.resourceManager.loadAssets();
    garment.canvasManager.updateAssets(this.resourceManager.getAssets());

    garment.canvasManager.setParameters(parameters);
    this.setSecondColor(parameters);
    garment.object.material.map = new Three.CanvasTexture(
      garment.canvasManager.getView(),
    );
    garment.object.material.map.flipY = false;
    garment.object.material.map.encoding = Three.sRGBEncoding;
    garment.object.material.map.anisotropy = 8;
    this.update();
    return Promise.resolve();
  }

  private initCanvas(
    config: GarmentConfigDefault,
    assets: Record<string, Pixi.Sprite>,
    type: CanvasType,
  ): CanvasManager {
    const manager = new CanvasManager();
    manager.initialize(1024, 1024, type);
    manager.setConfig(config, assets);
    manager.onRedraw(() => this.update());
    return manager;
  }

  public collectAllAssets(designs: Record<string, GarmentConfigDefault>): void {
    for (const design of Object.values(designs)) {
      this.collectAssetsFromGarmentDesign(design);
    }
  }

  private collectAssetsFromGarmentDesign(design: GarmentConfigDefault): void {
    design.snitchesMaskPath
      ? this.resourceManager.appendAsset(design.snitchesMaskPath)
      : null;
    design.aoMapPath
      ? this.resourceManager.appendAsset(design.aoMapPath)
      : null;
    design.layers.forEach((layer) =>
      this.resourceManager.appendAsset(layer.maskPath),
    );
  }

  private setDesignToGarment(
    garmentCode: string,
    config: GarmentConfigDefault,
    type: CanvasType,
    assets: Record<string, Pixi.Sprite>,
  ): void {
    const garment = this.garmentDataList.get(garmentCode);
    const oldManager = this.canvasManagersMap.get(`${garmentCode}_${type}`);
    garment.canvasManager = oldManager;
    if (!oldManager) {
      const manager = this.initCanvas(config, assets, type);
      this.canvasManagersMap.set(`${garmentCode}_${type}`, manager);
      garment.canvasManager = manager;
    }
    garment.canvasManager.setConfig(config, assets);
  }

  public setContainer(containter: HTMLElement | null, render = true): void {
    if (!containter) return;
    this.sceneWrapper.setContainer(containter);
    if (render) this.sceneWrapper.runRenderCycle();
  }

  setContainerTo(containterIdx: number): void {
    this.sceneWrapper.setContainerTo(containterIdx) &&
      this.sceneWrapper.runRenderCycle();
  }
  destroy() {
    this.sceneWrapper.destroy();
    // this.sceneWrapper.stopRenserCycle();
    // this.container.parent = null;
  }
}
