import React from 'react';
import {Box, styled} from '@mui/material';
import Logo from "./Logo";
import Burger from "./Burger";
import {BREAKPOINTS} from "../../constants";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setIsMenuOpen} from "../../store/appSlice";
import PreviewButtonEye from "../PreviewButton/PreviewButtonEye";
import GarmentSelectContent from "../GarmentSelect/GarmentSelectContent";

const Wrapper = styled('header')`
  position: fixed;
  z-index: 10;
  width: 100%;
  pointer-events: none;
  
  .headerWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px auto;
    width: 90%;
    height: 50px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .headerWrap {
      margin: 20px auto;
      height: 32px;
    }
  }
`

const BurgerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: all;
`;

const Header = () => {
  const dispatch = useAppDispatch();

  const handleMenuClick = () => {
    dispatch(setIsMenuOpen(true));
  }

  return (
    <Wrapper>
      <div className='headerWrap'>
        <Logo />
        <BurgerBox>
          <Burger
            handleClick={handleMenuClick}
          />
          <PreviewButtonEye />
          <GarmentSelectContent />
        </BurgerBox>
      </div>
    </Wrapper>
  );
};

export default Header;
