import {IconButton, styled} from "@mui/material";

export const MobileHeading = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
`;

export const MobileLeftColumn = styled('div')`
  width: 75%;
`;

interface RightColumnProps {
  menupage: string;
}

export const MobileRightColumn = styled('div')<RightColumnProps>`
  width: 25%;
  min-width: 92px;
  display: flex;
  justify-content: ${props => props.menupage === 'sport' ? "flex-end" : "space-between"};
`;

export const MobileControlButton = styled('button')`
  width: 46px;
  height: 46px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileScroll = styled('div')`
  display: flex;
  height: calc(100% - 62px);
  overflow-y: auto;
`;

export const MobileInputRow = styled('div')`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const MobileInputButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  background: #F6F7F9;
  border-radius: 0;
`;

export const MobileLogosWrap = styled('div')`
  width: 100%;
  display: flex;
  gap: 8px;
`;
