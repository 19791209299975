import * as PIXI from "pixi.js";
import type { GradientConfig, ColorParams } from "../../src/types/canvasTypes";

export default function addColorizeToPixiTextStyle(
  options: Partial<PIXI.ITextStyle>,
  colors: ColorParams,
  gradient?: GradientConfig
) {
  if (gradient) {
    colorizeTextWithGradient(options, colors, gradient);
  } else {
    colorozeTextWithSolidColor(options, colors);
  }
}

function colorizeTextWithGradient(options: Partial<PIXI.ITextStyle>, colors: ColorParams, gradient: GradientConfig) {
  const { borderColor, centerColor, pattern, type } = gradient;
  options.fill = [colors[borderColor], colors[centerColor], colors[centerColor], colors[borderColor]];
  options.fillGradientStops = pattern;
  options.fillGradientType = type === "vertical" ? 0 : 1;
  options.stroke = colors.outlineColor;
}

function colorozeTextWithSolidColor(options: Partial<PIXI.ITextStyle>, colors: ColorParams) {
  options.fill = colors.fillColor;
  options.stroke = colors.outlineColor;
}
