/**
 * Shuffles the contents of the array
 * @param array
 */

export default function shuffleArray<T>(array: T[]): T[] {
  if (array.length < 2) {
    return array;
  }

  const newArr = [...array];
  let currentIndex = newArr.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArr[currentIndex], newArr[randomIndex]] = [newArr[randomIndex], newArr[currentIndex]];
  }

  // Check if the same combination
  const isEqual = JSON.stringify(array) === JSON.stringify(newArr);

  if (isEqual) {
    return shuffleArray(array);
  }

  return newArr;
}
