import React from 'react';
import {styled} from "@mui/material";
import {COLORS} from "../../constants";
import eyeOpenSvg from '../../assets/img/UI/eye-mob.svg';
import eyeCloseSvg from '../../assets/img/UI/eye2-mob.svg';
import usePreviewButton from "../../hooks/usePreviewButton";
import useAppSelector from "../../hooks/useAppSelector";

const Wrapper = styled('button')`
  width: 56px;
  height: 56px;
  background: #fff;
  border: 1px solid ${COLORS.border};
`

const PreviewButtonEye = () => {
  const { pressed, onPress } = usePreviewButton();
  const pageId = useAppSelector(state => state.app.pageId);

  if (pageId !== 5) {
    return null;
  }

  return (
    <Wrapper
      onClick={onPress}
    >
      <img src={pressed ? eyeCloseSvg : eyeOpenSvg} alt='preview'/>
    </Wrapper>
  );
};

export default PreviewButtonEye;
