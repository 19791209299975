import React from 'react';
import {Box, styled} from "@mui/material";
import LayoutSport from "./layouts/LayoutSport";
import LayoutColors from "./layouts/LayoutColors";
import LayoutDecoration from "./layouts/LayoutDecoration";
import useAppSelector from "../../../hooks/useAppSelector";

const Wrapper = styled(Box)`
  width: 100%;
`;

const SideBarMobile = () => {
  const detailedSideBar = useAppSelector((state) => state.app.detailedSideBar);

  const renderDetailedMenu = () => {
    switch (detailedSideBar) {
      case 'sport': return <LayoutSport />;
      case 'color': return <LayoutColors />;
      case 'decoration': return <LayoutDecoration />;
      default: return null;
    }
  }
  return (
    <Wrapper>
      {
        renderDetailedMenu()
      }
    </Wrapper>
  );
};

export default SideBarMobile;
