import useAppDispatch from "./useAppDispatch";
import {
  setBaseColorIds, setPatternColorId,
} from '../store/configSlice';
import useAppSelector from "./useAppSelector";
import {
  selectAllColors,
  selectDesignsBySportAndGarmentId,
} from '../store/appSlice';
import { useCallback, useMemo } from 'react';
import { assignColorsFromSelectedToBase } from '../utils/assignColors';
import getMaxNumberOfLayers from '../utils/getMaxNumberOfLayers';
import { IBaseColor } from '../store/types';
import shuffleArray from '../utils/shuffleArray';
import useSelector from './useAppSelector';

interface IReturnShuffleColors {
  onShuffle: () => void;
}

const useShuffleColors = (): IReturnShuffleColors => {
  const dispatch = useAppDispatch();

  const patternColorScheme = useAppSelector(state => state.config.patternColorScheme);
  const baseColorIds: Record<string, IBaseColor> = useAppSelector(state => state.config.baseColorIds);
  const sportId = useAppSelector((state) => state.config.sportId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, sportId, garmentIds));
  const patternId = useAppSelector((state) => state.config.patternId);
  const layers = useMemo(() => {
    return getMaxNumberOfLayers(filteredDesigns.filter((k) => k.pattern === patternId));
  }, [patternId, filteredDesigns]);
  const baseColors = useSelector(selectAllColors);

  const onShuffle = useCallback(() => {
    const patterns = [...patternColorScheme];

    // цветов не должно быть больше, чем слоев в паттерне

    if (layers < patterns.length) {
      patterns.length = layers;
    }

    // собираем все цвета со всех слоев в один массив

    const scheme = [
      ...patterns,
      {
        code: 'base_color_ids_main',
        colorId: baseColorIds.Main.id,
      },
      {
        code: 'base_color_ids_text',
        colorId: baseColorIds.Text.id,
      },
      {
        code: 'base_color_ids_outline',
        colorId: baseColorIds['Text outline'].id,
      },
    ];

    // перемешиваем и выбираем новые цвета

    const shuffledAll: IBaseColor[] = shuffleArray(baseColors);
    const shuffledUniqueTypeMap = new Map(
      shuffledAll.map((color) => [color.type, color.id]),
    );
    const shuffled = Array.from(shuffledUniqueTypeMap.values());

    const newScheme = scheme.map((item, index) => ({
      ...item,
      colorId: shuffled[index],
    }));

    // назначаем новые цвета в редакс

    const colorIds: string[] = [];
    newScheme.forEach((item) => {
      switch (item.code) {
        case 'base_color_ids_main': {
          colorIds[0] = item.colorId;
          break;
        }
        case 'base_color_ids_text': {
          colorIds[2] = item.colorId;
          break;
        }
        case 'base_color_ids_outline': {
          colorIds[1] = item.colorId;
          break;
        }
        default: {
          dispatch(setPatternColorId({
            colorId: item.colorId,
            layerCode: item.code,
          }));
        }
      }
    });
    const colors = assignColorsFromSelectedToBase(colorIds, baseColors);
    dispatch(setBaseColorIds(colors));
  }, [patternColorScheme, baseColorIds, layers, baseColors]);

  return {
    onShuffle,
  };
}

export default useShuffleColors;
