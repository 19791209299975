import * as PIXI from 'pixi.js';
import type { CanvasType } from '../../src/types/canvasTypes';
import type {
  PlayerNumberConfig,
  TextConfig,
} from '../store/types/designTypes';

const typeQfMap = {
  mobile: 2,
  desktop: 2,
  preview: 0.5,
};
const typeStrokeThicknessMap = {
  playerNumber: {
    mobile: 2.5,
    desktop: 4.25,
    preview: 4.25,
  },
  text: {
    mobile: 1.32,
    desktop: 2.55,
    preview: 2.55,
  },
};
export default function setupPixiTextStyle(
  textOptions: Partial<PIXI.ITextStyle>,
  canvasType: CanvasType,
  textConfig: TextConfig | PlayerNumberConfig,
  textType: 'playerNumber' | 'text',
) {
  const { fontSize, fontFamily, reducedFontSize } = textConfig;
  const qf = typeQfMap[canvasType];
  const strokeThickness = typeStrokeThicknessMap[textType][canvasType];
  textOptions.align = 'center';
  if (reducedFontSize) {
    textOptions.fontSize = reducedFontSize * qf;
  } else {
    textOptions.fontSize = fontSize * qf;
  }
  textOptions.fontFamily = fontFamily;
  textOptions.strokeThickness = strokeThickness;
  textOptions.letterSpacing = 4 * qf;
  textOptions.padding = fontSize;
}
