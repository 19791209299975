import { GarmentParameters } from '../../src/types/canvasTypes';

export default function makeGarmentParams(
  garment,
  selectedColors,
  selectedNumber,
  selectedPatternId,
  logos,
  texts,
  canvasType,
  patternLayersColors,
) {
  const colors = { ...selectedColors, 'Side panel': patternLayersColors };
  const params: GarmentParameters = {
    number: selectedNumber.trim(),
    texts: [],
    colors: colors,
    selectedPatternId: selectedPatternId,
    logos: [],
    canvasType: canvasType,
  };
  (garment.config.default.texts || []).forEach((textConfig) => {
    params.texts.push(texts[textConfig.code] || '');
  });

  (garment.config.default.logos || []).forEach((logoConfig) => {
    params.logos.push(logos[logoConfig.code] ?? undefined);
  });
  return params;
}
export function makeGarmentParamsForPreview(
  garment,
  selectedNumber,
  selectedPatternId,
  logos,
  texts,
  canvasType,
) {
  const params: GarmentParameters = {
    number: selectedNumber.trim(),
    texts: [],
    colors: {
      Accent1: { hex: '#ffffff' },
      'Number outline': { hex: '#ffffff' },
      'Side panel': [{ hex: '#000000' }],
      'Text outline': { hex: '#ffffff' },
      Main: { hex: '#ffffff' },
      Number: { hex: '#ffffff' },
      Text: { hex: '#ffffff' },
    },
    selectedPatternId: selectedPatternId,
    logos: [],
    canvasType: canvasType,
  };
  (garment.config.default.texts || []).forEach((textConfig) => {
    params.texts.push(texts[textConfig.code] || '');
  });

  (garment.config.default.logos || []).forEach((logoConfig) => {
    params.logos.push(logos[logoConfig.code] || undefined);
  });
  return params;
}
