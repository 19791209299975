import React, {useState} from 'react';
import {Box, styled, useMediaQuery} from "@mui/material";
import useAppSelector from "../../../hooks/useAppSelector";
import {BREAKPOINTS} from "../../../constants";
import closeSvg from '../../../assets/img/UI/close-mob.svg';
import yesSvg from '../../../assets/img/UI/yes.svg';
import useAppDispatch from "../../../hooks/useAppDispatch";
import Dropdown from "../UI/Dropdown";
import ColorSelector from "../Desktop/components/ColorSelector";
import {restoreValues} from "../../../store/configSlice";
import ColorSwitcher from "../Desktop/components/ColorSwitcher";
import DecorationSwitcher from "./components/DecorationSwitcher";
import DecorationSelector from "./components/DecorationSelector";
import PatternSelector from "./components/PatternSelector";
import LayoutSport from "../Desktop/layouts/LayoutSport";
import {MobileControlButton, MobileHeading, MobileLeftColumn, MobileRightColumn, MobileScroll} from "./styles";
import useSelector from "../../../hooks/useAppSelector";
import {setDetailedSideBar} from "../../../store/appSlice";

const Wrapper = styled(Box)`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  padding: 0 16px 16px;
  z-index: 15;
`

const MobileMenu = () => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(true);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const dispatch = useAppDispatch();

  const detailedSideBar = useAppSelector(state => state.app.detailedSideBar);
  const selectedGarmentIds = useSelector((state) => state.canvas.selectedGarmentIds);
  const isShorts = selectedGarmentIds.length === 1 && selectedGarmentIds[0] === '4';
  const pageId = useAppSelector(state => state.app.pageId);

  if (!isMobile || !detailedSideBar || pageId < 2 || ((pageId === 3 || pageId === 2) && detailedSideBar === 'sport')) {
    return null;
  }

  const handleDropdown = () => {
    setOpenDropdown(prev => !prev);
  }

  const handleCancel = () => {
    dispatch(restoreValues());
    dispatch(setDetailedSideBar(null));
  }

  const handleConfirm = () => {
    dispatch(setDetailedSideBar(null));
  }

  const renderLeftColumnComponent = () => {
    switch (detailedSideBar) {
      case 'decoration': return <DecorationSwitcher />;
      default: return null;
    }
  }

  const renderDropdownList = () => {
    switch (detailedSideBar) {
      case 'color': return <ColorSwitcher
        dropdown
        open={openDropdown}
        setOpen={handleDropdown}
      />;
      default: return null;
    }
  }

  const renderContent = () => {
    switch (detailedSideBar) {
      case 'sport': return <LayoutSport isFourthMobilePage />;
      case 'color': return <ColorSelector
        isBottomMenu
      />;
      case 'decoration': return <DecorationSelector />;
      case 'pattern': return <PatternSelector />
      default: return null;
    }
  }

  return (
    <Wrapper
      sx={{
        height: isShorts && detailedSideBar === 'decoration' ? '140px' : '250px',
      }}
    >
      {
        detailedSideBar === 'color' &&
          <Dropdown
              open={openDropdown}
              ListComponent={renderDropdownList()}
          />
      }
      <MobileHeading>
        <MobileLeftColumn>
          {
            renderLeftColumnComponent()
          }
        </MobileLeftColumn>
        <MobileRightColumn
          menupage={detailedSideBar}
        >
          {detailedSideBar !== 'sport' && (
            <MobileControlButton onClick={handleCancel}>
              <img
                src={closeSvg}
                alt="X"
              />
            </MobileControlButton>
          )}
          <MobileControlButton
            onClick={handleConfirm}
          >
            <img src={yesSvg} alt='confirm'/>
          </MobileControlButton>
        </MobileRightColumn>
      </MobileHeading>

      <MobileScroll>
        {renderContent()}
      </MobileScroll>
    </Wrapper>
  );
};

export default MobileMenu;
