import React from 'react';
import {Box, styled, useMediaQuery} from "@mui/material";
import logoImg from '../../assets/img/logos/logo-menu.jpg';
import logoWhiteImg from '../../assets/img/logos/logo-white.svg';
import closeImg from '../../assets/img/UI/close.svg';
import {BREAKPOINTS} from "../../constants";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 26px;
  
  > img {
    width: 200px;
  }
  
  > button {
    background: none;
    
    img {
      width: 26px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    > img {
      width: 70px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    > button {
      img {
        width: 15px;
      }
    }
  }
`

interface IProps {
  handleClose: () => void;
}

const MenuHeader = ({ handleClose }: IProps) => {
  const isSpecial = useMediaQuery(`(max-width: ${BREAKPOINTS.special})`);

  return (
    <Wrapper>
      <img src={isSpecial ? logoWhiteImg : logoImg} alt='GoodGame'/>
      <button
        onClick={handleClose}
      >
        <img src={closeImg} alt='X'/>
      </button>
    </Wrapper>
  );
};

export default MenuHeader;
