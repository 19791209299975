import React from "react";
import CenteredPageContainer from "../../components/Containers/CenteredPageContainer";
import { Box, InputLabel, styled, useMediaQuery } from "@mui/material";
import TextInputLabel from "../../components/Typography/TextInputLabel";
import InputText from "./InputText";
import {BREAKPOINTS, DEFAULT_LOGO_CODE, DEFAULT_TEXT_CODE} from "../../constants";
import AddLogoButton from "./AddLogoButton";
import removeSvg from "../../assets/img/UI/remove.svg";
import useDecorationForm from "../../hooks/useDecorationForm";
import SideBarMobile from "../../components/SideBar/Desktop/SideBarMobile";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {setLogosAreSet} from "../../store/configSlice";

const Layout = styled(Box)`
  .inputWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
  }

  .imgPreviewWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .removeButton {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      background: none;
      display: flex;
      align-items: center;
      gap: 11px;
    }

    .preview {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 90%;
  }
`;

const PageDecoration = () => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const isLaptop = useMediaQuery(`(max-width: ${BREAKPOINTS.special})`);
  const detailedSideBar = useAppSelector((state) => state.app.detailedSideBar);
  const texts = useAppSelector((state) => state.config.texts);
  const number = useAppSelector((state) => state.config.number);
  const logos = useAppSelector((state) => state.config.logos);

  const dispatch = useAppDispatch();

  const {
    actions: { onChangeNumber, onRemovePreview, onTextChange, onLogoChange },
  } = useDecorationForm();

  const onLogoChangeHandler = (files: FileList, code: string) => {
    dispatch(setLogosAreSet(false));
    onLogoChange(code, files);
  };

  const onTextChangeHandler = (e) => {
    onTextChange(DEFAULT_TEXT_CODE, e.target.value);
  };

  const renderLayout = () => <Layout>
    <div className="inputWrap">
      <InputLabel htmlFor="teamName">
        <TextInputLabel>Enter Team Name</TextInputLabel>
      </InputLabel>
      <InputText
        id="teamName"
        placeholder="Team name..."
        inputProps={{
          maxLength: 30,
        }}
        sx={{
          width: isMobile ? "100%" : "350px",
        }}
        value={texts[DEFAULT_TEXT_CODE] || ""}
        onChange={onTextChangeHandler}
      />
    </div>

    <div className="inputWrap">
      <InputLabel htmlFor="number">
        <TextInputLabel>Number</TextInputLabel>
      </InputLabel>
      <InputText
        id="number"
        placeholder="XX"
        type="number"
        sx={{
          width: isMobile ? "100%" : "116px",
          ".MuiInputBase-root": {
            ".MuiInputBase-input": {
              fontSize: "30px",
            },
          },
        }}
        value={number}
        onChange={onChangeNumber}
      />
    </div>

    <div className="inputWrap" style={{ marginBottom: "10px" }}>
      <TextInputLabel>Add Logo</TextInputLabel>
      <AddLogoButton
        handleChange={(files) => onLogoChangeHandler(files, DEFAULT_LOGO_CODE)}
        style={{
          width: isMobile ? "100%" : "170px",
        }}
      />
    </div>

    {logos[DEFAULT_LOGO_CODE] ? (
      <div className="imgPreviewWrap">
        <button className="removeButton" onClick={() => onRemovePreview(DEFAULT_LOGO_CODE)}>
          {isMobile && <img src={removeSvg} alt="" />}
          Remove
        </button>
        <div className="preview">
          <img src={logos[DEFAULT_LOGO_CODE]} alt="preview" />
        </div>
      </div>
    ) : null}
  </Layout>;

  const showSideBarMobile = isMobile && detailedSideBar && detailedSideBar !== 'color';
  const hideLayout = (isMobile && detailedSideBar === 'sport') || (isLaptop && !isMobile && detailedSideBar === 'color');

  return (
    <CenteredPageContainer>
      {
        showSideBarMobile && <SideBarMobile />
      }
      {
        !hideLayout && renderLayout()
      }
    </CenteredPageContainer>
  );
};

export default PageDecoration;
