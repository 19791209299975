import React, {useCallback, useState} from 'react';
import FormInput from "../../../components/SideBar/UI/FormInput";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {setContactField} from "../../../store/contactsSlice";
import {ContactsStateType} from "../../../store/types";
import SubmitButton from "../../../components/Form/SubmitButton";
import FormInputRow from "../../../components/Form/FormInputRow";
import {Grid, useMediaQuery} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

interface IErrors {
  name: string | null;
  organization: string | null;
  city: string | null;
  countryState: string | null;
  email: string | null;
  phone: string | null;
  message: string | null;
}

const errorsDefault: IErrors = {
  name: null,
  organization: null,
  city: null,
  countryState: null,
  email: null,
  phone: null,
  message: null,
}

const validate = (contacts: ContactsStateType): IErrors => {
  const errors = {};

  for (const field in contacts) {
    const str = contacts[field].trim();
    if (field !== 'message' && !str) {
      errors[field] = 'This field is required';
    } else if (field === 'message' && str.length > 256) {
      errors[field] = 'Must be at most 256 characters';
    } else if (field !== 'message' && str.length > 32) {
      errors[field] = 'Must be at most 64 characters';
    }
  }

  return {
    ...errorsDefault,
    ...errors,
  };
}

const isValid = (errors: IErrors): boolean => {
  for (const field in errors) {
    if (errors[field]) {
      return false;
    }
  }

  return true;
}

const FormContacts = () => {
  const [errors, setErrors] = useState<IErrors>(errorsDefault);
  const contacts = useAppSelector(state => state.contacts);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  const {
    name,
    organization,
    city,
    countryState,
    email,
    phone,
    message
  } = contacts;

  const dispatch = useAppDispatch();

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof ContactsStateType
  ) => {
    dispatch(setContactField({
      field,
      value: e.target.value,
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(contacts);
    setErrors(errors);
    if (!isValid(errors)) {
      console.log('got an errors');
      return;
    }

    console.log('success submit');
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormInputRow
        error={errors.name}
      >
        <FormInput
          placeholder='Name*'
          value={name}
          onChange={(e) => handleChange(e, 'name')}
        />
      </FormInputRow>

      <FormInputRow
        error={errors.organization}
      >
        <FormInput
          placeholder='Organization*'
          value={organization}
          onChange={(e) => handleChange(e, 'organization')}
        />
      </FormInputRow>

      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <FormInputRow
            error={errors.city}
          >
            <FormInput
              placeholder='City*'
              value={city}
              onChange={(e) => handleChange(e, 'city')}
            />
          </FormInputRow>
        </Grid>

        <Grid item xs={6}>
          <FormInputRow
            error={errors.countryState}
          >
            <FormInput
              placeholder='State*'
              value={countryState}
              onChange={(e) => handleChange(e, 'countryState')}
            />
          </FormInputRow>
        </Grid>
      </Grid>

      <FormInputRow
        error={errors.email}
      >
        <FormInput
          placeholder='Email address*'
          value={email}
          onChange={(e) => handleChange(e, 'email')}
        />
      </FormInputRow>

      <FormInputRow
        error={errors.phone}
      >
        <FormInput
          placeholder='Phone*'
          value={phone}
          onChange={(e) => handleChange(e, 'phone')}
        />
      </FormInputRow>

      <FormInputRow
        error={errors.message}
      >
        <FormInput
          placeholder='Message'
          value={message}
          onChange={(e) => handleChange(e, 'message')}
          multiline
          minRows={3}
        />
      </FormInputRow>

      <SubmitButton
        sx={{
          marginBottom: isMobile ? '32px' : '0',
        }}
      />
    </form>
  );
};

export default FormContacts;
