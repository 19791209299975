import * as PIXI from 'pixi.js';
import * as THREE from 'three';
import { sum } from 'lodash';
import { CanvasType } from '../types/canvasTypes';

const degToRad = (deg) => deg / (180 / Math.PI);

export function textByCircle(
  textStr: string,
  textStyle: Partial<PIXI.ITextStyle>,
  radius: number,
  type: 'top' | 'bottom',
  canvasType: CanvasType
) {
  const spherical = new THREE.Spherical(radius);
  const viewport = new PIXI.Container();
  const text = type === 'top' ? textStr.split('') : textStr.split('').reverse();
  const max_length = radius * Math.PI; // maximum of length of text
  const tS = (type === 'top') ? -1: 1;
  const emptyCount = (text.length === 1) ? 1 : text.length-1;
  let step = degToRad(type === 'top' ? -5 : 5);
  let degreeEmptySpaces = radius * Math.abs(step) * (text.length-1);
  const rotations: number[] = [];
  let sizes = text.map(
    (t) =>
      PIXI.TextMetrics.measureText(t, textStyle as PIXI.TextStyle).width / 2,
  );
  let summa = sum(sizes) * 2 + degreeEmptySpaces;
  let fontSize = textStyle.fontSize as number;
  while (summa > max_length && fontSize > 9) {

      fontSize -= 3;
      textStyle.fontSize = fontSize;
      sizes = text.map(
      (t) =>
        PIXI.TextMetrics.measureText(t, textStyle as PIXI.TextStyle).width / 2,
      );
      summa = sum(sizes) * 2;
      const diff = (max_length - summa);
    if (degreeEmptySpaces > 0.5 * summa && diff > 0) {
        step = diff / radius * tS / emptyCount;
      if (Math.abs(step) < 0.03) step = 0.03 * tS; // ~ 2 degrees
        degreeEmptySpaces = radius * Math.abs(step) * emptyCount;
      }
      summa += degreeEmptySpaces;
  }

  spherical.phi = type == 'top' ? + Math.PI + 0.000001 : 0;
  let lastAngle = 0;
  const curve = sizes.map((_, idx) => {
    const v = new THREE.Vector3().setFromSpherical(spherical);
    const p = new THREE.Vector2(v.y, v.z);
    const vecSize = p.length();
    let padding = sizes[idx + 1] + sizes[idx];
    padding = isNaN(padding) ? 0 : padding;
    const arrow = new THREE.Vector2(
      (-p.y * padding) / vecSize,
      (p.x * padding) / vecSize,
    );
    const p2 = new THREE.Vector2(p.x + arrow.x, p.y + arrow.y);
    const returnPoint = new THREE.Vector2(
      (radius * p2.x) / p2.length(),
      (radius * p2.y) / p2.length(),
    );
    spherical.setFromCartesianCoords(0, returnPoint.x, returnPoint.y);
    rotations.push(-Math.atan(p.x/p.y));
    spherical.phi += step;
    lastAngle =
      type === 'top' ? spherical.phi - step : Math.PI - (spherical.phi - step);
    return p;
  });
  viewport.rotation = lastAngle / 2;

  const resolution = canvasType === "desktop" ? 15 : 2;

  text.forEach((t, idx) => {
    const pixiText = new PIXI.Text('', textStyle);
    pixiText.resolution = resolution;
    pixiText.text = t;
    pixiText.updateText(false);
    const { texture } = pixiText;
    const sprite = new PIXI.Sprite(texture);
    sprite.anchor.set(0.5, 0.5);
    sprite.pivot.set(0.5, 0.5);
    sprite.rotation = rotations[idx];
    sprite.x = curve[idx].x;
    sprite.y = curve[idx].y;
    viewport.addChild(sprite);
  });
  return viewport;
}
