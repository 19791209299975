import {styled, TextField} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

const InputTextMenu = styled(TextField)`
  height: 56px;
  max-width: 200px;
  margin-right: 20px;
  
  .MuiInputBase-root {
    clip-path: polygon(0 0, 100% 0, calc(100% - 18px) 100%, 0% 100%);
    background: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding-left: 32px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #333333;
    
    .MuiInputBase-input {
      padding-left: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background 0s 50000s; // fix for autofill background problem
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
    }
  }

  fieldset {
    border: none;
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    .MuiInputBase-root {
      clip-path: polygon(18px 0, 100% 0, calc(100% - 18px) 100%, 0% 100%);
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .MuiInputBase-root {
      .MuiInputBase-input {
        font-size: 18px !important;
      }
    }
  }
`

export default InputTextMenu;
