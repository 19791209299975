import React from 'react';
import CenteredPageContainer from "../../components/Containers/CenteredPageContainer";
import {Box, styled} from "@mui/material";
import TextHeading2 from "../../components/Typography/TextHeading2";
import {BREAKPOINTS} from "../../constants";
import ButtonSport from "./ButtonSport";
import {selectAllGarmentTypes, selectAllSports, setPageId} from "../../store/appSlice";
import {setSportId} from "../../store/configSlice";
import useAppDispatch from "../../hooks/useAppDispatch";
import {useSelector} from "react-redux";

const Layout = styled(Box)`
  width: 656px;
  max-height: 80vh;
  overflow-y: auto;
  
  .wrap {
    padding-top: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 32px;
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    width: 90%;
    
    .wrap {
      justify-content: center;
      gap: 16px;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .wrap {
      padding-top: 16px;
      column-gap: 1vw;
      justify-content: flex-start;
    }
  }
`

const PageSelectSport = () => {
  const dispatch = useAppDispatch();

  const sportList = useSelector(selectAllSports);

  const allGarmentTypes = useSelector(selectAllGarmentTypes);
  const sports = Object.keys(allGarmentTypes);

  const allGarmentTypesNoDisabled = sports.reduce((total, key) => {
    if (!(key in allGarmentTypes) || allGarmentTypes[key].every((sport) => sport.disabled)) {
      return total;
    }
    total[key] = allGarmentTypes[key];
    return total;
  }, {});

  const activeSports = Object.keys(allGarmentTypesNoDisabled);

  const handleButtonClick = (id: string) => {
    dispatch(setSportId(id));
    dispatch(setPageId(1));
  }

  return (
    <CenteredPageContainer>
      <Layout>
        <TextHeading2>Select your sport</TextHeading2>
        <div className='wrap'>
          {sportList.map((item) => (
            <ButtonSport
              handleClick={() => handleButtonClick(item.id)}
              key={item.id}
              disabled={!activeSports.includes(item.code)}
            >
              {item.title}
            </ButtonSport>
          ))}
        </div>
      </Layout>
    </CenteredPageContainer>
  );
};

export default PageSelectSport;
