import React, {useEffect, useState} from 'react';
import {DecorationLeftText, DecorationsRowBox, DecorationsWrapper} from "../styles/StyledDecorationsNew";
import DropdownList, {IDropdownListRow} from "../DropdownList/DropdownList";
import useAppSelector from "../../../hooks/useAppSelector";
import {useSelector} from "react-redux";
import {selectAllColors, selectAllPatternsBySport, selectDesignsBySportAndGarmentId} from "../../../store/appSlice";
import {getFontsFromDesigns} from "../tools";
import {IBaseColor} from "../../../store/types";
import cleanArray from "../../../utils/cleanArray";
import getMaxNumberOfLayers from "../../../utils/getMaxNumberOfLayers";

const DecorationsNew = () => {
  const [teamName, setTeamName] = useState<IDropdownListRow[]>([]);
  const [number, setNumber] = useState<IDropdownListRow[]>([]);
  const [color, setColor] = useState<IDropdownListRow[]>([]);

  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patterns = useSelector((state) =>
    selectAllPatternsBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );
  const texts = useAppSelector(state => state.config.texts);
  const selectedSport = sports.find((s) => s.id === selectedSportId);
  const patternId = useAppSelector((state) => state.config.patternId);
  const selectedPattern = patterns.find((p) => p.id === patternId);
  const designs = useAppSelector((state) => state.app.designs);
  const numberValue = useAppSelector(state => state.config.number);
  const patternColorScheme = useAppSelector(state => state.config.patternColorScheme);
  const baseColorIds = useAppSelector(state => state.config.baseColorIds);
  const baseColors: IBaseColor[] = useSelector(selectAllColors);
  const sportId = useAppSelector((state) => state.config.sportId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, sportId, garmentIds));
  const layers = getMaxNumberOfLayers(filteredDesigns.filter((k) => k.pattern === patternId));

  useEffect(() => {
    const { teamNameFonts, numberFonts } = getFontsFromDesigns(
      designs,
      selectedSport?.code,
      selectedPattern?.id
    );

    // team name

    const teamNameArr: IDropdownListRow[] = [];
    if (texts.shirt_text_1 && teamNameFonts[0]) {
      teamNameArr.push({
        value1: texts.shirt_text_1,
        value2: teamNameFonts[0],
      });
    }
    if (texts.shirt_text_2) {
      teamNameArr.push({
        value1: texts.shirt_text_2,
        value2: teamNameFonts[1] ? teamNameFonts[1] : teamNameFonts[0],
      });
    }
    setTeamName(teamNameArr);

    // number

    const numberArr: IDropdownListRow[] = [];
    if (numberValue) {
      numberArr.push({
        value1: numberValue,
        value2: numberFonts[0],
      });
    }
    setNumber(numberArr);

    // color

    // цвета со слоев паттерна
    const patternColors: string[] = [];
    patternColorScheme.forEach((k, patternIndex) => {
      if (patternIndex < layers) {
        patternColors.push(k.colorId);
      }
    });
    // все остальные цвета, кроме Accent1
    const allColors: string[] = [];
    for (const color in baseColorIds) {
      if (color !== 'Accent1') {
        allColors.push(baseColorIds[color].id);
      }
    }
    const resultColors = patternColors.concat(allColors);
    const filteredResultColors = cleanArray(resultColors);

    const colorArr: IDropdownListRow[] = filteredResultColors.map((id) => {
      const myColor = baseColors.find((k) => k.id === id);
      return {
        value1: myColor?.id || '',
        value2: myColor?.title || '',
        isColor: true,
      }
    });
    setColor(colorArr);
  }, [
    designs,
    selectedSport,
    selectedPattern,
    texts,
    numberValue,
    patternColorScheme,
    baseColors,
    baseColorIds,
    layers,
  ]);

  return (
    <DecorationsWrapper>
      <DecorationsRowBox>
        <DecorationLeftText>Team name:</DecorationLeftText>
        <DropdownList
          rows={teamName}
          maxItems={1}
        />
      </DecorationsRowBox>
      <DecorationsRowBox>
        <DecorationLeftText>Number:</DecorationLeftText>
        <DropdownList
          rows={number}
          maxItems={1}
        />
      </DecorationsRowBox>
      <DecorationsRowBox>
        <DecorationLeftText>Color:</DecorationLeftText>
        <DropdownList
          rows={color}
          maxItems={3}
          isColor
        />
      </DecorationsRowBox>
    </DecorationsWrapper>
  );
};

export default DecorationsNew;
