import React from 'react';
import {styled, css} from "@mui/material";
import closeSvg from '../../../assets/img/UI/close.svg';
import backSvg from '../../../assets/img/UI/back.svg';
import {COLORS} from "../../../constants";
import {AnimationSideBarButtonCss} from "./styles";

const expandedCss = css`
  background: ${COLORS.textDarkGrey};
  width: 199px;
  height: 56px;
  clip-path: polygon(0 0, 100% 0, 179px 100%, 0 100%);
  padding-left: 43px;
  justify-content: flex-start;
  
  .titleBox {
    display: flex;
    align-items: center;
    gap: 14px;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: ${COLORS.textGrey};
  }
`

interface IWrapperProps {
  myTitle?: string;
}

const Wrapper = styled('button')<IWrapperProps>`
  ${AnimationSideBarButtonCss};
  
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.boxBg};
  width: 129px;
  height: 46px;
  clip-path: polygon(0 0, 100% 0, 113px 100%, 0 100%);
  margin: 0 20px 10px 0;
  
  ${props => props.myTitle && expandedCss}
`

interface IProps {
  handleClick: () => void;
  title?: string;
}

const ButtonMenuClose = ({ handleClick, title }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
      myTitle={title}
    >
      {
        title ?
          <div className='titleBox'>
            <img src={backSvg} alt='>'/>
            <p>{title}</p>
          </div>
          : <img src={closeSvg} alt='X'/>
      }
    </Wrapper>
  );
};

export default ButtonMenuClose;
