import React from 'react';
import {styled, css} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../../constants";

interface IWrapperProps {
  selected: boolean;
}

const selectedCss = css`
  background: black;
  
  > div {
    background: white;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    background: ${COLORS.textDarkGrey};
    border: 3px solid ${COLORS.textDarkGrey};
  }
`

const Wrapper = styled('button')<IWrapperProps>`
  background: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  
  > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    transition: 0.3s;
  }
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    border: 3px solid #828282;
    border-radius: 100px;
    width: 32px;
    height: 16px;
    background: #fff;

    > div {
      display: none;
    }
  }
  
  ${props => props.selected && selectedCss};
`

interface IProps {
  selected: boolean;
  handleClick: () => void;
}

const SliderDotButton = ({ selected, handleClick }: IProps) => {
  return (
    <Wrapper
      selected={selected}
      onClick={handleClick}
    >
      <div />
    </Wrapper>
  );
};

export default SliderDotButton;
