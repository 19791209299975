export default function updateObject(target: object, update: object){
    for (const [key,value] of Object.entries(update)){
       if (Object.prototype.hasOwnProperty.call(target, key)){
         if (['string','number','boolean'].includes(typeof value)){
           target[key] = value;
           if (typeof value === 'object'){
              updateObject(target[key], value)
           }
         }
         else if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i+=1) { 
               updateObject(target[key][i], value[i]);
            }
            // target[key] = newArray;
         }
       }
    }
 }
 