import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

type ControlsState = {
  position: THREE.Vector3;
  minDistance?: number;
  maxDistance?: number;
};

type CameraState = {
  position: THREE.Vector3;
  isFar?: boolean;
};
type CameraType = {
  perspective?: THREE.PerspectiveCamera;
  orthogonal?: THREE.OrthographicCamera;
};

export default class SceneWrapper {
  private readonly cameras: CameraType = {};
  private readonly renderers: Array<THREE.WebGLRenderer> = [];
  private readonly controlses: Array<OrbitControls> = [];
  private readonly scene: THREE.Scene = new THREE.Scene();
  private renderer: THREE.WebGLRenderer;
  private controls: OrbitControls;

  constructor() {
    this.cameras.perspective = this.makeCamera("perspective");
    this.cameras.orthogonal = this.makeCamera("orthogonal");
    // this.runRenderCycle();
  }

  public get camera(): THREE.PerspectiveCamera {
    if (!this.cameras.perspective) throw Error("Camera is undefined");
    return this.cameras.perspective;
  }
  public get getRenderer(): THREE.Renderer {
    return this.renderer;
  }

  public get children(): THREE.Object3D[] {
    return this.scene.children;
  }

  public setBlockControls(block: boolean): void {
    this.controls.enabled = !block;
  }

  private get screeenshotCamera(): THREE.OrthographicCamera {
    if (!this.cameras.orthogonal) throw Error("Camera is undefined");
    return this.cameras.orthogonal;
  }
  public appendChild(object: THREE.Object3D) {
    this.scene.add(object);
  }

  public setContainer(container: HTMLElement | null): void {
    if (!container) return;
    const renderer = this.makeThreeRenderer();
    this.renderer = renderer;
    container.style.width = "100%";
    container.style.height = "100%";
    this.renderer.domElement.style.height = "100%";
    this.renderer.domElement.style.width = "100%";
    this.renderer.domElement.remove();
    container.appendChild(this.renderer.domElement);
    this.controls = this.makeControls();
    if (this.renderers.length < 2) {
      this.renderers.push(this.renderer);
      this.controlses.push(this.controls);
    } else return;
  }

  public setShotMode() {
    this.renderer.setSize(300, 396);
    this.camera.aspect = 300 / 396;
    this.camera.updateProjectionMatrix();
  }
  public setMainMode(): void {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.camera.aspect = window.innerWidth / window.innerHeight;
  }
  public getShot(camera: keyof CameraType = "perspective"): string {
    this.render(camera);
    const url = this.renderer.domElement.toDataURL();
    return url;
  }
  public setContainerTo(idx: number): boolean {
    if (this.renderers[idx]) {
      this.renderer = this.renderers[idx];
      this.controls = this.controlses[idx];
      return true;
    }
    return false;
  }
  public runRenderCycle(): void {
    this.renderer?.setAnimationLoop(() => {
      this.syncRendererSize();
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
      this.controls.update();
      this.render();
    });
  }
  public stopRenserCycle() {
    this.renderer?.setAnimationLoop(null);
    this.cameras.orthogonal = null;
    this.cameras.perspective = null;
    this.renderers.length = 0;
    this.controlses.length = 0;
    this.renderer = null;
    this.controls = null;
  }
  public syncRendererSize(): void {
    const { parentElement } = this.renderer.domElement;

    if (!parentElement) return;

    const parentSize = new THREE.Vector2(parentElement.offsetWidth, parentElement.offsetHeight);
    const rendererSize = this.renderer.getSize(new THREE.Vector2());

    if (parentSize.equals(rendererSize)) return;

    this.renderer.setSize(parentElement.offsetWidth, parentElement.offsetHeight);
    this.camera.aspect = parentElement.offsetWidth / parentElement.offsetHeight;
    this.camera.updateProjectionMatrix();
  }

  public resize(width: number, height: number): void {
    this.renderer.setSize(width, height);
    const canvas = this.renderer.domElement;
    this.camera.aspect = canvas.width / canvas.height;
    this.camera.updateProjectionMatrix();
  }

  public render(camera: keyof CameraType = "perspective"): void {
    if (this.cameras?.[camera])
      this.renderer?.render(this.scene, this.cameras[camera]);
  }

  public setEnvironment(texture: THREE.DataTexture | null): void {
    if (texture) {
      texture.mapping = THREE.EquirectangularRefractionMapping;
      texture.needsUpdate = true;
    }
    this.scene.environment = texture;
  }

  public setBackground(texture: THREE.Texture | null): void {
    if (texture) {
      texture.mapping = THREE.EquirectangularRefractionMapping;
      texture.needsUpdate = true;
    }
    this.scene.background = texture;
  }

  public destroy(): void {
    this.renderer?.setAnimationLoop(null);
    this.renderer?.dispose();
    if (this.renderer?.domElement.parentElement) this.renderer?.domElement.remove();
  }

  private makeCamera(type: "perspective"): THREE.PerspectiveCamera;
  private makeCamera(type: "orthogonal"): THREE.OrthographicCamera;
  private makeCamera(type: keyof CameraType): CameraType[keyof CameraType] {
    if (type === "perspective") {
      const camera = new THREE.PerspectiveCamera(50, 1, 0.1, 500);
      this.scene.add(camera);
      return camera;
    } else {
      const camera = new THREE.OrthographicCamera();
      this.scene.add(camera);
      return camera;
    }
  }

  private makeControls(): OrbitControls {
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.maxPolarAngle = Math.PI / 2;
    controls.minPolarAngle = Math.PI / 4;
    controls.enablePan = false;
    return controls;
  }

  private makeThreeRenderer(): THREE.WebGLRenderer {
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setClearColor(0xffffff, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;
    return renderer;
  }

  public setControlsState(state: ControlsState) {
    const { position, minDistance, maxDistance } = state;
    this.controls.target.copy(position);
    if (minDistance) this.controls.minDistance = minDistance;
    if (maxDistance) this.controls.maxDistance = maxDistance;
    this.controls.update();
  }

  public setCameraState(state: CameraState) {
    const { position } = state;
    this.camera.position.copy(position);
    if (state.isFar) {
      this.camera.position.z -= 4;
    }
  }
}
