import React, {useState} from 'react';
import LayoutContainer from "./LayoutContainer";
import useAppSelector from "../../../../hooks/useAppSelector";
import ColorSelector from "../components/ColorSelector";
import ColorSwitcher from "../components/ColorSwitcher";

const LayoutColors = () => {
  const [openSwitcher, setOpenSwitcher] = useState<boolean>(false);

  const pageId = useAppSelector(state => state.app.pageId);

  const handleClick = () => {
    setOpenSwitcher(prev => !prev);
  }

  return (
    <LayoutContainer title='Color'>
      {
        pageId > 3 ?
          <ColorSwitcher
            open={openSwitcher}
            setOpen={handleClick}
          /> :
          <ColorSelector />
      }
    </LayoutContainer>
  );
};

export default LayoutColors;
