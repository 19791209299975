import React from 'react';
import {styled} from "@mui/material";
import clickSvg from '../../../assets/img/UI/click-mob.svg';
import {COLORS} from "../../../constants";

interface IWrapperProps {
  bgsrc: string;
}

const Wrapper = styled('button')<IWrapperProps>`
  width: 110px;
  height: 190px;
  background-image: url(${props => props.bgsrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: .2s;
  transform: scale(.85);
  
  &:hover {
    transform: scale(1);
  }
`;

interface ISelectedProps {
  selected: boolean;
}

const SelectedBox = styled('div')<ISelectedProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLORS.textGrey};
  opacity: ${props => props.selected ? 1 : 0};
  transition: .3s;
`;

interface IProps {
  src: string;
  selected: boolean;
  onClick: () => void;
}

const ButtonPatternDesktop = ({ src, selected, onClick }: IProps) => {
  return (
    <Wrapper
      onClick={onClick}
      bgsrc={src}
    >
      <SelectedBox
        selected={selected}
      >
        <img src={clickSvg} alt='+'/>
      </SelectedBox>
    </Wrapper>
  );
};

export default ButtonPatternDesktop;
