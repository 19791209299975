import React from 'react';
import {styled, css, useMediaQuery} from "@mui/material";
import prevArrow from '../../assets/img/UI/prev-arrow.svg';
import {BREAKPOINTS} from "../../constants";

type TVariants = 'prev' | 'next';

const nextActiveCss = css`
  @keyframes nextActive {
    50% {
      transform: translateX(15px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

const disabledCss = css`
  opacity: .6;
  cursor: default;
  
  .animation {
    animation-name: none;
  }

  &:hover {
    .animation {
      transform: none;
    }
  }
`

interface IWrapperProps {
  variant: TVariants;
  disabled: boolean;
  isMobile: boolean;
  hide: boolean;
}

const Wrapper = styled('button')<IWrapperProps>`
  ${nextActiveCss};
  
  padding: 0 5px;
  background: none;
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
  pointer-events: all;

  img {
    width: 48px
  }

  .animation {
    transition: .3s;
    
    ${props => props.variant === 'next' && !props.isMobile && css`
      animation-name: nextActive;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    `}
    
    img {
      transform: ${props => props.variant === 'next' ? 'rotateZ(180deg)' : 'none'};
    }
  }
  
  &:hover {
    .animation {
      transform: translateX(${props => props.variant === 'next' ? 'none' : '-15px'});
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    height: 56px;
    width: 56px;

    img {
      width: 36px;
    }

    &:hover {
      .animation {
        transform: translateX(${props => props.variant === 'next' ? 'none' : '-10px'});
      }
    }
  }
  
  ${props => props.disabled && disabledCss};
}
`

interface IProps {
  handleClick: () => void;
  variant: TVariants;
  disabled?: boolean;
  hide?: boolean;
}

const PaginatorArrow = ({ variant, disabled, handleClick, hide }: IProps) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  const onClick = () => {
    if (!disabled && !hide) {
      handleClick();
    }
  }

  return (
    <Wrapper
      variant={variant}
      disabled={disabled}
      isMobile={isMobile}
      onClick={onClick}
      hide={hide}
    >
      <div className='animation'>
        <img src={prevArrow} alt='arrow'/>
      </div>
    </Wrapper>
  );
};

export default PaginatorArrow;
