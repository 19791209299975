import React, { useEffect, useState, CSSProperties, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setSelectedPreviewGarmentId } from "../../store/canvasSlice";
import { selectAllDesigns, selectAllGarmentTypesBySport, setLoading, setAssetsLoading, selectAllPatternsBySport, setFirstLoading, selectAllColors } from "../../store/appSlice";
import { App } from "../../app3d/App";
import { BREAKPOINTS } from "../../constants";
import makeGarmentParams from "../../utils/makeGarmentParams";

type canvasStyle = { width: string; height: string; top: 0; position: string; display: string };
export type ImageEntity = { src: string; label: string; name: string };

interface IProps {
  CanvasElement: JSX.Element;
  app: App;
  initialPage: number;
}

const Canvas3D = ({ CanvasElement, app, initialPage }: IProps) => {
  const pageId = useAppSelector((state) => state.app.pageId);
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patternsForSelectedSport = useAppSelector((state) =>
    selectAllPatternsBySport(
      state,
      sports.find((s) => s.id === selectedSportId),
    ),
  );
  const showCanvas = useAppSelector((state) => state.app.showCanvas);
  const assetsLoading = useAppSelector((state) => state.app.assetsLoading);
  const loading = useAppSelector((state) => state.app.loading);
  const canvasIsPreview = useAppSelector((state) => state.app.canvasIsPreview);
  const patternColorScheme = useAppSelector((state) => state.config.patternColorScheme);
  const baseColors = useAppSelector(selectAllColors);
  const patternLayersColors = patternColorScheme.map((scheme) => {
    return baseColors.find((color) => color.id === scheme.colorId);
  });

  const selectedPreviewGarmentId = useAppSelector((state) => state.canvas.selectedPreviewGarmentId);
  let selectedGarmentIds = useAppSelector((state) => state.canvas.selectedGarmentIds);
  selectedGarmentIds = canvasIsPreview ? [selectedPreviewGarmentId ?? "0"] : selectedGarmentIds;

  const selectedPatternId = useAppSelector((state) => state.config.patternId);
  const selectedNumber = useAppSelector((state) => state.config.number);
  const garmentIds = useAppSelector((state) => state.config.garmentIds);
  const garmentList = useAppSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId),
    ),
  );
  const texts = useAppSelector((state) => state.config.texts);
  const logos = useAppSelector((state) => state.config.logos);
  const garments = useAppSelector((state) => state.app.designs);
  const [lastTexts, setLastTexts] = useState(null);
  const [lastLogos, setLastLogos] = useState(null);
  const [lastSelectedColors, setLastSelectedColors] = useState(null);
  const [lastPatternColorScheme, setLastPatternColorScheme] = useState(null);
  const [lastSelectedNumber, setLastSelectedNumber] = useState(null);
  const [textChanged, setTextChanged] = useState(false);
  const [logoChanged, setLogoChanged] = useState(false);
  const [selectedColorsChanged, setSelectedColorsChanged] = useState(false);
  const [patternColorSchemeChanged, setPatternColorSchemeChanged] = useState(false);
  const [selectedNumberChanged, setSelectedNumberChanged] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  let updateCounter = 0;

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  const selectedColors = useAppSelector((state) => state.config.baseColorIds);
  const designs = useAppSelector(selectAllDesigns);

  useEffect(() => {
    // we need to specify sport type for garments
    if (!sports.length) return;
    app.setCurrentSport(sports.find((s) => s.id === selectedSportId)?.code);
  }, [selectedSportId]);

  useEffect(() => {
    if (garmentList.length) {
      const garments = garmentList.filter((g) => garmentIds.includes(g.id));
      garments.forEach((garment) => app.addGarment(garment.code));
    }
  }, [garmentIds]);

  useEffect(() => {
    // we need to select at least one garment in the slider scene
    if (canvasIsPreview && garmentIds.length && !selectedPreviewGarmentId) {
      dispatch(setSelectedPreviewGarmentId([garmentIds[0]]));
    }
  }, [selectedGarmentIds, garmentIds, canvasIsPreview]);

  useEffect(() => {
    app.setupDesigns(designs);
  }, []);

  useEffect(() => {
    // we need to select the configuration depending on the device type
    dispatch(setAssetsLoading(true));
    if (!showCanvas) return;
    dispatch(setLoading(true));
    app
      .prepareSelectedGarment()
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(setAssetsLoading(false));
        dispatch(setLoading(false));
      });
  }, [designs, isMobile, showCanvas]);

  useEffect(() => {
    if (lastTexts !== texts) {
      updateCounter += 1;
      setTimeout(() => {
        if (updateCounter > 1) {
          updateCounter -= 1;
        } else {
          setLastTexts(texts);
          setTextChanged(true);
        }
      }, 400);
    }
  }, [texts]);

  useEffect(() => {
    setLogoChanged(true);
    setLastLogos(logos);
  }, [logos]);

  useEffect(() => {
    setSelectedColorsChanged(true);
    setLastSelectedColors(selectedColors);
  }, [selectedColors]);

  useEffect(() => {
    setPatternColorSchemeChanged(true);
    setLastPatternColorScheme(patternColorScheme);
  }, [patternColorScheme]);

  useEffect(() => {
    setSelectedNumberChanged(true);
    setLastSelectedNumber(selectedNumber);
  }, [selectedNumber]);
  const params = useMemo(
    () => ({ lastSelectedColors, lastPatternColorScheme, selectedSportId, selectedPatternId, lastSelectedNumber, lastTexts, lastLogos, showCanvas, assetsLoading }),
    [lastSelectedColors, lastPatternColorScheme, selectedSportId, selectedPatternId, lastSelectedNumber, lastTexts, lastLogos, showCanvas, assetsLoading]
  );

  useEffect(() => {
    const { lastSelectedColors, selectedSportId, selectedPatternId, lastSelectedNumber, lastTexts, lastLogos, showCanvas, assetsLoading } = params;
    // we need to apply the garment parameters when changing any of them
    if (!showCanvas || assetsLoading) return;
    if (lastTexts === null || lastLogos === null || lastSelectedColors === null || lastSelectedNumber === null) return;
    if (firstLoad) {
      dispatch(setLoading(true));
    } else {
      if (!(textChanged || logoChanged || selectedColorsChanged || patternColorSchemeChanged || selectedNumberChanged)) {
        dispatch(setLoading(true));
      }
    }
    setFirstLoad(false);
    setTextChanged(false);
    setLogoChanged(false);
    setSelectedColorsChanged(false);
    setPatternColorSchemeChanged(false);
    setSelectedNumberChanged(false);
    const selectedSport = sports.find((s) => s.id === selectedSportId);
    const selectedPattern = patternsForSelectedSport.find((p) => p.id === selectedPatternId);
    if (!garmentList.length) return;
    const selectedGarmentList = garmentList.filter((g) => garmentIds.includes(g.id));
    async function setParams() {
      for (const garmentType of selectedGarmentList) {
        const garment =
          selectedSport && selectedPattern && garmentType
            ? garments.find((g) => {
                return g.sport === selectedSport.code && g.pattern === selectedPattern.id && garmentType.code === g.garmentType;
              })
            : undefined;
        if (!garment) continue;
        await app.setupParams(garmentType.code, makeGarmentParams(garment, lastSelectedColors, lastSelectedNumber, selectedPattern.code, lastLogos, lastTexts, isMobile ? "mobile" : "desktop", patternLayersColors));
      }
      return Promise.resolve();
    }
    new Promise((resolve) => setTimeout(resolve))
      .then(() => {
        return setParams();
      })
      .then(() => {
        dispatch(setLoading(false));
        dispatch(setFirstLoading(false));
      });
  }, [params]);

  useEffect(() => {
    // we need to show one of the garment in the slider scene
    if (!canvasIsPreview) return;
    if (selectedPreviewGarmentId) {
      const garment = garmentList.find((g) => g.id === selectedPreviewGarmentId);
      app.showSingleGarment(garment?.code || "jersey", true);
    }
  }, [selectedGarmentIds, showCanvas, selectedPatternId]);

  useEffect(() => {
    if (!showCanvas) return;
    garmentList.forEach((g, idx) => console.log({ [idx]: g }));
  }, [showCanvas, garmentList]);

  useEffect(() => {
    // we need to show one or all of the garment in the main scene
    if (!showCanvas || loading || assetsLoading) {
      return;
    }
    if (canvasIsPreview) {
      app.showAllGarments();
    } else if (selectedGarmentIds.length === 1) {
      const garment = garmentList.find((g) => g.id === selectedGarmentIds[0]);
      garment && app.showSingleGarment(garment.code, false);
    } else if (selectedGarmentIds.length > 1) {
      app.showAllGarments();
    }
  }, [selectedGarmentIds, showCanvas, canvasIsPreview, assetsLoading, loading]);

  const [style, setStyle] = useState<canvasStyle>();
  useEffect(() => {
    // We need to show the main scene on page 4 or when the preview is enabled, and the slider scene on page 5
    if (initialPage === 5) {
      canvasIsPreview && !showCanvas && app.setContainerTo(1);
    }
    if (initialPage === 4) {
      showCanvas && app.setContainerTo(0);
    }
  }, [pageId, canvasIsPreview, showCanvas]);

  useEffect(() => {
    if (initialPage === 5) {
      setStyle({ width: "100%", height: "100%", display: canvasIsPreview ? "block" : "none", top: 0, position: "static" });
    } else {
      setStyle({ width: "100%", height: "100%", top: 0, position: "fixed", display: showCanvas ? "none" : "block" });
    }
    if (initialPage === 4) {
      setStyle({ width: "100%", height: "100%", top: 0, position: "fixed", display: showCanvas && !loading ? "block" : "none" });
    }
  }, [pageId, canvasIsPreview, showCanvas]);

  return <div style={style as CSSProperties}>{CanvasElement}</div>;
};

export default Canvas3D;
