import React, { useEffect } from "react";
import PageSelectSport from "./SelectSport/PageSelectSport";
import { Box, styled, useMediaQuery } from "@mui/material";
import Header from "../components/Header/Header";
import MainMenu from "../components/MainMenu/MainMenu";
import Modal from "../components/Containers/Modal";
import Paginator from "../components/Paginator/Paginator";
import { BREAKPOINTS, PAGES_ORDER_ARR } from "../constants";
import PageBaseColors from "./BaseColors/PageBaseColors";
import PageDecoration from "./Decoration/PageDecoration";
import SideBarDesktop from "../components/SideBar/Desktop/SideBarDesktop";
import PageGarment from "./Garment/PageGarment";
import Canvas3D from "../components/Canvas3d/Canvas3d";
import SliderLayout from "../components/Slider/SliderLayout";
import PagePreview from "./Preview/PagePreview";
import ButtonShuffleColors from "../components/SideBar/UI/ButtonShuffleColors";
import MobileMenu from "../components/SideBar/MobileMenu";
import useAppSelector from "../hooks/useAppSelector";
import Preloader from "./Preloader/Preloader";
import useSelector from "../hooks/useAppSelector";
import { selectAllGarmentTypes } from "../store/appSlice";
import useApp3d from "../hooks/useApp3d";
import Spinner from "../components/Spinner/Spinner";
import useApp3dRefCallback from "../hooks/useAppRefCallback";
import ButtonShufflePatterns from "../components/SideBar/UI/ButtonShufflePatterns";
import useApp3dScreenshots from "../hooks/useApp3dScreenshots";
import Canvas3DShots from "../components/Canvas3dShots/Canvas3dShots";

const Container = styled(Box)`
  width: 100%;
  position: relative;
`;

const MainPage = () => {
  const pageId = useAppSelector((state) => state.app.pageId);
  const pageName = PAGES_ORDER_ARR[pageId];

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const garmentList = useSelector((state) => selectAllGarmentTypes(state));

  const app = useApp3d();
  const appShots = useApp3dScreenshots();
  const sceneConfig = useAppSelector((state) => state.app.sceneConfig);
  const useApp3dCallback = useApp3dRefCallback({
    app,
    garmentList,
    sceneConfig,
  });
  const useApp3dShotsCallback = useApp3dRefCallback({
    app: appShots,
    garmentList,
    sceneConfig,
    render: false,
  });

  useEffect(() => {
    return () => {
      if (app) {
        app.destroy();
      }
    };
  }, [app]);
  const renderPageLayout = () => {
    switch (PAGES_ORDER_ARR[pageId]) {
      case "sport":
        return <PageSelectSport />;
      case "color":
        return <PageBaseColors />;
      case "decoration":
        return <PageDecoration />;
      case "garment":
        return <PageGarment />;
      case "preview":
        return <PagePreview />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Preloader />
      <Spinner />
      <Header />
      {pageName === "pattern" && isMobile && (
        <>
          <ButtonShuffleColors />
          <ButtonShufflePatterns />
        </>
      )}
      {pageId > 0 && pageId < 5 && !isMobile && <SideBarDesktop
          buttonsCount={pageId}
      />}
      <MobileMenu />
      {renderPageLayout()}
      <MainMenu />
      <Modal />
      <Paginator pageId={pageId} />
      {pageId > 3 && !isMobile && <SliderLayout />}

      <Canvas3DShots
        CanvasElement={<div ref={useApp3dShotsCallback} />}
        app={appShots}
        initialPage={4}
      />
      <Canvas3D
        CanvasElement={<div ref={useApp3dCallback} />}
        app={app}
        initialPage={4}
      />
    </Container>
  );
};

export default MainPage;
