import React from 'react';
import {Button, styled, SxProps} from "@mui/material";
import {COLORS} from "../../constants";
import {SubmitSend} from "../../assets/img/svgComponents";

const Wrapper = styled(Button)`
  width: 100%;
  height: 56px;
  background: ${COLORS.textDarkGrey};
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-transform: none;
  border-radius: 0;
  
  &:hover {
    background: ${COLORS.textDarkGrey};
  }
  
  &:active {
    color: #009FF1;
    
    svg {
      path {
        stroke: #009FF1;
      }
    }
  }
  
  &.Mui-disabled {
    color: white;
    background: #BCBCBC;
  }
  
  svg {
    margin-left: 8px;
    path {
      transition: .3s;
    }
  }
`;

interface IProps {
  disabled?: boolean;
  sx?: SxProps;
}

const SubmitButton = ({ disabled, sx }: IProps) => {
  return (
    <Wrapper
      type='submit'
      disabled={disabled}
      sx={sx}
    >
      Submit
      <SubmitSend />
    </Wrapper>
  );
};

export default SubmitButton;
