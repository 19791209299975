import React from 'react';
import {styled} from "@mui/material";
import {COLOR_PICKER_WIDTH} from "../../constants";

interface IWrapperProps {
  rgb: string;
  selected: boolean;
}

const Wrapper = styled('button')<IWrapperProps>`
  background: ${props => props.rgb};
  width: ${COLOR_PICKER_WIDTH + 'px'};
  height: ${props => props.selected ? '90px' : '70px'};
  transition: 0.3s;
  position: relative;
  display: flex;
  justify-content: center;
  
  .title {
    position: absolute;
    top: -25px;
    opacity: 0;
    font-size: 15px;
    user-select: none;
    pointer-events: none;
    cursor: default;
    white-space: nowrap;
  }
  
  &:hover {
    height: 90px;
    
    .title {
      opacity: 1;
    }
  }
`

interface IProps {
  rgb: string;
  selected?: boolean;
  title: string;
  handleClick: () => void;
}

const ColorPicker = ({ rgb, title, selected, handleClick }: IProps) => {
  return (
    <Wrapper
      rgb={rgb}
      selected={selected}
      onClick={handleClick}
    >
      <p className='title'>{title}</p>
    </Wrapper>
  );
};

export default ColorPicker;
