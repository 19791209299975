import React from 'react';
import {styled} from "@mui/material";
import chevronBlack from "../../assets/img/UI/click-chevron-black.svg";
import chevronWhite from "../../assets/img/UI/click-chevron-white.svg";
import {BREAKPOINTS} from "../../constants";
import {IBaseColor} from "../../store/types/types";

interface IWrapperProps {
  rgb: string;
  colortitle: string;
  selected: boolean;
  boxshadow: boolean;
  secondPalette: boolean;
  isBottomMenu: boolean;
}

const Wrapper = styled('button')<IWrapperProps>`
  width: 100%;
  height: 100%;
  background: ${props => props.rgb};
  border-radius: 50%;
  box-shadow: ${props => props.boxshadow ? '0 0 30px 5px #e3e3e3' : 'none'};

  &::before {
    content: '${props => props.colortitle}';
    width: fit-content;
    position: absolute;
    bottom: ${props => props.secondPalette ? '45px' : '50px'};
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #FFFFFF;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    padding: 5px 3px;
    border: 1px solid #000;
    transition: .3s;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: ${props => props.isBottomMenu ? 0 : 1};
    }
  }

  .chevron {
    width: 50%;
    margin-top: 5px;
    transition: .3s;
    opacity: ${props => props.selected ? 1 : 0};
  }

  @media all and (max-width: ${BREAKPOINTS.lg}) {
    &::before {
      bottom: ${props => props.secondPalette ? '35px' : '50px'};
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.md}) {
    &::before {
      bottom: ${props => props.secondPalette ? '25px' : '50px'};
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.special}) {
    &::before {
      bottom: ${props => props.secondPalette ? '30px' : '40px'};
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    border: ${props => props.boxshadow ? '1px solid #D9D9D9' : 'none'};
    box-shadow: none;

    &::before {
      bottom: 40px;
    }
  }
`

interface IProps {
  handleClick: () => void;
  data: IBaseColor;
  selected: boolean;
  secondPalette?: boolean;
  isBottomMenu?: boolean;
}

const ColorCircle = ({ handleClick, data, selected, secondPalette, isBottomMenu }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
      rgb={data.rgb}
      colortitle={data.title}
      selected={selected}
      boxshadow={data.boxShadow}
      secondPalette={secondPalette}
      isBottomMenu={isBottomMenu}
    >
      <img
        src={data.blackChevron ? chevronBlack : chevronWhite}
        alt='+'
        className='chevron'
      />
    </Wrapper>
  );
};

export default ColorCircle;
