import React from 'react';
import {Box, css, styled} from "@mui/material";
import ButtonMenuClose from "../../UI/ButtonMenuClose";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {selectAllColors, setDetailedSideBar} from "../../../../store/appSlice";
import {BREAKPOINTS} from "../../../../constants";
import useAppSelector from "../../../../hooks/useAppSelector";
import {assignColorsFromSelectedToBase} from "../../../../utils/assignColors";
import {setBaseColorIds} from "../../../../store/configSlice";
import useSelector from "../../../../hooks/useAppSelector";

interface IWrapperProps {
  movetocenter: string;
  smallerheight: string;
}

const moveToCenterCss = css`
  width: 100%;

  .menuContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const Wrapper = styled(Box)<IWrapperProps>`
  .menuContent {
    max-height: calc(70vh - ${props => props.smallerheight === 'true' ? '180px' : '110px'});
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  @media all and (max-width: ${BREAKPOINTS.special}) {
    ${props => props.movetocenter === 'true' ? moveToCenterCss : null};
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .menuContent {
      max-height: calc(100vh - 214px);
    }
  }
`

interface IProps {
  children: React.ReactNode;
  title?: string;
  isFourthMobilePage?: boolean;
}

const LayoutContainer = ({ children, title, isFourthMobilePage }: IProps) => {
  const dispatch = useAppDispatch();

  const pageId = useAppSelector(state => state.app.pageId);
  const detailedSideBar = useAppSelector(state => state.app.detailedSideBar);
  const selectedColorIds = useAppSelector((state) => state.config.selectedColorIds);
  const baseColors = useSelector(selectAllColors);

  const handleCloseMenu = () => {
    if (pageId === 2 || pageId === 3) {
      // в момент закрытия colors, мы должны назначить выбранные цвета
      const colors = assignColorsFromSelectedToBase(selectedColorIds, baseColors);
      dispatch(setBaseColorIds(colors));
    }
    dispatch(setDetailedSideBar(null));
  }

  const smallerHeight = detailedSideBar === 'color' && pageId === 4;

  // only for '1024px' breakpoint
  const moveToCenter = detailedSideBar === 'decoration' || detailedSideBar === 'color' && pageId !== 4;

  return (
    <Wrapper
      movetocenter={String(moveToCenter)}
      smallerheight={String(smallerHeight)}
    >
      {!isFourthMobilePage && (
        <ButtonMenuClose
          handleClick={handleCloseMenu}
          title={pageId > 3 && title ? title : null}
        />
      )}
      <div className='menuContent'>
        { children }
      </div>
    </Wrapper>
  );
};

export default LayoutContainer;
