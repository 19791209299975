import React from 'react';
import {styled} from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../../constants";
import {AnimationSideBarButtonCss} from "./styles";

interface IWrapperProps {
  delay: string;
  disabled: boolean;
}

const Wrapper = styled('button')<IWrapperProps>`
  ${AnimationSideBarButtonCss};
  animation-delay: ${props => props.delay};
  
  width: 200px;
  height: 40px;
  border: ${props => props.disabled ? `1px solid ${COLORS.greyBg}` : '1px solid #000000'};
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${props => props.disabled ? COLORS.greyBg : COLORS.textDarkGrey};
  transition: 0.3s;
  background: none;
  margin-right: 20px;

  .hidden {
    display: none;
  }
  
  &:hover {
    background: ${props => props.disabled ? '' : COLORS.boxBg};
    border: ${props => props.disabled ? '' : `1px solid ${COLORS.boxBg}`};
    color: ${props => props.disabled ? '' : '#FFFFFF'};
    transform: ${props => props.disabled ? '' : 'scale(1.1) !important'};
    cursor: ${props => props.disabled ? 'default' : ''};

    .visible {
      display: none;
    }

    .hidden {
      display: block;
    }
  }
  
  @media all and (max-width: ${BREAKPOINTS.lg}) {
    width: 150px;
    height: 35px;
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    width: 120px;
    height: 25px;
    font-size: 14px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 45%;
    display: inline-block;
    height: 55px;
    font-size: 16px;
    margin: 10px 2.2%;
  }
`

interface IProps {
  children: React.ReactNode;
  handleClick: () => void;
  delay?: string;
  disabled: boolean;
}

const ButtonMenuSport = ({ children, handleClick, delay = '0s', disabled }: IProps) => {
  return (
    <Wrapper
      onClick={handleClick}
      delay={delay}
      disabled={disabled}
    >
      <div className='visible'>{ children }</div>
      <div className='hidden'>{disabled ? 'Coming soon' : children}</div>
    </Wrapper>
  );
};

export default ButtonMenuSport;
