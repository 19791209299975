import React, {useEffect} from 'react';
import {Box, styled, css} from "@mui/material";
import ButtonColorSwitch from "./ButtonColorSwitch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {
  selectDesignsBySportAndGarmentId,
  setSelectedColorIndex,
  setSelectedColorName,
  setSelectedPatternColorScheme
} from "../../../../store/appSlice";
import {BREAKPOINTS, COLOR_MENU_DISPLAY} from "../../../../constants";
import getMaxNumberOfLayers from "../../../../utils/getMaxNumberOfLayers";

interface ILayoutSwitcherProps {
  dropdown: string;
}

const LayoutSwitcher = styled(Box)<ILayoutSwitcherProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 28px 0 0 25px;
  transition: .3s;
  
  @media all and (max-width: ${BREAKPOINTS.md}) {
    margin: 20px 0 0 15px;
  }
  
  ${props => props.dropdown === 'true' && css`
    @media all and (max-width: ${BREAKPOINTS.sm}) {
      gap: 8px;
      margin: 0;
    }
  `}
`

interface IProps {
  dropdown?: boolean;
  open: boolean;
  setOpen: (value?: boolean) => void;
}

/**
 * Используется в обеих версиях: desktop и mobile
 * @param dropdown
 * @param open
 * @param setOpen
 * @constructor
 */

const ColorSwitcher = ({ dropdown, open, setOpen }: IProps) => {
  const dispatch = useAppDispatch();

  const baseColorIds = useAppSelector(state => state.config.baseColorIds);
  const selectedColorIndex = useAppSelector(state => state.app.selectedColorIndex);
  const patternColorScheme = useAppSelector((state) => state.config.patternColorScheme);
  const selectedPatternColorScheme = useAppSelector((state) => state.app.selectedPatternColorScheme);
  const colors = useAppSelector((state) => state.app.colors);
  const sportId = useAppSelector((state) => state.config.sportId);
  const patternId = useAppSelector((state) => state.config.patternId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, sportId, garmentIds));
  const layers = getMaxNumberOfLayers(filteredDesigns.filter((k) => k.pattern === patternId));

  useEffect(() => {
    dispatch(setSelectedColorName('Main'));
    dispatch(setSelectedColorIndex(0));
    dispatch(setSelectedPatternColorScheme(null));
  }, []);

  const handleSwitcherClick = (colorName: string, index: number) => {
    dispatch(setSelectedColorName(colorName));
    dispatch(setSelectedColorIndex(index));
    dispatch(setSelectedPatternColorScheme(null));
    setOpen();
  };

  const handleSubSwitcherClick = (code: string, index: number) => {
    dispatch(setSelectedColorName('Side panel'));
    dispatch(setSelectedColorIndex(index));
    dispatch(setSelectedPatternColorScheme(code));
    setOpen();
  };

  return (
    <LayoutSwitcher
      dropdown={String(dropdown)}
    >
      {
        COLOR_MENU_DISPLAY
          .map((item, index) => {
            if (item.value === 'Side panel') {
              return patternColorScheme.map((k, patternIndex) => {
                if (patternIndex >= layers) {
                  // не отображать больше Side panel, чем есть слоев у паттерна
                  return null;
                }

                if (dropdown && !open && k.code !== selectedPatternColorScheme) {
                  // скрывать невыбранные подпункты, если мы в режиме dropdown, и меню скрыто
                  return null;
                }

                const rgb = colors.find((d) => d.id === k.colorId)?.rgb || '';
                return <ButtonColorSwitch
                  key={k.code}
                  id={k.colorId}
                  title={k.display}
                  rgb={rgb}
                  selected={k.code === selectedPatternColorScheme}
                  handleClick={() => handleSubSwitcherClick(k.code, index)}
                  dropdown={dropdown}
                />;
              });
            }

            if (dropdown && !open && index !== selectedColorIndex) {
              // скрывать невыбранные подпункты, если мы в режиме dropdown, и меню скрыто
              return null;
            }

            return <ButtonColorSwitch
              key={item.value}
              title={item.display}
              id={baseColorIds[item.value]?.id}
              rgb={baseColorIds[item.value]?.rgb || ''}
              selected={index === selectedColorIndex}
              handleClick={() => handleSwitcherClick(item.value, index)}
              dropdown={dropdown}
            />
          })
      }
    </LayoutSwitcher>
  );
};

export default ColorSwitcher;
