import React from 'react';
import LayoutContainer from "./LayoutContainer";
import {Box, styled} from "@mui/material";
import ButtonMenuSport from "../../UI/ButtonMenuSport";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {setSportId} from "../../../../store/configSlice";
import {ANIMATION_BUTTONS_DELAY, BREAKPOINTS} from "../../../../constants";
import {useSelector} from "react-redux";
import {selectAllSports, resetAppSlice, selectAllGarmentTypes, setShowCanvas} from "../../../../store/appSlice";
import { reset as resetCanvasSlice } from '../../../../store/canvasSlice';
import { reset as resetConfigSlice } from '../../../../store/configSlice';

interface ILayoutProps {
  isfourthmobilepage: string;
}

const Layout = styled(Box)<ILayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    display: block;
    width: 100%;
    margin-top: ${props => props.isfourthmobilepage === 'true' ? "0" : "36px"};
  }
`

interface IDetailedMenuSportProps {
  isFourthMobilePage?: boolean;
}

const LayoutSport = ({ isFourthMobilePage } : IDetailedMenuSportProps) => {
  const dispatch = useAppDispatch();
  const sportList = useSelector(selectAllSports);

  const allGarmentTypes = useSelector(selectAllGarmentTypes);
  const sports = Object.keys(allGarmentTypes);

  const allGarmentTypesNoDisabled = sports.reduce((total, key) => {
    if (!(key in allGarmentTypes) || allGarmentTypes[key].every((sport) => sport.disabled)) {
      return total;
    }
    total[key] = allGarmentTypes[key];
    return total;
  }, {});

  const activeSports = Object.keys(allGarmentTypesNoDisabled);

  const handleClickSport = (id: string) => {
    dispatch(resetAppSlice());
    dispatch(resetConfigSlice());
    dispatch(resetCanvasSlice());
    dispatch(setSportId(id));
    dispatch(setShowCanvas(false));
  }

  return (
    <LayoutContainer isFourthMobilePage={isFourthMobilePage}>
      <Layout isfourthmobilepage={String(isFourthMobilePage)}>
        {
          sportList.map((sport, index) => <ButtonMenuSport
            key={sport.id}
            handleClick={() => handleClickSport(sport.id)}
            delay={ANIMATION_BUTTONS_DELAY * index + 's'}
            disabled={!activeSports.includes(sport.code)}
          >
            {sport.title}
          </ButtonMenuSport>)
        }
      </Layout>
    </LayoutContainer>
  );
};

export default LayoutSport;
