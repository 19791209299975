import {uniq} from "lodash";

/**
 * Очистить массив от одинаковых и falsy значений
 * @param arr
 */

const cleanArray = <T>(arr: T[]): T[] => {
  return uniq(arr.filter((k) => k));
}

export default cleanArray;
