import React, {useRef} from 'react';
import {styled, Box} from "@mui/material";
import clearFileInput from "../../../utils/clearFileInput";
import upload from '../../../assets/img/UI/upload.svg';
import remove from '../../../assets/img/UI/remove.svg';
import {RemoveIcon} from "./styles";

interface WrapperProps {
  hasvalue: string;
  stickystyle: string;
}

const Wrapper = styled(Box)<WrapperProps>`
  position: relative;
  width: 67px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${props => props.stickystyle === 'true' ? '25px' : '8px'};
  cursor: ${props => props.hasvalue === 'true' ? 'default' : 'pointer'};
`;

const Skew = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  transform: skew(-18deg);
  width: 75px;
  height: 48px;
`;

const Label = styled('label')`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 75px;
  height: 48px;
  transform: skew(-18deg);
  cursor: pointer;
`;

const Input = styled('input')`
  display: none;
`;

const ImgIcon = styled('img')`
  z-index: 2;
  pointer-events: none;
`;

const ImgValue = styled('img')`
  position: absolute;
  z-index: 1;
  max-height: 44px;
`;

const StickyBackground = styled('div')`
  position: absolute;
  left: -25px;
  top: 0;
  background: white;
  width: 48px;
  height: 48px;
  cursor: default;
`;

const IconBox = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface IProps {
  name?: string;
  value?: string;
  onChange: (files: FileList | null) => void;
  onRemove: () => void;
  stickyStyle?: boolean;
  uploadIconPath: string;
}

const AddLogoButton = ({
  name = 'inputFileMenu',
  onChange,
  value,
  onRemove,
  stickyStyle,
  uploadIconPath,
}: IProps) => {
  const inputRef = useRef();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files);
  }

  const clearInput = () => {
    clearFileInput(inputRef.current);
  }

  const handleRemove = () => {
    value && onRemove();
  }

  return (
    <Wrapper
      hasvalue={String(Boolean(value))}
      stickystyle={String(stickyStyle)}
    >
      <Skew />
      {
        value
          ? <RemoveIcon
            onClick={handleRemove}
          >
            <ImgIcon
              src={remove}
              alt='x'
            />
          </RemoveIcon>
          : <IconBox>
            <ImgIcon
              src={uploadIconPath}
              alt=''
            />
            <ImgIcon
              src={upload}
              alt='upload'
            />
          </IconBox>
      }
      {
        value
        ? <ImgValue
            src={value}
            alt=''
            style={{
              maxWidth: stickyStyle ? '67px' : '51px',
            }}
          />
        : <Label
            htmlFor={name}
          >
            <Input
              id={name}
              ref={inputRef}
              type='file'
              accept='image/*'
              onChange={onChangeInput}
              onClick={clearInput}
            />
          </Label>
      }
      {
        stickyStyle
        && <StickyBackground />
      }
    </Wrapper>
  );
};

export default AddLogoButton;
