import React from 'react';
import {Box, styled, SxProps} from "@mui/material";
import {BREAKPOINTS} from "../../../../constants";
import {useSelector} from "react-redux";
import {selectAllColors} from "../../../../store/appSlice";

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;

  .colorSample {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    margin-left: 10px;

    .colorSample {
      width: 20px;
      height: 20px;
    }
  }
`

interface IProps {
  colorIds: string[];
  sx?: SxProps;
}

const ColorsWrap = ({ colorIds, sx }: IProps) => {
  const baseColors = useSelector(selectAllColors);

  return (
    <Wrapper
      sx={sx}
    >
      {
        colorIds.map((id, index) => {
          const data = baseColors.find(item => item.id === id);

          if (!data) {
            return null;
          }

          return <div
            key={index}
            className='colorSample'
            style={{
              background: data.rgb,
              border: data.boxShadow ? '1px solid #D9D9D9' : 'none',
            }}
          />
        })
      }
    </Wrapper>
  );
};

export default ColorsWrap;
