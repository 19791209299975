import {setPatternId, updatePatternColorScheme} from "../store/configSlice";
import useAppSelector from "./useAppSelector";
import {useSelector} from "react-redux";
import {selectAllPatternsBySport, selectDesignsBySportAndGarmentId} from "../store/appSlice";
import useAppDispatch from "./useAppDispatch";

interface ReturnShufflePatterns {
  onShufflePatterns: () => void;
}

const useShufflePatterns = (): ReturnShufflePatterns => {
  const dispatch = useAppDispatch();
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patternId = useAppSelector((state) => state.config.patternId);

  const sportId = useAppSelector((state) => state.config.sportId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, sportId, garmentIds));

  const patternList = useSelector((state) =>
    selectAllPatternsBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const handleShufflePatterns = () => {
    const patternIds = patternList.map((item) => item.id);
    if (patternIds.length < 2) {
      return;
    }
    const index = patternIds.findIndex((k) => k === patternId);
    const nextIndex = index + 1;
    const newId = patternIds[nextIndex] ? patternIds[nextIndex] : patternIds[0];
    dispatch(setPatternId(newId));

    const design = filteredDesigns.filter((k) => k.pattern === newId && k.garmentType === 'jersey')[0];
    dispatch(updatePatternColorScheme(design));
  };

  return {
    onShufflePatterns: handleShufflePatterns,
  }
};

export default useShufflePatterns;
