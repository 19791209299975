import React from 'react';
import {Box, styled, css} from "@mui/material";
import {BREAKPOINTS, PAGES_ORDER_ARR} from "../../constants";
import useAppSelector from "../../hooks/useAppSelector";
import {TPages} from "../../store/types/types";

interface IContainerProps {
  pagename: TPages;
  isdetailedsidebar: string;
}

const pageColorCss = css`
  @media all and (max-width: ${BREAKPOINTS.special}) {
    height: unset;
    padding-top: 180px;
  }
`

const pageGarmentCss = css`
  @media all and (max-width: ${BREAKPOINTS.special}) {
    height: unset;
    padding-top: 110px;
  }
`

const pagePreviewCss = css`
  @media all and (max-width: ${BREAKPOINTS.special}) {
    height: unset;
    padding-top: 110px;
  }
`

const Container = styled(Box)<IContainerProps>`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
  
  ${props => (props.pagename === 'color' || props.pagename === 'decoration') && pageColorCss};
  ${props => props.pagename === 'garment' && pageGarmentCss};
  ${props => props.pagename === 'preview' && pagePreviewCss};
  
  @media all and (max-width: ${BREAKPOINTS.sm}) {
    position: absolute;
    height: unset;
    padding-top: ${props => props.pagename === 'preview' ? '70px' : (props.isdetailedsidebar === 'true' ? '80px' : '15vh')};
  }
`

interface IProps {
  children: React.ReactNode;
}

const CenteredPageContainer = ({ children }: IProps) => {
  const detailedSideBar = useAppSelector(state => state.app.detailedSideBar);
  const pageId = useAppSelector(state => state.app.pageId);
  const pageName = PAGES_ORDER_ARR[pageId];

  return (
    <Container
      pagename={pageName}
      isdetailedsidebar={String(Boolean(detailedSideBar))}
    >
      { children }
    </Container>
  );
};

export default CenteredPageContainer;
