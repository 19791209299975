import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { DataTexture } from "three";
import { Sprite } from "pixi.js";

export enum ResourceType {
  GLTF = "GLTF",
  HDR_TEXTURE = "HDR_TEXTURE",
  SPRITE = "SPRITE",
  IMAGE = "IMAGE",
}

export interface Resource {
  type?: ResourceType;
  url: string;
  error?: Error;
  content?: unknown;
  loaded: boolean;
}

export interface GltfResource extends Resource {
  type: ResourceType.GLTF;
  content?: GLTF;
}
export interface SpriteResource extends Resource {
  type: ResourceType.SPRITE;
  content?: Sprite;
}

export interface HdrTextureResource extends Resource {
  type: ResourceType.HDR_TEXTURE;
  content?: DataTexture;
}

export interface ImageResource extends Resource {
  type: ResourceType.IMAGE;
  content?: string;
}
export type ResourseLoaderParams = {
  url: string;
  resource: Resource;
};

export interface Loader {
  loadResource({ url, resource }: ResourseLoaderParams): Promise<void>;
}
export function isGltfResource(resource: Resource): resource is GltfResource {
  return (resource as GltfResource).type == ResourceType.GLTF;
}

export function isSpriteResource(
  resource: Resource
): resource is SpriteResource {
  return (resource as SpriteResource).type == ResourceType.SPRITE;
}
