export default function clearFileInput(ctrl) {
  try {
    ctrl.value = null;
  } catch(ex) {
    console.log(ex);
  }
  if (ctrl.value) {
    ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
  }
}
