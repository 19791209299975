import React, {useEffect, useRef, useState} from 'react';
import {Box, styled, css} from "@mui/material";
import ButtonSideBar from "../UI/ButtonSideBar";
import useAppSelector from "../../../hooks/useAppSelector";
import {ANIMATION_BUTTONS_DELAY, basicColor, BREAKPOINTS} from "../../../constants";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  setDetailedSideBar,
} from '../../../store/appSlice';
import LayoutSport from "./layouts/LayoutSport";
import LayoutColors from "./layouts/LayoutColors";
import LayoutDecoration from "./layouts/LayoutDecoration";
import LayoutPattern from "./layouts/LayoutPattern";
import ColorsWrap from "./components/ColorsWrap";
import shuffle from '../../../assets/button/shuffle.svg'
import useShufflePatterns from "../../../hooks/useShufflePatterns";
import useShuffleColors from '../../../hooks/useShuffleColors';

interface ILayoutProps {
  singlebutton: string;
}

const singleButtonCss = css`
  top: 50vh;
  padding-top: 0;

  @media all and (max-width: ${BREAKPOINTS.special}) {
    padding-top: 0;
  }
`

const Layout = styled(Box)<ILayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  padding-top: 30vh;

  .wrapper {
    display: flex;
  }

  .shuffle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    width: 88px;
    height: 56px;
    -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
    -moz-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
    clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
    cursor: pointer;
  }

  @media all and (max-width: ${BREAKPOINTS.special}) {
    position: relative;
    width: min-content;
  }
  
  ${props => props.singlebutton === 'true' ? singleButtonCss : null}
`

interface IProps {
  buttonsCount: number;
}

const SideBarDesktop = ({ buttonsCount }: IProps) => {
  // buttonsCount = page id

  const dispatch = useAppDispatch();
  const LayoutRef = useRef(null);
  const [update, setUpdate] = useState<boolean>(true);

  const colorIds = useAppSelector(state => state.config.baseColorIds);
  const detailedSideBar = useAppSelector(state => state.app.detailedSideBar);
  const colorOrderIds = basicColor.map((item) => colorIds[item]?.id);

  const { onShufflePatterns } = useShufflePatterns();
  const { onShuffle } = useShuffleColors();

  useEffect(() => {
    // update animation
    if (LayoutRef.current) {
      LayoutRef.current.style.opacity = '0';
    }
    setUpdate(false);
  }, [buttonsCount])

  useEffect(() => {
    // update animation
    if (LayoutRef.current) {
      LayoutRef.current.style.opacity = '1';
    }
    setUpdate(true);
  }, [update])

  if (buttonsCount === 5) {
    return null;
  }

  const handleClickSport = () => {
    dispatch(setDetailedSideBar('sport'));
  }

  const handleClickColor = () => {
    dispatch(setDetailedSideBar('color'));
  }

  const handleClickDecoration = () => {
    dispatch(setDetailedSideBar('decoration'));
  }

  const handleClickPattern = () => {
    dispatch(setDetailedSideBar('pattern'));
  }

  const renderDetailedMenu = () => {
    switch (detailedSideBar) {
      case 'sport': return <LayoutSport />;
      case 'color': return <LayoutColors />;
      case 'decoration': return <LayoutDecoration />;
      case 'pattern': return <LayoutPattern />;
      default: return null;
    }
  }

  // bug fix for the 'color select' page, whe we press the 'Sport' button at sidebar
  const singleButton = buttonsCount < 2 && detailedSideBar === null;

  const handleShuffleColors = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onShuffle();
  };

  return (
    <Layout
      ref={LayoutRef}
      singlebutton={String(singleButton)}
    >
      {
        update && !detailedSideBar &&
        <>
          <ButtonSideBar
            handleClick={handleClickSport}
          >
              Sport
          </ButtonSideBar>
          {buttonsCount > 1 && (
            <div className='wrapper'>
              <ButtonSideBar handleClick={handleClickColor}>
                <ColorsWrap colorIds={colorOrderIds} />
              </ButtonSideBar>
              {buttonsCount > 3 && (
                <>
                  <div
                    className='shuffle'
                    onClick={handleShuffleColors}
                  >
                    <img src={shuffle} alt=''/>
                  </div>
                </>
              )}
            </div>
          )}
          {
            buttonsCount > 3 &&
              <ButtonSideBar
                handleClick={handleClickDecoration}
                delay={ANIMATION_BUTTONS_DELAY * 2 + 's'}
              >
                Decoration
              </ButtonSideBar>
          }
          {buttonsCount > 3 && (
            <div className='wrapper'>
              <ButtonSideBar
                handleClick={handleClickPattern}
                delay={ANIMATION_BUTTONS_DELAY * 3 + 's'}
              >
                Pattern
              </ButtonSideBar>
              <div
                className='shuffle'
                onClick={onShufflePatterns}
              >
                <img src={shuffle} alt=''/>
              </div>
            </div>
          )}
        </>
      }
      {
        renderDetailedMenu()
      }
    </Layout>
  );
};

export default SideBarDesktop;
