import React from "react";
import { useEffect } from "react";
import { loadConfig, loadFonts } from "./store/appSlice";
import useAppSelector from "./hooks/useAppSelector";
import MainPage from "./pages/MainPage";
import useAppDispatch from "./hooks/useAppDispatch";

const App = () => {
  const sportsLength = useAppSelector((state) => state.app.sports).length;
  const fontsLoading = useAppSelector((state) => state.app.fontsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadFonts());
  }, []);

  return sportsLength && !fontsLoading ? <MainPage /> : null;
};

export default App;
