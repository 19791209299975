import React from 'react';
import {Box, styled} from "@mui/material";
import ButtonPatternMobile from "../../UI/ButtonPatternMobile";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {setPatternId, updatePatternColorScheme} from "../../../../store/configSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import {useSelector} from "react-redux";
import {selectAllPatternsBySport, selectDesignsBySportAndGarmentId} from "../../../../store/appSlice";
import ButtonPatternLoader from "../../UI/ButtonPatternLoader";

const Wrapper = styled(Box)`
  display: flex;
  gap: 10px;
`

const PatternSelector = () => {
  const sports = useAppSelector((state) => state.app.sports);
  const screenshots = useAppSelector((state) => state.canvas.screenshots);
  const loading = useAppSelector((state) => state.canvas.loading);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const patternList = useSelector((state) => selectAllPatternsBySport(state, sports.find(s=>s.id === selectedSportId)));
  const dispatch = useAppDispatch();

  const currentId = useAppSelector(state => state.config.patternId);
  const garmentIds = useAppSelector(state => state.canvas.selectedGarmentIds);
  const filteredDesigns = useAppSelector((state) => selectDesignsBySportAndGarmentId(state, selectedSportId, garmentIds));

  const handleClickPattern = (id: string) => {
    dispatch(setPatternId(id));
    const design = filteredDesigns.filter((k) => k.pattern === id && k.garmentType === 'jersey')[0];
    dispatch(updatePatternColorScheme(design));
  }

  return (
    <Wrapper>
      {
        patternList.map((item, index) => {
          const src = screenshots[item.id]?.src;
          if (src && !loading) {
            return <ButtonPatternMobile
              key={item.id + index}
              selected={item.id === currentId}
              src={src}
              onClick={() => handleClickPattern(item.id)}
            />;
          } else {
            return <ButtonPatternLoader
              key={index}
            />;
          }
        })
      }
    </Wrapper>
  );
};

export default PatternSelector;
