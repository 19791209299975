import {IGarment} from "../store/types/designTypes";

const getMaxNumberOfLayers = (designs?: IGarment[]): number => {
  if (!designs || !designs.length) {
    return 0;
  }

  const countArray: number[] = designs.map((k) => {
    return k.config.default.layers.length;
  });
  return Math.max(...countArray);
}

export default getMaxNumberOfLayers;
