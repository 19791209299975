import {Box, styled} from "@mui/material";
import {BREAKPOINTS} from "../../../constants";

export default styled(Box)`
  .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;

    > button {
      background: none;
      transition: 0.3s;

      img {
        width: 100%;
      }

      &:hover {
        opacity: 0.6;
      }

      &.prev {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .viewport {
      width: 470px;
      height: 470px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .dotsContainer {
    width: 100%;

    .dotsFlex {
      display: flex;
      gap: 14px;
      width: max-content;
      margin: 0 auto;
    }
  }

  @media all and (max-width: ${BREAKPOINTS.lg}) {
    .slider {
      margin-top: 15px;
      gap: 20px;

      > button {
        width: 30px;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.md}) {
    .slider {
      .viewport {
        width: 470px;
        height: 470px;
      }
    }
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .slider {
      width: 100%;
      justify-content: space-between;

      > button {
        width: 70px;
        height: 56px;
        background: #ffffff;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);

        img {
          width: 8px;
        }

        &.next {
          clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
        }
      }

      .viewport {
        max-width: 90%;
        height: 290px;
        max-height: 40vh;
      }
    }

    .dotsContainer {
      margin-top: 20px;
    }
  }
`;
