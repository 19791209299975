import {TPages} from "./store/types";
import {TColorDisplay} from "./types";

export const COLORS = {
  textDarkGrey: '#333333',
  textGrey: '#F2F2F2',
  boxBg: '#292A2C',
  greyBg: '#C4C4C4',
  border: '#D9D9D9',
}

export const BREAKPOINTS = {
  lg: '1919px',
  md: '1366px',
  special: '1024px',
  sm: '768px',
  xs: '360px',
}

export const PAGES_ORDER_ARR: TPages[] = [
  'sport',
  'color',
  'decoration',
  'garment',
  'pattern',
  'preview',
];

export const ANIMATION_BUTTONS_DELAY = 0.1;
export const COLOR_PICKER_WIDTH = 35;
export const COLOR_PICKER_GAP = 3;

export const PATH_SPORT_ICONS = '/assets/img/sportIcons/';

export const colorNames = ['Main', 'Side panel', 'Accent1', 'Text', 'Number', 'Text outline', 'Number outline'] as const;
export const basicColor = ['Main', 'Side panel', 'Accent1'];

export const COLOR_MENU_DISPLAY: TColorDisplay[] = [
  {
    value: 'Main',
    display: 'Main',
  },
  {
    value: 'Side panel',
    display: 'Side panel',
  },
  {
    value: 'Text',
    display: 'Text/Number',
  },
  {
    value: 'Text outline',
    display: 'Text/Number outline',
  },
];

export const DEFAULT_TEXT_CODE = 'shirt_text_1';
export const DEFAULT_LOGO_CODE = 'shirt_logo_1';

export const SITE_LINK = 'https://www.323sports.com/';

export const SHOW_COLOR_IDS = false;
