import {styled, Typography} from "@mui/material";

const TextError = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #EB5757;
`;

export default TextError;
