import React from 'react';
import {styled} from "@mui/material";

const Wrapper = styled('button')`
  height: 13vw;
  width: 13vw;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const ButtonContainer = ({ children, onClick }: IProps) => {
  return (
    <Wrapper
      onClick={() => onClick && onClick()}
    >
      { children }
    </Wrapper>
  );
};

export default ButtonContainer;
