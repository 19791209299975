import React, {useRef} from 'react';
import {Box, styled} from "@mui/material";
import clearFileInput from "../../../utils/clearFileInput";
import upload from "../../../assets/img/UI/upload.svg";
import {RemoveIcon} from "./styles";
import remove from "../../../assets/img/UI/remove.svg";

interface WrapperProps {
  hasvalue: string;
  background: string;
}

const Wrapper = styled(Box)<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${props => props.hasvalue === 'true' ? 'white' : '#F6F7F9'};
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  border: 1px solid ${props => props.hasvalue === 'true' ? '#DDDDDD' : '#F6F7F9'};
`;

const Label = styled('label')`
  width: 100%;
  height: 40px;
  position: absolute;
  cursor: pointer;
`;

const Input = styled('input')`
  display: none;
`;

const IconBox = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface IProps {
  name?: string;
  value?: string;
  onChange: (files: FileList | null) => void;
  onRemove: () => void;
  stickyStyle?: boolean;
  uploadIconPath: string;
}

const AddLogoButtonMobile = ({
  name = 'inputFileMenu',
  onChange,
  value,
  onRemove,
  uploadIconPath,
}: IProps) => {
  const inputRef = useRef();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files);
  }

  const clearInput = () => {
    clearFileInput(inputRef.current);
  }

  const handleRemove = () => {
    value && onRemove();
  }

  return (
    <Wrapper
      hasvalue={String(Boolean(value))}
      background={value}
    >
      {
        value
          ? <RemoveIcon
            onClick={handleRemove}
          >
            <img
              src={remove}
              alt='x'
            />
          </RemoveIcon>
          : <IconBox>
            <img
              src={uploadIconPath}
              alt=''
            />
            <img
              src={upload}
              alt='upload'
            />
          </IconBox>
      }
      {
        !value
        && <Label
              htmlFor={name}
          >
              <Input
                  id={name}
                  ref={inputRef}
                  type='file'
                  accept='image/*'
                  onChange={onChangeInput}
                  onClick={clearInput}
              />
          </Label>
      }
    </Wrapper>
  );
};

export default AddLogoButtonMobile;
