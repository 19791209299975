import React from 'react';
import {styled} from "@mui/material";
import {COLORS} from "../../../constants";
import shuffle from '../../../assets/button/shuffleMobile.svg'
import useShuffleColors from '../../../hooks/useShuffleColors';

const Wrapper = styled('button')`
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width: 117px;
  height: 56px;
  position: fixed;
  bottom: 13vh;
  left: 0;
  z-index: 5;
  clip-path: polygon(0 0, 100% 0, 78% 100%, 0 100%);
  
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.textDarkGrey};
  
  .text {
    margin-left: 10px;
  }
`;

const ButtonShuffleColors = () => {
  const { onShuffle } = useShuffleColors();

  return (
    <Wrapper
      onClick={onShuffle}
    >
      <img src={shuffle} alt=''/>
      <div className='text'>Colors</div>
    </Wrapper>
  );
};

export default ButtonShuffleColors;
