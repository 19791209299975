import React from "react";

export const ChevronButtonRight = () =>
  <svg width="6" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.54023 6.29004L1.88023 0.640037C1.78726 0.546308 1.67666 0.471914 1.5548 0.421145C1.43294 0.370377 1.30224 0.344238 1.17023 0.344238C1.03821 0.344238 0.907509 0.370377 0.785649 0.421145C0.66379 0.471914 0.553189 0.546308 0.460226 0.640037C0.273975 0.827399 0.169434 1.08085 0.169434 1.34504C0.169434 1.60922 0.273975 1.86267 0.460226 2.05004L5.41023 7.05004L0.460226 12C0.273975 12.1874 0.169434 12.4409 0.169434 12.705C0.169434 12.9692 0.273975 13.2227 0.460226 13.41C0.552841 13.5045 0.663286 13.5797 0.785161 13.6312C0.907036 13.6827 1.03792 13.7095 1.17023 13.71C1.30253 13.7095 1.43342 13.6827 1.55529 13.6312C1.67717 13.5797 1.78761 13.5045 1.88023 13.41L7.54023 7.76004C7.64173 7.66639 7.72274 7.55274 7.77815 7.42624C7.83355 7.29974 7.86216 7.16314 7.86216 7.02504C7.86216 6.88693 7.83355 6.75033 7.77815 6.62383C7.72274 6.49733 7.64173 6.38368 7.54023 6.29004Z" fill="#333333"/>
  </svg>

export const UploadSvg = () =>
  <svg width="61" height="45" viewBox="0 0 61 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.9583 28.125H33.0416V16.875H40.6666L30.4999 7.5L20.3333 16.875H27.9583V28.125Z" fill="white"/>
    <path d="M50.8335 35.25H10.1668V22.125H5.0835V35.25C5.0835 37.3181 7.36337 39 10.1668 39H50.8335C53.637 39 55.9168 37.3181 55.9168 35.25V22.125H50.8335V35.25Z" fill="white"/>
  </svg>

export const PrevArrowSvg = () =>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.8541 18.7499H6.25716L13.7568 11.2503L11.989 9.48257L1.47162 20L11.989 30.5174L13.7568 28.7497L6.25701 21.2499H38.8541V18.7499Z" fill="#333333"/>
  </svg>

export const HideButtonPlus = () =>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7676 3.71094H13.2324C13.3626 3.71094 13.4277 3.77604 13.4277 3.90625V21.0938C13.4277 21.224 13.3626 21.2891 13.2324 21.2891H11.7676C11.6374 21.2891 11.5723 21.224 11.5723 21.0938V3.90625C11.5723 3.77604 11.6374 3.71094 11.7676 3.71094Z" fill="white"/>
    <path d="M4.29688 11.5723H20.7031C20.8333 11.5723 20.8984 11.6374 20.8984 11.7676V13.2324C20.8984 13.3626 20.8333 13.4277 20.7031 13.4277H4.29688C4.16667 13.4277 4.10156 13.3626 4.10156 13.2324V11.7676C4.10156 11.6374 4.16667 11.5723 4.29688 11.5723Z" fill="white"/>
  </svg>

export const SubmitSend = () =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 2L11 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
