import {useEffect, useState} from "react";
import {setSelectedGarmentAllIds} from "../store/canvasSlice";
import useSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";

interface IReturnDecorationControl {
  currentGarmentId: string | null;
  onGarmentClick: (id: string | null) => void;
}

export const useDecorationControl = (): IReturnDecorationControl => {
  const [currentGarmentId, setCurrentGarmentId] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const garmentIds = useSelector((state) => state.config.garmentIds);

  const onGarmentClick = (id: string | null) => {
    setCurrentGarmentId(id);
    dispatch(setSelectedGarmentAllIds([id]));
  }

  useEffect(() => {
    const initialId = garmentIds[0];
    // массив со всеми гарментами, выбранными на предыдущем этапе
    setCurrentGarmentId(initialId);
    // управляем верхним правым меню, и заодно перезагружается модель
    dispatch(setSelectedGarmentAllIds([initialId]));
  }, [garmentIds]);

  return {
    currentGarmentId,
    onGarmentClick,
  }
}
