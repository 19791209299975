import React from 'react';
import {styled} from "@mui/material";
import {BREAKPOINTS} from "../../constants";
import logoImg from "../../assets/img/logos/logo.svg";

const Wrapper = styled('img')`
  width: 116px;
  user-select: none;

  @media all and (max-width: ${BREAKPOINTS.md}) {
    width: 70px;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    width: 58px;
  }
`

const Logo = () => <Wrapper src={logoImg} alt='GoodGame' />;

export default Logo;
