import React from 'react';
import { Drawer } from "@mui/material";
import {BREAKPOINTS, COLORS} from "../../constants";

interface IProps {
  handleClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
}

const MenuDrawer = ({ children, handleClose, isOpen }: IProps) => {
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleClose}
      transitionDuration={isOpen ? 500 : 0}
      ModalProps={{
        sx: {
          '.MuiBackdrop-root': {
            background: 'none',
          },
        }
      }}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          width: '50%',
          background: COLORS.boxBg,
          [`@media all and (max-width: ${BREAKPOINTS.special})`]: {
            width: '100%',
          }
        }
      }}
    >
      { children }
    </Drawer>
  );
};

export default MenuDrawer;
