import React, {ReactElement} from 'react';
import TextError from "../Typography/TextError";
import {Box, styled, css} from "@mui/material";

interface IProps {
  children: ReactElement;
  error?: string | null;
}

interface IWrapperProps {
  iserror: string;
}

const Wrapper = styled(Box)<IWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  
  ${props => props.iserror === 'true' && css`
    margin-bottom: 4px;
    
    .MuiInputBase-root {
      border: 1px solid #EB5757;
    }
  `}
`;

const FormInputRow = ({ children, error }: IProps) => {
  return (
    <Wrapper
      iserror={String(Boolean(error))}
    >
      { children }
      {
        error
        ? <TextError>
            { error }
          </TextError>
          : null
      }
    </Wrapper>
  );
};

export default FormInputRow;
