import React from 'react';
import {Box, styled} from "@mui/material";
import useAppSelector from "../../../hooks/useAppSelector";
import ButtonColors from "./ButtonColors";
import ButtonContainer from "./ButtonContainer";
import ttSvg from '../../../assets/img/UI/tt.svg';
import patternSvg from '../../../assets/img/UI/pattern-mob.svg';
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  setDetailedSideBar,
} from "../../../store/appSlice";
import {backupValues} from "../../../store/configSlice";
import ShareButton from "../../../pages/Preview/ShareButton";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4vw;
  pointer-events: all;
`

const MobilePanel = () => {
  const pageId = useAppSelector(state => state.app.pageId);

  const dispatch = useAppDispatch();

  const handleClickColors = () => {
    if (pageId === 4) {
      dispatch(backupValues());
    }
    dispatch(setDetailedSideBar('color'));
  }

  const handleClickDecoration = () => {
    if (pageId === 4) {
      dispatch(backupValues());
    }
    dispatch(setDetailedSideBar('decoration'));
  }

  const handleClickPattern = () => {
    dispatch(backupValues());
    dispatch(setDetailedSideBar('pattern'));
  }

  return (
    <Wrapper>
      {
        pageId === 5 ?
          <ShareButton /> :
          <>
            {
              pageId > 1 &&
                <ButtonContainer
                  onClick={handleClickColors}
                >
                    <ButtonColors />
                </ButtonContainer>
            }
            {
              pageId > 3 &&
                <ButtonContainer
                  onClick={handleClickDecoration}
                >
                    <img src={ttSvg} alt='Tt'/>
                </ButtonContainer>
            }
            {
              pageId > 3 &&
                <>
                    <ButtonContainer
                        onClick={handleClickPattern}
                    >
                        <img src={patternSvg} alt='Pattern'/>
                    </ButtonContainer>
                </>
            }
          </>
      }
    </Wrapper>
  );
};

export default MobilePanel;
