import React from 'react';
import {styled, SxProps, Typography} from "@mui/material";
import {BREAKPOINTS} from '../../constants';

const Wrapper = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  
  @media all and (max-width: ${BREAKPOINTS.lg}) {
    font-size: 16px;
  }
`

interface IProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const TextHeading5 = ({ children, sx }: IProps) => {
  return (
    <Wrapper
      variant='h5'
      sx={sx}
    >
      { children }
    </Wrapper>
  );
};

export default TextHeading5;
