import {styled, TextField} from "@mui/material";
import {BREAKPOINTS} from "../../constants";

const InputText = styled(TextField)`
  width: 100%;
  height: 52px;
  
  .MuiInputBase-root {
    clip-path: polygon(18px 0, 100% 0, calc(100% - 18px) 100%, 0% 100%);
    background: black;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    
    .MuiInputBase-input {
      box-sizing: border-box;
      background: white;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      clip-path: polygon(18px 0, 100% 0, calc(100% - 17.5px) 100%, .5px 100%);
      text-align: center;
      font-size: 18px;
      padding: 0 30px;
      
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
    }
  }

  fieldset {
    border: none;
  }

  @media all and (max-width: ${BREAKPOINTS.sm}) {
    .MuiInputBase-root {
      .MuiInputBase-input {
        font-size: 18px !important;
      }
    }
  }
`

export default InputText;
