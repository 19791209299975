import React from 'react';
import LayoutContainer from "./LayoutContainer";
import {Box, IconButton, styled, useMediaQuery} from "@mui/material";
import useDecorationForm from "../../../../hooks/useDecorationForm";
import InputTextMenu from "../../UI/InputTextMenu";
import {BREAKPOINTS} from "../../../../constants";
import ButtonDecorationModel from "../../UI/ButtonDecorationModel";
import useSelector from "../../../../hooks/useAppSelector";
import {selectAllGarmentTypesBySport} from "../../../../store/appSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import svgPlus from '../../../../assets/img/UI/decorationAdd.svg';
import svgMinus from '../../../../assets/img/UI/decorationRemove.svg';
import {InputBox, LogosWrap, ModelButtonsBox} from '../styles';
import AddLogoButton from "../../UI/AddLogoButton";
import {useDecorationControl} from "../../../../hooks/useDecorationControl";

const Layout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 16px;
  margin-bottom: 16px;

  @media all and (max-width: ${BREAKPOINTS.special}) {
    align-items: center;
  }
`;

const LayoutDecoration = () => {
  const isLaptop = useMediaQuery(`(max-width: ${BREAKPOINTS.special})`);

  const { currentGarmentId, onGarmentClick } = useDecorationControl();

  const {
    formData: { selectedGarmentLogos, selectedGarmentTexts },
    actions: {
      onChangeNumber,
      onRemovePreview,
      onTextChange,
      onLogoChange,
      onRemoveTextClick,
      onAddTextClick,
    },
  } = useDecorationForm();

  const texts = useAppSelector((state) => state.config.texts);
  const number = useAppSelector((state) => state.config.number);
  const logos = useAppSelector((state) => state.config.logos);
  const garmentIds = useSelector((state) => state.config.garmentIds);
  const selectedGarmentIds = useSelector((state) => state.canvas.selectedGarmentIds);
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );

  const onTextChangeHandler = (code: string) => {
    return (e) => {
      onTextChange(code, e.target.value);
    }
  }

  const onLogoChangeHandler = (code: string) => {
    return (files) => {
      onLogoChange(code, files);
    }
  }

  /*
  !!! ВАЖНО !!!

  Если что-то здесь правите, то проследите, чтобы логика отображения
  была такой же, как в компоненте DecorationSelector
   */

  return (
    <LayoutContainer title='Decoration'>
      <Layout>

        <ModelButtonsBox>
          {garmentList
            .filter((item) => garmentIds.includes(item.id))
            .map((k) => <ButtonDecorationModel
              key={k.id}
              isSelected={k.id === currentGarmentId}
              onClick={() => onGarmentClick(k.id)}
              imgSrc={k.icon}
            />)
          }
        </ModelButtonsBox>

        {Object.entries(texts).map((text, index, arr) => {
          if (index >= selectedGarmentTexts.length) {
            // полей не может быть больше, чем длины массива selectedGarmentTexts
            return null;
          }
          const code = text[0];
          const value = text[1];
          const name = selectedGarmentTexts.find((k) => k.code === code)?.name || code;

          return <InputBox key={code}>
            <InputTextMenu
                id={code}
                placeholder={`Enter ${name}`}
                value={value || ''}
                inputProps={{
                  maxLength: 30,
                }}
                onChange={onTextChangeHandler(code)}
            />
            {
              index > 0 ?
                // кнопка "минус" рендерится на всех текстах, кроме первого
                <IconButton
                  disableRipple
                  onClick={() => onRemoveTextClick(code)}
                >
                  <img
                    src={svgMinus}
                    alt='-'
                  />
                </IconButton>
              : (arr.length < selectedGarmentTexts.length && <IconButton
                  // кнопку "плюс" рендерим только если конфиг позволяет добавлять больше текстов
                disableRipple
                onClick={onAddTextClick}
              >
                <img
                  src={svgPlus}
                  alt='+'
                />
              </IconButton>)
            }
          </InputBox>
        })}

        {
          selectedGarmentIds.length === 1 && selectedGarmentIds[0] === '4'
            // не отображать номер, если выбраны только шорты
          ? null
          : <InputBox>
            <InputTextMenu
              id='numberMenu'
              placeholder='XX'
              type='number'
              value={number}
              onChange={onChangeNumber}
            />
          </InputBox>
        }

        <LogosWrap>
          {selectedGarmentLogos.map((logo, index) => {
            const code = logo.code;
            return <AddLogoButton
              key={code}
              name={code}
              value={logos[code]}
              onChange={onLogoChangeHandler(code)}
              onRemove={() => onRemovePreview(code)}
              stickyStyle={!isLaptop && index % 2 === 0}
              uploadIconPath={logo.iconPath}
            />
          })}
        </LogosWrap>

      </Layout>
    </LayoutContainer>
  );
};

export default LayoutDecoration;
