import React from 'react';
import closeSvg from '../../assets/img/UI/close-mob.svg';
import useAppDispatch from "../../hooks/useAppDispatch";
import {setModal} from "../../store/appSlice";
import FormContacts from "../../pages/Preview/FormColumn/FormContacts";
import {ContactUsWrapper} from "./style";

const ContactUs = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setModal(null));
  }

  return (
    <ContactUsWrapper>
      <button
        onClick={handleClose}
      >
        <img src={closeSvg} alt='X'/>
      </button>

      <div className='formWrap'>
        <FormContacts />
      </div>
    </ContactUsWrapper>
  );
};

export default ContactUs;
