import React from 'react';
import {Box, styled} from "@mui/material";
import useDecorationForm from "../../../../hooks/useDecorationForm";
import FormInput from '../../UI/FormInput';
import useAppSelector from "../../../../hooks/useAppSelector";
import {MobileInputButton, MobileInputRow, MobileLogosWrap} from "../styles";
import svgMinus from "../../../../assets/img/UI/decorationRemove.svg";
import svgPlus from "../../../../assets/img/UI/decorationAdd.svg";
import AddLogoButtonMobile from "../../UI/AddLogoButtonMobile";
import useSelector from "../../../../hooks/useAppSelector";

const Layout = styled(Box)`
  width: 100%;
`;

const DecorationSelector = () => {
  const texts = useAppSelector((state) => state.config.texts);
  const number = useAppSelector((state) => state.config.number);
  const logos = useAppSelector((state) => state.config.logos);
  const selectedGarmentIds = useSelector((state) => state.canvas.selectedGarmentIds);

  const {
    formData: { selectedGarmentLogos, selectedGarmentTexts },
    actions: {
      onChangeNumber,
      onRemovePreview,
      onTextChange,
      onLogoChange,
      onRemoveTextClick,
      onAddTextClick,
    },
  } = useDecorationForm();

  const onTextChangeHandler = (code: string) => {
    return (e) => {
      onTextChange(code, e.target.value);
    }
  }

  const onLogoChangeHandler = (code: string) => {
    return (files) => {
      onLogoChange(code, files);
    }
  }

  /*
  !!! ВАЖНО !!!

  Если что-то здесь правите, то проследите, чтобы логика отображения
  была такой же, как в компоненте LayoutDecoration
   */

  return (
    <Layout className='inputWrap'>
      {
        Object.entries(texts).map((text, index, arr) => {
          if (index >= selectedGarmentTexts.length) {
            // полей не может быть больше, чем длины массива selectedGarmentTexts
            return null;
          }

          const code = text[0];
          const value = text[1];
          const name = selectedGarmentTexts.find((k) => k.code === code)?.name || code;
          return <MobileInputRow key={code}>
            {
              index > 0 ?
                // кнопка "минус" рендерится на всех текстах, кроме первого
                <MobileInputButton
                  disableRipple
                  onClick={() => onRemoveTextClick(code)}
                >
                  <img
                    src={svgMinus}
                    alt='-'
                  />
                </MobileInputButton>
                : (arr.length < selectedGarmentTexts.length && <MobileInputButton
                  // кнопку "плюс" рендерим только если конфиг позволяет добавлять больше текстов
                      disableRipple
                      onClick={() => onAddTextClick()}
                  >
                      <img
                          src={svgPlus}
                          alt='+'
                      />
                  </MobileInputButton>)
            }
            <FormInput
              placeholder={name}
              value={value || ''}
              onChange={onTextChangeHandler(code)}
            />
          </MobileInputRow>
        })
      }

      {
        selectedGarmentIds.length === 1 && selectedGarmentIds[0] === '4'
          // не отображать номер, если выбраны только шорты
          ? null
          : <FormInput
            placeholder='XX'
            value={number}
            onChange={onChangeNumber}
            sx={{ marginBottom: '16px' }}
          />
      }

      <MobileLogosWrap>
        {
          selectedGarmentLogos.map(item => <AddLogoButtonMobile
            key={item.code}
            value={logos[item.code] || ''}
            onChange={onLogoChangeHandler(item.code)}
            onRemove={() => onRemovePreview(item.code)}
            uploadIconPath={item.iconPath}
          />)
        }
      </MobileLogosWrap>
    </Layout>
  );
};

export default DecorationSelector;
