import { DataTexture } from "three";
import { Sprite } from "pixi.js";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import {
  Resource,
  ResourceType,
  isSpriteResource,
  Loader,
  ImageResource,
} from "./ResourceTypes";
import { PixiResourcesLoader } from "./PixiResourceLoader";
import { ThreeResourceLoader } from "./ThreeResourceLoader";
import { ImageResourceLoader } from "./ImageResourceLoader";
import { ResourseLoaderParams } from "./ResourceTypes";
interface PromiseFulfilledResult<T> {
  status: "fulfilled";
  value: T;
  reason: Record<string, string>;
}

import {
  HdrTextureResource,
  GltfResource,
  SpriteResource,
} from "./ResourceTypes";

export class ResourceManager {
  private readonly loaders: Record<"image" | "three", Loader> = {
    image: new ImageResourceLoader(),
    three: new ThreeResourceLoader(),
  };

  public getImageByLabel(label: string): string {
    return this.getResourceContentByUrl<ImageResource>(label);
  }

  private readonly assetsLoader: PixiResourcesLoader =
    new PixiResourcesLoader();
  private readonly resources: Record<string, Resource> = {};

  public appendResource(url: string, type: ResourceType, label?: string): void {
    if (!label) label = url;
    if (label in this.resources) return;
    this.resources[label] = {
      loaded: false,
      url,
      type,
    };
  }
  public appendAsset(url: string): void {
    if (url in this.resources) return;
    this.assetsLoader.addImage(url);
    this.resources[url] = {
      loaded: false,
      url,
      type: ResourceType.SPRITE,
    };
  }

  public appendAssetFromData(url: string, data: string): void {
    this.assetsLoader.addImageFromData(url, data);
    this.resources[url] = {
      loaded: false,
      url,
      type: ResourceType.SPRITE,
    };
  }

  public async loadAssets(): Promise<void> {
    await this.assetsLoader.loadImages(this.resources);
  }

  public async loadResources(): Promise<void> {
    return await Promise.allSettled(
      Object.entries(this.resources).map(([url, resource]): Promise<void> => {
        if (!resource.loaded && !isSpriteResource(resource))
          return this.loadResource({ url, resource });
        else return Promise.resolve();
      })
    ).then((values) =>
      console.log(
        values
          .filter((value) => value.status === "rejected")
          .map(
            (value) => (value as PromiseFulfilledResult<void>).reason.message
          )
      )
    );
  }
  private loadResource(params: ResourseLoaderParams): Promise<void> {
    if (params.resource.type === ResourceType.IMAGE) {
      return this.loaders.image.loadResource(params);
    } else {
      return this.loaders.three.loadResource(params);
    }
  }

  private getResourceByUrl<T extends Resource>(url: string): T | undefined {
    const resource = this.resources[url];
    return resource as T;
  }

  private getResourceContentByUrl<T extends Resource>(
    url: string
  ): Required<T>["content"] {
    const resource = this.getResourceByUrl<T>(url);
    return resource?.content;
  }

  public getHdrTextureUrl(url: string): DataTexture {
    return this.getResourceContentByUrl<HdrTextureResource>(url);
  }

  public getGltfByUrl(url: string): GLTF {
    return this.getResourceContentByUrl<GltfResource>(url);
  }
  public getAssetByUrl(url: string): Sprite {
    return this.getResourceContentByUrl<SpriteResource>(url);
  }
  public getAssets(): Record<string, Sprite> {
    const assets: Record<string, Sprite> = {};
    for (const [url, resource] of Object.entries(this.resources)) {
      if (isSpriteResource(resource)) {
        assets[url] = resource.content;
      }
    }
    return assets;
  }
}
