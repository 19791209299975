import React from 'react';
import {IDropdownListRow} from "./DropdownList";
import {ListRowText, ListRowTextDescription, ListRowWrapper} from "./style";
import ColorsWrap from "../../../components/SideBar/Desktop/components/ColorsWrap";
import arrayToString from "../../../utils/arrayToString";

interface IProps {
  rows: IDropdownListRow[];
  isColor?: boolean;
}

const ListRow = ({ rows, isColor }: IProps) => {
  return (
    <ListRowWrapper>
      {
        rows.map((row, index) => {
          return isColor ?
            <ColorsWrap
              key={index}
              colorIds={[row.value1]}
              sx={{
                marginLeft: '0 !important',
              }}
            /> :
            <ListRowText key={index}>
              { row.value1 }
            </ListRowText>
        })
      }
      <ListRowTextDescription>
        ({ arrayToString(rows.map((row) => row.value2)) })
      </ListRowTextDescription>
    </ListRowWrapper>
  );
};

export default ListRow;
