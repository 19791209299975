import * as PIXI from "pixi.js";
import { Resource } from "./ResourceTypes";
import { WebfontLoaderPlugin } from "pixi-webfont-loader";
PIXI.extensions.add({
  type: PIXI.ExtensionType.Loader,
  ref: WebfontLoaderPlugin,
});
PIXI.Loader.shared.add({
  name: "AgencyFB",
  url: "/fonts/AgencyFB/AgencyFB.ttf",
});
export type ImageDict = Record<string, PIXI.Sprite>;

export type ErrorsDict = Record<string, Error>;

export type ResourcesLoaderOptions = {
  useQueryTimestamp?: boolean;
};

export class PixiResourcesLoader {
  private pixiLoader: PIXI.Loader = new PIXI.Loader();

  public addImage(url: string): void {
    if (this.checkUrlAlreadyAdded(url)) return;

    const imageName = url;
    const imageSrc = url;

    this.pixiLoader.add(imageName, imageSrc, {
      loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
      crossOrigin: "anonymous",
    });
  }
  public addImageFromData(url: string, data: string): void {
    const imageName = url;
    if (this.pixiLoader.resources[imageName]) this.pixiLoader.reset();
    this.pixiLoader.add(imageName, data);
  }
  private checkUrlAlreadyAdded(url: string): boolean {
    const resource = this.pixiLoader.resources[url];
    return !!resource;
  }

  private checkAllAssetsAreLoaded(): boolean {
    return Object.values(this.pixiLoader.resources).every((resource) => resource.isComplete);
  }

  public loadImages(resources: Record<string, Resource>): Promise<void> {
    return Promise.resolve()
      .then(() => {
        return PIXI.Loader.shared.load();
      })
      .then(
        () =>
          new Promise<void>((resolve) => {
            if (this.checkAllAssetsAreLoaded()) {
              resolve();
            }
            this.pixiLoader.load(() => {
              Object.entries(this.pixiLoader.resources).forEach(([url, resource]) => {
                resources[url].content = new PIXI.Sprite(resource.texture);
                resources[url].loaded = true;
              });
              resolve();
            });
          })
      );
  }

  public destroy(): void {
    this.pixiLoader.destroy();
  }
}
