import React from "react";
import nextSvg from "../../../assets/img/UI/res-next.svg";
import nextMobileSvg from "../../../assets/img/UI/next.svg";
import { useMediaQuery } from "@mui/material";
import { BREAKPOINTS } from "../../../constants";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { setSelectedPreviewGarmentId } from "../../../store/canvasSlice";
import {
  switchArrayToNextItem,
  switchArrayToPreviousItem,
} from "../../../utils/switchArrayItems";
import SliderDotButton from "./SliderDotButton";
import { useSelector } from "react-redux";
import { selectAllGarmentTypesBySport } from "../../../store/appSlice";
import Canvas3D from "../../../components/Canvas3d/Canvas3d";
import { useEffect } from "react";
import useApp3d from "../../../hooks/useApp3d";
import useAppSelector from "../../../hooks/useAppSelector";
import useApp3dRefCallback from "../../../hooks/useAppRefCallback";
import StyledPreviewSlider from "../styles/StyledPreviewSlider";

interface IProps {
  currentId: string;
  garmentIds: string[];
}

const PreviewSlider = ({ currentId, garmentIds }: IProps) => {
  // const pageId = useAppSelector((state) => state.app.pageId);
  // const pageName = PAGES_ORDER_ARR[pageId];
  const sports = useAppSelector((state) => state.app.sports);
  const selectedSportId = useAppSelector((state) => state.config.sportId);
  const garmentList = useSelector((state) =>
    selectAllGarmentTypesBySport(
      state,
      sports.find((s) => s.id === selectedSportId)
    )
  );
  const app = useApp3d();
  const sceneConfig = useAppSelector((state) => state.app.sceneConfig);

  useEffect(() => {
    return () => {
      if (app) {
        app.destroy();
      }
    };
  }, [app]);

  // const currentGarmentTitle = garmentList.find(item => item.id === currentId)?.title || '';
  // const selectedGarmentIds = useAppSelector(state => state.canvas.selectedGarmentIds)
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);

  const handleClickPrev = () => {
    const newId = switchArrayToPreviousItem(garmentIds, currentId);
    dispatch(setSelectedPreviewGarmentId(newId));
  };

  const handleClickNext = () => {
    const newId = switchArrayToNextItem(garmentIds, currentId);
    dispatch(setSelectedPreviewGarmentId(newId));
  };

  const handleDotClick = (id: string) => {
    dispatch(setSelectedPreviewGarmentId(id));
  };

  return (
    <StyledPreviewSlider>
      <div className="slider">
        <button className="prev" onClick={handleClickPrev}>
          <img src={isMobile ? nextMobileSvg : nextSvg} alt="<<" />
        </button>

        <div className="viewport">
          {
            <Canvas3D
              CanvasElement={
                <div
                  ref={useApp3dRefCallback({ app, garmentList, sceneConfig })}
                />
              }
              app={app}
              initialPage={5}
            />
          }
        </div>

        <button className="next" onClick={handleClickNext}>
          <img src={isMobile ? nextMobileSvg : nextSvg} alt=">>" />
        </button>
      </div>

      <div className="dotsContainer">
        <div className="dotsFlex">
          {garmentIds.map((item) => (
            <SliderDotButton
              key={item}
              selected={item === currentId}
              handleClick={() => handleDotClick(item)}
            />
          ))}
        </div>
      </div>
    </StyledPreviewSlider>
  );
};

export default PreviewSlider;
