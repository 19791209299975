import React from 'react';
import {styled, css} from "@mui/material";
import {COLORS} from "../../constants";

interface IWrapper {
  variant?: 'white' | 'black';
}

const BlackCss = css`
  .blackInnerBlock {
    padding-left: 30px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: black;
    
    .content {
      color: white;
    }
  }

  &:hover {
    background: white;
  }
`

const Wrapper = styled('button')<IWrapper>`
  width: 199px;
  height: 56px;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  overflow: hidden;
  background: white;
  transition: .3s;
  
  .blackInnerBlock {
    transition: .3s;
    padding-left: 40px;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
    display: flex;
    align-items: center;
    
    .content {
      font-weight: 600;
      font-size: 16px;
      color: ${COLORS.textDarkGrey};
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 23px;
      }
    }
  }
  
  
  
  &:hover {
    background: black;
    width: 250px;
    
    .blackInnerBlock {
      padding-left: 64px;
      
      .content {
        color: white;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
  
  ${props => props.variant === 'black' && BlackCss}
`

interface IProps extends IWrapper {
  children: React.ReactNode;
  handleClick?: () => void;
}

const MenuButton = ({ children, variant = 'white', handleClick }: IProps) => {
  return (
    <Wrapper
      variant={variant}
      onClick={() => handleClick && handleClick()}
    >
      <div className='blackInnerBlock'>
        <div className='content'>
          { children }
        </div>
      </div>
    </Wrapper>
  );
};

export default MenuButton;
